<template>
  <!--Main Content-->
  <div class="main-content px-0 app-content">
    <!--Main Content Container-->
    <div class="container-fluid">
      <!--Page Header-->
      <Breadcrumb />
      <!--Page Header-->
      <div class="forms card rounded">
        <div
          v-bind:class="[closed ? 'close' : '']"
          class="mb-4 bg-warning p-3 text-dark"
          v-if="isSuccessful === false"
        >
          <b>Error : {{ errorMessage }}</b>
          <i @click="close()" class="fas fa-close"></i>
        </div>
        <div v-else></div>

        <form @submit.prevent="updateDelivery()">
          <label>Mail Type</label>
          <select
            v-model="delivery.mailType"
            class="form-control mb-3"
            required
          >
            <option selected>Select</option>
            <option value="Internal">Internal</option>
            <option value="External">External</option>
          </select>

          <label>Item</label>
          <input
            v-model="delivery.item"
            type="text"
            class="form-control"
            required
          />

          <label>Reciever Name</label>
          <input
            v-model="delivery.receiverName"
            type="text"
            class="form-control"
            required
          />

          <label>Reciever Address</label>
          <input
            v-model="delivery.receiverAddress"
            type="text"
            class="form-control"
            required
          />

          <label>Department</label>
          <input
            v-model="delivery.department"
            type="text"
            class="form-control"
            required
          />

          <label>Courier</label>
          <input
            v-model="delivery.courier"
            type="text"
            class="form-control"
            required
          />

          <label>Delivery Status</label>
          <select
            v-model="delivery.deliveryStatus"
            class="form-control mb-3"
            required
          >
            <option selected>Select</option>
            <option value="Not Delivered">Not Delivered</option>
            <option value="Delivered">Delivered</option>
          </select>

          <label>Delivery Date</label>
          <input
            v-model="delivery.date.split('T')[0]"
            type="date"
            class="form-control"
            disabled
          />

          <label>New Delivery Date</label>
          <input v-model="delivery.date" type="date" class="form-control" />

          <label>Already Uploaded Acknowledgement File</label>
          <div class="mb-3">
            <a
              class="text-light btn btn-info"
              :href="
                'https://chiadminapi.chiplc.com/Image/' +
                delivery.acknowledgementURL
              "
              target="_blank"
              >View Document</a
            >
          </div>
          <div
            v-if="
              delivery.status !== 'cancel' &&
              delivery.deliveryStatus !== 'Delivered'
            "
          >
            <label>Acknowledgement File</label>
            <div class="">
              <input
                type="file"
                v-on:change="handleFileUpload($event)"
                class="dropify"
                data-height="180"
              />
            </div>
            <button v-if="loading == true" class="btn btn-primary" disabled>
              Loading
              <div class="spinner-border spinner-border-sm" role="status">
                <span style="width: 2px" class="sr-only text-light"
                  >Loading...</span
                >
              </div>
            </button>
            <button v-else type="submit" class="btn btn-primary">
              Update Delivery
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";

export default {
  components: {
    Breadcrumb,
  },

  props: ["user", "BaseURL"],
  data() {
    return {
      loading: false,

      delivery: {},
      // MailType:'',
      // Item:'',
      // ReceiverName:'',
      // ReceiverAddress:'',
      // Department:'',
      // Courier:'',
      // DeliveryStatus:'',
      // date:'',
      document: "",

      isSuccessful: "",
      errorMessage: "",

      closed: "",
    };
  },

  created() {
    fetch(this.BaseURL + "/getDeliveryById" + this.$route.params.id, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        //console.log(data)
        this.delivery = data;
      });
  },

  beforeCreate() {
    if (localStorage.getItem("role") !== "User") {
      window.location = "/error";
    }
  },

  methods: {
    handleFileUpload(e) {
      this.document = e.target.files[0];
    },

    updateDelivery() {
      this.loading = true;
      let formData = new FormData();
      formData.append("FormFile", this.document);
      formData.append("Id", this.$route.params.id);
      formData.append("MailType", this.delivery.mailType);
      formData.append("Item", this.delivery.item);
      formData.append("ReceiverName", this.delivery.receiverName);
      formData.append("ReceiverAddress", this.delivery.receiverAddress);
      formData.append("Department", this.delivery.department);
      formData.append("Courier", this.delivery.courier);
      formData.append("DeliveryStatus", this.delivery.deliveryStatus);
      formData.append("AcknowledgementURL", this.delivery.acknowledgementURL);
      formData.append("SubmittedBy", this.user.email);
      formData.append("Date", this.delivery.date);

      fetch(this.BaseURL + "/updateDelivery", {
        method: "POST",
        body: formData,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((res) => res.json())
        .then((data) => {
          this.isSuccessful = data.isSuccessful;
          this.errorMessage = data.message;

          if (this.isSuccessful == true) {
            window.location = "/delivery";
            this.loading = false;
          } else {
            this.loading = false;
            alert(`Error : ${this.errorMessage}`);
          }
        });
    },

    close() {
      this.closed = true;
    },
  },
};
</script>