<template>
  <!--footer-->
  <div class="main-footer mg-t-auto">
    <div class="container">
      <span
        >Copyright &copy; 2022 <a href="https://chiplc.com">CHI PLC</a>.
      </span>
    </div>
  </div>
  <!--/footer-->
</template>