<template >
  <div v-if="online === true">
    <Topbar :BaseURL="this.BaseURL" :user="this.user" :role="this.role" />
    <Sidebar :BaseURL="this.BaseURL" :user="this.user" :role="this.role" />
    <router-view :BaseURL="this.BaseURL" :user="this.user" :role="this.role" />
    <Footer />
  </div>

  <div v-else>
    <!-- Main-signin-wrapper -->

    <div class="main-signin-wrapper">
      <div class="row text-center pl-0 pr-0 ml-0 mr-0">
        <div class="col-lg-3 d-block mx-auto">
          <div class="card">
            <!-- <div
              v-bind:class="[closed ? 'close' : '']"
              class="mb-4 bg-warning p-3 text-dark"
              v-if="isSuccessful === false"
            >
              <b>Error : {{ errorMessage }}</b>
              <i @click="close()" class="fas fa-close"></i>
            </div> -->

            <ErrorMessage
              v-if="isSuccessful === false"
              :message="this.errorMessage"
              :status="this.isSuccessful"
            />

            <div v-else></div>

            <div class="card-body">
              <img src="../logo.png" class="mb-3 logo" alt="Logo" />
              <h4>Please sign in to continue</h4>
              <form @submit.prevent="login()" class="text-left mt-3">
                <div class="form-group">
                  <label>Username</label>
                  <input
                    class="form-control"
                    v-model="userName"
                    placeholder="Enter your Username"
                    type="text"
                    required
                  />
                </div>
                <div class="form-group">
                  <label>Password</label>
                  <input
                    class="form-control"
                    v-model="password"
                    placeholder="Enter your password"
                    type="password"
                    required
                  />
                </div>
                <button
                  v-if="loading == true"
                  type="submit"
                  class="btn btn-main-primary btn-block"
                  disabled
                >
                  Loading
                  <div class="spinner-border spinner-border-sm" role="status">
                    <span style="width: 2px" class="sr-only text-light"
                      >Loading...</span
                    >
                  </div>
                </button>
                <button
                  v-else
                  type="submit"
                  class="btn btn-main-primary btn-block"
                >
                  Sign In
                </button>
              </form>
              <!--<div class="main-signin-footer mg-t-5">
								<p><a href="">Forgot password?</a></p>
								<p>Don't have an account? <a href="page-signup.html">Create an Account</a></p>
							</div>-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Main-signin-wrapper -->
  </div>
</template>

<script>
//import $session from 'vue-session'
import Footer from "./components/Footer.vue";
import Sidebar from "./components/Sidebar.vue";
import Topbar from "./components/Topbar.vue";
import ErrorMessage from "@/components/ErrorMessage";
//import Error from "./components/Error.vue";
//import Users from "./views/user/Users.vue";
export default {
  components: {
    Footer,
    Sidebar,
    Topbar,
    ErrorMessage,
    //Users,
    //Error,
  },
  data() {
    return {
      BaseURL: "https://chiadminapi.chiplc.com",
      userName: "",
      password: "",

      online: null,
      errorMessage: "",
      closed: "",

      isSuccessful: null,

      user: {},
      role: "",

      loading: null,
    };
  },

  methods: {
    login() {
      this.loading = true;
      fetch(this.BaseURL + "/authenticate", {
        method: "POST",
        body: JSON.stringify({
          userName: this.userName,
          password: this.password,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          this.isSuccessful = data.isSuccessful;
          this.online = data.isSuccessful;
          this.errorMessage = data.message;

          if (data.isSuccessful == true) {
            localStorage.setItem("token", data.token);
            localStorage.setItem("id", data.userId);
            localStorage.setItem("role", data.role);
            localStorage.setItem("username", data.email.split("@")[0]);
            this.role = data.role;

            if (this.role == "Admin") {
              fetch(
                this.BaseURL + "/getUserById" + localStorage.getItem("id"),
                {
                  headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                  },
                }
              )
                .then((res) => res.json())
                .then((userData) => {
                  //console.log(data)
                  this.user = userData;
                });
              this.$router.push({ name: "Users" });
              window.location = "/users";
            } else if (
              this.role == "ED" ||
              this.role == "GMD" ||
              this.role == "ARM"
            ) {
              fetch(
                this.BaseURL + "/getUserById" + localStorage.getItem("id"),
                {
                  headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                  },
                }
              )
                .then((res) => res.json())
                .then((userData) => {
                  //console.log(data)
                  this.user = userData;
                });
              this.$router.push({ name: "Approvals" });
              window.location = "/approvals";
            } else {
              window.location.reload();
            }
          } else {
            this.loading = false;
          }
          //alert(data.message)
        });
    },

    close() {
      this.closed = true;
    },

    timeOut() {
      localStorage.clear();
      location.reload(true);
    },
  },
  created() {
    //console.log(this.$route);
    if (localStorage.getItem("token")) {
      this.online = true;
      this.role = localStorage.getItem("role");
      fetch(this.BaseURL + "/getUserById" + localStorage.getItem("id"), {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((res) => res.json())
        .then((userData) => {
          //console.log(data)
          this.user = userData;
        });
    }

    //  if(this.online == true){
    //  setInterval(this.timeOut, 60000);
    //  }

    //console.log(localStorage.getItem('token'))
  },
};
</script>

<style>
input {
  margin-bottom: 15px;
}

.forms {
  background-color: #fff;
  padding: 40px 20px;
  margin: 0px 20px;
}

.close {
  display: none;
}

.fa-close {
  float: right;
  cursor: pointer;
}

.table-responsive {
  padding: 20px;
}
</style>

<style scoped>
.logo {
  width: 20%;
}

i:hover {
  cursor: pointer;
}
</style>
