<template>
  <!--Main Content-->
  <div class="main-content px-0 app-content">
    <!--Main Content Container-->
    <div class="container-fluid">
      <!--Page Header-->
      <Breadcrumb />
      <div class="forms card rounded">
        <div class="row row-sm">
          <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-margin">
            <div class="card">
              <div class="card-body pb-0 border-bottom">
                <div class="d-flex mb-3">
                  <h6 class="card-title mb-0">All Pending Claims</h6>
                  <div class="card-option ml-auto d-none d-md-flex">
                    <div class="mr-2">
                      <router-link to="/createclaim"
                        ><button
                          class="btn btn-primary btn-sm rounded-lg fs-13"
                        >
                          <i class="mdi mdi-plus-circle-outline"></i>Create New
                          Asset Claim
                        </button></router-link
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="">
                <div class="table-responsive">
                  <table
                    id="example"
                    class="table table-striped"
                    style="width: 100%"
                  >
                    <thead>
                      <tr>
                        <th>Claim Id</th>
                        <th>Asset ID</th>
                        <th>Asset Name</th>

                        <th>Insurer</th>
                        <th>Status</th>
                        <th>View</th>
                        <th>Upload Documents</th>
                        <th>Send Mail</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="claim in pendingClaims" :key="claim.id">
                        <td>{{ claim.id }}</td>
                        <td>{{ claim.assetId }}</td>
                        <td>{{ claim.assetName }}</td>
                        <td>{{ claim.insurerName }}</td>
                        <td>{{ claim.status }}</td>

                        <td>
                          <router-link
                            :to="{
                              name: 'Edit Claim',
                              params: { id: claim.id },
                            }"
                            ><button class="btn btn-primary">
                              View / Edit
                            </button></router-link
                          >
                        </td>

                        <td>
                          <router-link to="" v-if="claim.status == 'completed'"
                            ><button class="btn btn-primary disabled">
                              Upload Claim Documents
                            </button></router-link
                          >

                          <router-link
                            v-else
                            :to="{
                              name: 'Claim Documents',
                              params: { id: claim.id },
                            }"
                            ><button class="btn btn-primary">
                              Upload Claim Documents
                            </button></router-link
                          >
                        </td>

                        <td>
                          <router-link
                            :to="{
                              name: 'Mail',
                              params: { id: claim.id },
                            }"
                            ><button class="btn btn-primary">
                              Send Mail
                            </button></router-link
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <!-- COL END -->
        </div>
      </div>

      <div class="forms card rounded mt-5">
        <div class="row row-sm">
          <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-margin">
            <div class="card">
              <div class="card-body pb-0 border-bottom">
                <div class="d-flex mb-3">
                  <h6 class="card-title mb-0">All Completed Claims</h6>
                  <div class="ml-auto d-md-flex">
                    <div class="mr-2 mb-2">
                      <router-link to="/createclaim"
                        ><button
                          class="btn btn-primary btn-sm rounded-lg fs-13"
                        >
                          <i class="mdi mdi-plus-circle-outline"></i>Create New
                          Asset Claim
                        </button></router-link
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="">
                <div class="table-responsive">
                  <table
                    id="example2"
                    class="table table-striped"
                    style="width: 100%"
                  >
                    <thead>
                      <tr>
                        <th>Claim Id</th>
                        <th>Asset ID</th>
                        <th>Asset Name</th>

                        <th>Insurer</th>
                        <th>Status</th>
                        <th>View</th>
                        <th>Upload Documents</th>
                        <th>Send Mail</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="claim in claims" :key="claim.id">
                        <td>{{ claim.id }}</td>
                        <td>{{ claim.assetId }}</td>
                        <td>{{ claim.assetName }}</td>
                        <td>{{ claim.insurerName }}</td>
                        <td>{{ claim.status }}</td>

                        <td>
                          <router-link
                            :to="{
                              name: 'Edit Claim',
                              params: { id: claim.id },
                            }"
                            ><button class="btn btn-primary">
                              View
                            </button></router-link
                          >
                        </td>

                        <td>
                          <router-link to="" v-if="claim.status == 'completed'"
                            ><button class="btn btn-primary disabled">
                              Upload Claim Documents
                            </button></router-link
                          >

                          <router-link
                            v-else
                            :to="{
                              name: 'Claim Documents',
                              params: { id: claim.id },
                            }"
                            ><button class="btn btn-primary">
                              Upload Claim Documents
                            </button></router-link
                          >
                        </td>

                        <td>
                          <router-link
                            :to="{
                              name: 'Mail',
                              params: { id: claim.id },
                            }"
                            ><button class="btn btn-primary">
                              Send Mail
                            </button></router-link
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <!-- COL END -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

import Breadcrumb from "@/components/Breadcrumb";

export default {
  components: {
    Breadcrumb,
  },

  props: ["user", "BaseURL"],

  data() {
    return {
      claims: [],
      pendingClaims: [],
    };
  },

  mounted() {
    fetch(this.BaseURL + "/getAllClaim", {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        //console.log(data)
        this.claims = data.filter((claim) => claim.status == "completed");
        $(() => {
          $("#example").DataTable();
        });

        this.pendingClaims = data.filter((claim) => claim.status == "Pending");
        $(() => {
          $("#example2").DataTable();
        });
      });
  },

  methods: {},
};
</script>