<template>
  <!--Main Content-->
  <div class="main-content px-0 app-content">
    <!--Main Content Container-->
    <div class="container-fluid">
      <!--Page Header-->
      <Breadcrumb />
      <!--Page Header-->
      <div class="forms card rounded">
        <form @submit.prevent="upload()">
          <label class="mg-b-20">Choose Excel Document</label>
          <div class="">
            <input
              type="file"
              @change="handleFileUpload($event)"
              class="dropify"
              data-height="180"
              required
            />
          </div>

          <button type="submit" class="btn btn-primary mb-5">
            Upload Document
          </button>
          <br />
          <hr />
          <a
            class="mt-5 pt-5"
            href="../../assets/AssetUploadTemplate.csv"
            download
            >Download Template For Bulk Upload Here</a
          >
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";

export default {
  components: {
    Breadcrumb,
  },

  props: ["user", "BaseURL"],

  data() {
    return {
      document: null,

      isSuccessful: "",
      errorMessage: "",
    };
  },
  beforeCreate() {
    if (localStorage.getItem("role") !== "User") {
      window.location = "/error";
    }
  },

  methods: {
    handleFileUpload(e) {
      this.document = e.target.files[0];
    },

    upload() {
      let formData = new FormData();
      formData.append("FormFile", this.document);
      formData.append("SubmittedBy", this.user.email);

      fetch(this.BaseURL + "/Upload", {
        method: "POST",
        body: formData,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          this.isSuccessful = data.isSuccessful;
          this.errorMessage = data.message;

          if (this.isSuccessful == true) {
            alert("Assets Successfully Uploaded");
            window.location = "/assets";
          }
        });
    },
  },
};
</script>

<style scoped>
</style>