<template>
  <!--Main Content-->
  <div class="main-content px-0 app-content">
    <!--Main Content Container-->
    <div class="container-fluid">
      <!--Page Header-->
      <Breadcrumb />
      <!--Page Header-->
      <div class="forms card rounded">
        <div
          v-bind:class="[closed ? 'close' : '']"
          class="mb-4 bg-warning p-3 text-dark"
          v-if="isSuccessful === false"
        >
          <b>Error : {{ errorMessage }}</b>
          <i @click="close()" class="fas fa-close"></i>
        </div>
        <div v-else></div>
        <form @submit.prevent="createVendor()">
          <label>Registered Company Name</label>
          <input
            v-model="companyName"
            type="text"
            class="form-control"
            required
          />

          <label>Company Registration Number (RC)</label>
          <input v-model="regNo" type="text" class="form-control" required />

          <label>Tax Identification Number (TIN)</label>
          <input v-model="taxIdNo" type="text" class="form-control" required />

          <label>Registered Place of Business / Address</label>
          <input v-model="address" type="text" class="form-control" required />

          <label>Office Phone Number</label>
          <input v-model="telNo" type="number" class="form-control" required />

          <label>Mobile Number</label>
          <input
            v-model="mobileNo"
            type="number"
            class="form-control"
            required
          />

          <label>Vendor functional email address</label>
          <input v-model="email" type="text" class="form-control" required />

          <label>Vendor Website</label>
          <input v-model="website" type="text" class="form-control" />

          <label
            >Vendor nature of business / products / services / industry</label
          >
          <textarea
            v-model="industry"
            rows="3"
            class="form-control mb-3"
            required
          ></textarea>

          <label>Overall Number Of Staff</label>
          <input
            v-model="noOfStaff"
            type="number"
            class="form-control"
            required
          />

          <label>Number of years in business</label>
          <input
            v-model="yearsInBusiness"
            type="number"
            class="form-control"
            required
          />

          <label>Does the company belong to any Group of Companies ?</label>
          <select v-model="groupOfCompanies" class="form-control mb-3" required>
            <option selected>Select</option>
            <option :value="true">Yes</option>
            <option :value="false">No</option>
          </select>

          <div v-if="groupOfCompanies === true">
            <label
              >Please state the name of the company/organization/group:</label
            >
            <input
              v-model="groupOfCompaniesName"
              type="text"
              class="form-control"
              required
            />
          </div>

          <label>Does the company have several plants/branches?</label>
          <select v-model="branches" class="form-control mb-3" required>
            <option selected>Select</option>
            <option :value="true">Yes</option>
            <option :value="false">No</option>
          </select>

          <label>Does the company have any subsidiary?</label>
          <select v-model="subsidiary" class="form-control mb-3" required>
            <option selected>Select</option>
            <option :value="true">Yes</option>
            <option :value="false">No</option>
          </select>

          <div v-if="subsidiary === true">
            <table id="example" class="table table-striped" style="width: 100%">
              <thead>
                <tr>
                  <th>Subsidiary Company name</th>
                  <th>Location</th>
                  <th>Field of Activity</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="subsidiary in subsidiaries" :key="subsidiary.id">
                  <td>{{ subsidiary.subsidiaryCompanyName }}</td>
                  <td>{{ subsidiary.location }}</td>
                  <td>{{ subsidiary.fieldofActivity }}</td>
                </tr>
              </tbody>
            </table>

            <div class="jumbotron">
              <label>Subsidiary Company name</label>
              <input
                v-model="subsidiaryCompanyName"
                type="text"
                class="form-control"
              />

              <label>Subsidiary Location</label>
              <input
                v-model="subsidiaryLocation"
                type="text"
                class="form-control"
              />

              <label>Field of Activity</label>
              <textarea
                v-model="subsidiaryFieldOfActivity"
                rows="3"
                class="form-control mb-3"
              ></textarea>

              <button
                type="button"
                @click="addSubsidiary"
                class="btn btn-primary"
              >
                Add
              </button>
            </div>
          </div>
          <button v-if="loading == true" class="btn btn-primary" disabled>
            Loading
            <div class="spinner-border spinner-border-sm" role="status">
              <span style="width: 2px" class="sr-only text-light"
                >Loading...</span
              >
            </div>
          </button>
          <button v-else type="submit" class="btn btn-primary">Save</button>
          <button @click.prevent="reset()" class="btn btn-warning mx-3">
            Cancel
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";

export default {
  components: {
    Breadcrumb,
  },
  props: ["user", "BaseURL"],
  data() {
    return {
      companyName: " ",
      regNo: " ",
      taxIdNo: " ",
      address: " ",
      telNo: 0,
      mobileNo: 0,
      email: " ",
      website: " ",
      industry: " ",
      noOfStaff: 0,
      groupOfCompanies: null,
      yearsInBusiness: 0,
      groupOfCompaniesName: " ",
      branches: null,
      subsidiary: null,
      subsidiaries: [],

      subsidiaryCompanyName: " ",
      subsidiaryLocation: " ",
      subsidiaryFieldOfActivity: " ",

      isSuccessful: "",
      errorMessage: "",
      loading: false,

      closed: "",
    };
  },

  beforeCreate() {
    // if (localStorage.getItem("role") !== "Admin") {
    //   window.location = "/error";
    // }
  },

  methods: {
    reset() {
      window.location.reload(true);
    },
    addSubsidiary() {
      this.subsidiaries.push({
        subsidiaryCompanyName: this.subsidiaryCompanyName,
        location: this.subsidiaryLocation,
        fieldofActivity: this.subsidiaryFieldOfActivity,
      });

      this.subsidiaryCompanyName = null;
      this.subsidiaryLocation = null;
      this.subsidiaryFieldOfActivity = null;
    },
    createVendor() {
      this.loading = true;
      fetch(this.BaseURL + "/addVendor", {
        method: "POST",
        body: JSON.stringify({
          submittedBy: this.user.email,
          registeredCompanyName: this.companyName,
          companyRegistrationNumber: this.regNo,
          taxIdentificationNumber: this.taxIdNo,
          address: this.address,
          telephoneNumber: this.telNo,
          mobileNumber: this.mobileNo,
          email: this.email,
          website: this.website,
          industry: this.industry,
          numberOfStaff: this.noOfStaff,
          yearsinBusiness: this.yearsInBusiness,
          groupofCompanies: this.groupOfCompanies,
          nameofGroupofCompanies: this.groupOfCompaniesName,
          branches: this.branches,
          subsidiary: this.subsidiary,
          subsidiaryDTO: this.subsidiaries,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          this.isSuccessful = data.isSuccessful;
          this.errorMessage = data.message;

          if (this.isSuccessful == true) {
            this.loading = false;
            alert("Vendor Created Successfully");
            window.location = "/vendors";
          } else {
            alert("Error : " + this.errorMessage);
          }
        });
    },

    close() {
      this.closed = true;
    },
  },
};
</script>