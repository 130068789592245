<template>
  <form @submit.prevent="scheduleRenewal()">
    <!-- <label>Asset Name</label>
    <input type="text" :value="$route.params.id" class="form-control" disabled /> -->

    <label>Asset Name</label>
    <input type="text" :value="asset.name" class="form-control" disabled />

    <label>Registration Number</label>
    <input type="text" v-model="regNo" class="form-control" />

    <label>Renewal Name</label>
    <input type="text" v-model="name" class="form-control" required />

    <label>Renewal Type</label>
    <select v-model="renewalTypeId" class="form-control mb-3">
      <option selected>Select Renewal</option>
      <option
        v-for="renewalType in renewalTypes"
        :key="renewalType.id"
        :value="renewalType.id"
      >
        {{ renewalType.name }}
      </option>
    </select>

    <label>Expiration Date </label>
    <input
      id="date"
      type="date"
      v-model="expDate"
      class="form-control"
      required
    />

    <label>Renewal Cost</label>
    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <span class="input-group-text">₦</span>
      </div>

      <input
        type="text"
        v-model="cost"
        class="form-control"
        required
        aria-label="Amount (to the nearest naira)"
      />
      <div class="input-group-append">
        <span class="input-group-text">.00</span>
      </div>
    </div>

    <!-- <label>Renewal Cost</label>
    <input type="number" v-model="cost" class="form-control" required /> -->

    <!-- <label>Renewal Frequency</label>
    <select v-model="frequency" class="form-control mb-3">
      <option selected>{{claim.insurerName}}</option>
      <option value="Recurring">Recurring</option>

      <option value="One Off">One Off</option>
    </select> -->

    <label>Driver's Name</label>
    <input type="text" v-model="driverName" class="form-control" />

    <label>Assigned Staff</label>
    <input type="text" v-model="userName" class="form-control" />

    <!-- <label>Total Sum</label>
    <input type="number" v-model="sum" class="form-control" required /> -->
    <button
      v-if="loading == true"
      type="submit"
      class="btn btn-primary"
      disabled
    >
      Loading
      <div class="spinner-border spinner-border-sm" role="status">
        <span style="width: 2px" class="sr-only text-light">Loading...</span>
      </div>
    </button>

    <button v-else type="submit" class="btn btn-primary">Save</button>
  </form>
</template>

<script>
import $ from "jquery";
export default {
  props: ["user", "BaseURL"],

  data() {
    return {
      loading: false,
      name: "",
      regNo: "",
      expDate: "",
      cost: 0,
      driverName: "",
      userName: "",
      sum: 0,
      renewalTypeId: "Select Renewal",
      frequency: " ",

      isSuccessful: "",
      errorMessage: "",

      closed: false,

      renewalTypes: [],

      asset: {},
    };
  },

  created() {
    $(function () {
      var dtToday = new Date();

      var month = dtToday.getMonth() + 1;
      var day = dtToday.getDate();
      var year = dtToday.getFullYear();
      if (month < 10) month = "0" + month.toString();
      if (day < 10) day = "0" + day.toString();

      var maxDate = year + "-" + month + "-" + day;
      //alert(maxDate);
      $("#date").attr("min", maxDate);
    });

    fetch(this.BaseURL + "/getAllRenewalType", {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        //console.log(data)
        this.renewalTypes = data;
      });

    fetch(this.BaseURL + "/getAssetById" + this.$route.params.id, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        //console.log(data)
        this.asset = data;
      });
  },

  methods: {
    format() {
      this.cost = new Intl.NumberFormat().format(
        parseInt(this.cost.replace(",", ""))
      );
    },

    scheduleRenewal() {
      this.loading = true;
      fetch(this.BaseURL + "/addRenewal", {
        method: "POST",
        body: JSON.stringify({
          assetId: this.$route.params.id,
          name: this.name,
          renewalTypeId: this.renewalTypeId,
          registrationNumber: this.regNo,
          expirydate: this.expDate,
          renewalCost: parseInt(this.cost),
          driverName: this.driverName,
          totalAmount: this.sum,
          staffAssignTo: this.userName,
          submittedBy: this.user.email,
          frequency: this.frequency,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((data) => {
          //console.log(data);
          this.isSuccessful = data.isSuccessful;
          this.errorMessage = data.message;

          if (this.isSuccessful == true) {
            alert("Renewal Scheduled Succesfully");
            window.location = "/renewals";
          }
        });
    },
  },
};
</script>

