<template>
  <!--Main Content-->
  <div class="main-content px-0 app-content">
    <!--Main Content Container-->
    <div class="container-fluid">
      <!--Page Header-->
      <Breadcrumb />
      <!--Page Header-->
      <div class="forms card rounded">
        <div
          v-bind:class="[closed ? 'close' : '']"
          class="mb-4 bg-warning p-3 text-dark"
          v-if="isSuccessful === false"
        >
          <b>Error : {{ errorMessage }}</b>
          <i @click="close()" class="fas fa-close"></i>
        </div>
        <form @submit.prevent="edit()">
          <label>Claim ID</label>
          <input type="text" v-model="claim.id" class="form-control" disabled />

          <label>Asset </label>
          <select v-model="claim.assetId" class="form-control mb-3">
            <option selected>Select</option>
            <option v-for="asset in assets" :key="asset.id" :value="asset.id">
              {{ asset.name }}
            </option>
          </select>

          <label>Asset Tag Number </label>
          <input
            type="text"
            v-model="claim.tagNumber"
            class="form-control"
            disabled
          />

          <div class="mb-4">
            <label class="mg-b-10">Insurer</label>
            <select v-model="claim.insurerId" class="form-control mb-3">
              <option selected>{{ claim.insurerName }}</option>
              <option
                v-for="insurer in insurers"
                :key="insurer.id"
                :value="insurer.id"
              >
                {{ insurer.name }}
              </option>
            </select>
          </div>

          <label>Claim Status</label>
          <select v-model="claim.status" class="form-control mb-3">
            <!-- <option selected>{{claim.insurerName}}</option> -->
            <option value="Pending">Pending</option>

            <option value="completed">Completed</option>
          </select>

          <label>Claim Form Document</label>
          <div class="mb-3">
            <a
              class="text-light btn btn-info"
              :href="
                'https://chiadminapi.chiplc.com/IMAGE/' + claim.claimFormUrl
              "
              target="_blank"
              >View Document</a
            >
          </div>

          <Label
            >Other Uploaded Documents ({{ claim.documentsUrl.length }})</Label
          >
          <ul v-for="document in claim.documentsUrl" :key="document.id">
            <li>
              <b>{{ document.documentName }}</b>
              <a
                class="ml-3"
                :href="
                  'https://chiadminapi.chiplc.com/IMAGE/' +
                  document.documentsUrl
                "
                target="_blank"
                >( View Document )</a
              >
            </li>
          </ul>
          <br />
          <br />

          <!-- <Label>Uploaded Documents ({{claim.documentsUrl.length}})</Label>
                <ul v-for="document in claim.documentsUrl" :key="document.id">
                <li>{{document.documentName}}</li>
                </ul> -->
          <div v-if="claim.status !== 'completed'">
            <p class="mg-b-20 mt-5">Re-Upload Claim Form</p>
            <div class="mb-4">
              <input
                type="file"
                @change="handleFileUpload($event)"
                class="dropify"
                data-height="180"
              />
            </div>

            <button
              v-if="loading == true"
              type="submit"
              class="btn btn-main-primary btn-block"
              disabled
            >
              Loading
              <div class="spinner-border spinner-border-sm" role="status">
                <span style="width: 2px" class="sr-only text-light"
                  >Loading...</span
                >
              </div>
            </button>
            <button v-else type="submit" class="btn btn-primary">
              Update Claim
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";

export default {
  components: {
    Breadcrumb,
  },

  props: ["user", "BaseURL"],

  data() {
    return {
      loading: false,
      assets: [],
      insurers: [],

      claim: {},
      document: "",
      name: "",

      isSuccessful: "",
      errorMessage: "",

      closed: false,
    };
  },

  created() {
    fetch(this.BaseURL + "/getAllAsset", {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        //console.log(data)
        this.assets = data;
      });

    fetch(this.BaseURL + "/getClaimById" + this.$route.params.id, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        //console.log(data)
        this.claim = data;
      });

    fetch(this.BaseURL + "/getAllInsurer", {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        //console.log(data)
        this.insurers = data;
      });
  },

  methods: {
    close() {
      this.closed = true;
    },

    handleFileUpload(e) {
      this.document = e.target.files[0];
    },

    edit() {
      this.loading = true;
      let formData = new FormData();
      formData.append("Id", this.claim.id);
      formData.append("FormFile", this.document);
      formData.append("AssetId", this.claim.assetId);
      formData.append("InsurerId", this.claim.insurerId);
      formData.append("ClaimFormUrl", this.claim.ClaimFormUrl);
      formData.append("Status", this.claim.status);
      formData.append("SubmittedBy", this.user.email);

      fetch(this.BaseURL + "/updateClaim", {
        method: "POST",
        body: formData,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((res) => res.json())
        .then((data) => {
          //console.log(data);
          this.isSuccessful = data.isSuccessful;
          this.errorMessage = data.message;

          if (this.isSuccessful == true) {
            window.location.reload();
          }
        });
    },
  },
};
</script>