<template>
  <!--Main Content-->
  <div class="main-content px-0 app-content">
    <!--Main Content Container-->
    <div class="container-fluid">
      <!--Page Header-->
      <Breadcrumb />
      <!--Page Header-->
      <div class="forms card rounded">
        <form @submit.prevent="editTask()">
          <label>Asset Name </label>
          <input
            type="text"
            v-model="task.assetName"
            class="form-control"
            disabled
          />

          <label>Task Name</label>
          <input
            type="text"
            v-model="task.name"
            class="form-control"
            required
          />

          <label>Description </label>
          <textarea
            v-model="task.description"
            class="form-control mb-3"
            rows="3"
          ></textarea>

          <label>Due Date</label>
          <input
            type="date"
            v-model="task.dueDate.split('T')[0]"
            class="form-control"
            disabled
          />

          <label>New Due Date</label>
          <input type="date" v-model="task.dueDate" class="form-control" />

          <label>Status</label>
          <select v-model="task.status" class="form-control mb-3">
            <option selected>Select</option>
            <option :value="false">Pending</option>

            <option :value="true">Completed</option>
          </select>

          <button type="submit" class="btn btn-primary">Update Task</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";

export default {
  props: ["user", "BaseURL"],
  components: { Breadcrumb },

  data() {
    return {
      //   name: "",
      //   description: "",
      //   date: "",
      //   status: "",
      //taskID: "",
      task: {},

      isSuccessful: "",
      errorMessage: "",

      closed: false,

      renewalTypes: [],
    };
  },

  beforeCreate() {
    if (localStorage.getItem("role") !== "User") {
      window.location = "/error";
    }
  },

  created() {
    fetch(this.BaseURL + "/getTaskById" + this.$route.params.id, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        //console.log(data)
        this.task = data;
      });
  },

  methods: {
    editTask() {
      fetch(this.BaseURL + "/updateTask", {
        method: "POST",
        body: JSON.stringify({
          id: this.task.id,
          name: this.task.name,
          assetId: this.task.assetId,
          description: this.task.description,
          submittedBy: this.user.email,
          dueDate: this.task.dueDate,
          status: this.task.status,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          this.isSuccessful = data.isSuccessful;
          this.errorMessage = data.message;

          if (this.isSuccessful == true) {
            window.location = "/tasks";
          }
        });
    },
  },
};
</script>