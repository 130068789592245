<template>
  <!--Main Content-->
  <div class="main-content px-0 app-content">
    <!--Main Content Container-->
    <div class="container-fluid">
      <!--Page Header-->
      <Breadcrumb />
      <!--Page Header-->
      <!-- <div class="forms mb-5 card rounded">
        <DeliveryForm :user="this.user" />
      </div> -->

      <div class="">
        <div class="row row-sm">
          <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-margin">
            <div class="card">
              <div class="card-body pb-0 border-bottom">
                <div class="d-flex mb-3">
                  <h6 class="card-title mb-0">All Delivery</h6>
                  <div class="card-option ml-auto d-none d-md-flex">
                    <div class="mr-2">
                      <!-- <router-link to="/createdelivery"><button class="btn btn-primary btn-sm rounded-lg fs-13"><i class="mdi mdi-plus-circle-outline"></i>Create New Delivery </button></router-link> -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="">
                <form class="card m-3" @submit.prevent="getAllDelivery()">
                  <div class="m-3 p-3 row">
                    <div class="col-md-6">
                      <!-- <h5 class="mb-3 bg-light p-2">Generate By Date</h5> -->
                      <p>Start Date</p>
                      <input
                        class="form-control"
                        type="date"
                        required
                        v-model="startDate"
                        name="start"
                      />
                    </div>

                    <div class="col-md-6">
                      <!-- <h5 class="mb-3 bg-light p-2">
                      Generate By Category Or Status
                    </h5> -->

                      <p>End Date</p>
                      <input
                        v-model="endDate"
                        class="form-control"
                        type="date"
                        required
                        name="end"
                      />
                    </div>
                    <button v-if="loading" class="btn btn-primary" disabled>
                      Loading
                      <div
                        class="spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span style="width: 2px" class="sr-only text-light"
                          >Loading...</span
                        >
                      </div>
                    </button>
                    <input
                      v-else-if="deliveries.length == 0"
                      class="ml-3 btn btn-primary"
                      type="submit"
                      value="Generate"
                    />
                    <button class="ml-5 btn btn-warning" @click.prevent="reset">
                      Reset
                    </button>
                  </div>
                </form>
                <div class="card m-3">
                  <div class="table-responsive">
                    <table
                      id="example"
                      class="table table-striped"
                      style="width: 100%"
                    >
                      <thead>
                        <tr>
                          <th>Id</th>
                          <th>Mail Type</th>
                          <th>Item</th>
                          <th>Reciever's Name</th>
                          <th>Reciever's Address</th>
                          <th>Reciever's Department</th>
                          <th>Courier</th>
                          <th>Delivery Status</th>
                          <th>Acknowledgement URL</th>
                          <th>Delivery Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="delivery in deliveries" :key="delivery.id">
                          <td>{{ delivery.id }}</td>
                          <td>{{ delivery.mailType }}</td>
                          <td>{{ delivery.item }}</td>
                          <td>{{ delivery.receiverName }}</td>
                          <td>{{ delivery.receiverAddress }}</td>
                          <td>{{ delivery.department }}</td>
                          <td>{{ delivery.courier }}</td>
                          <td>{{ delivery.deliveryStatus }}</td>
                          <td>
                            <a
                              target="_blank"
                              :href="
                                'https://chiadminapi.chiplc.com/IMAGE/' +
                                delivery.acknowledgementURL
                              "
                              >{{ delivery.acknowledgementURL }}</a
                            >
                          </td>
                          <td>{{ delivery.date }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- COL END -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
//import DeliveryForm from "@/components/DeliveryForm";
import Breadcrumb from "@/components/Breadcrumb";

export default {
  components: {
    //DeliveryForm,
    Breadcrumb,
  },

  props: ["user", "BaseURL"],

  data() {
    return {
      loading: false,
      deliveries: [],

      startDate: "",
      endDate: "",
      categoryId: 0,
      status: "",
    };
  },

  beforeCreate() {
    if (localStorage.getItem("role") !== "User") {
      window.location = "/error";
    }
  },

  methods: {
    getAllDelivery() {
      this.loading = true;
      fetch(
        this.BaseURL +
          "/getDeliveryByDate/" +
          this.startDate +
          "/" +
          this.endDate,
        {
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          this.deliveries = data;

          if (this.deliveries.length == 0) {
            this.loading = false;
            alert(
              `No Data Available Within Specified Date Or Category To Show`
            );
          } else {
            this.loading = false;
            $(() => {
              $("#example").DataTable({
                dom: "Bfrtip",
                buttons: [
                  "colvis",
                  "copyHtml5",
                  "csvHtml5",
                  "excel",
                  "pdf",
                  "print",
                ],
              });
            });
          }
          //console.log(data);
          // this.isSuccessful = data.isSuccess;
          // this.errorMessage = data.message;
        });
    },

    reset() {
      window.location.reload(true);
    },
  },
};
</script>