import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
// import Login from '../views/Login.vue'


import Users from '../views/user/Users.vue'
import createUser from '../views/user/createUser.vue'
import EditUser from '../views/user/EditUser.vue'

import RenewalTypes from '../views/renewaltype/RenewalTypes.vue'
import UpdateRenewalType from '../views/renewaltype/UpdateRenewalType.vue'
import CreateRenewalType from '../views/renewaltype/CreateRenewalType.vue'

import MaintenanceTypes from '../views/maintenancetype/MaintenanceTypes.vue'
import EditMaintenanceType from '../views/maintenancetype/EditMaintenanceType.vue'
import CreateMaintenanceType from '../views/maintenancetype/CreateMaintenanceType.vue'

import Categories from '../views/category/Categories.vue'
import EditCategory from '../views/category/EditCategory.vue'
import CreateCategory from '../views/category/CreateCategory.vue'

import Assets from '../views/asset/Assets.vue'
import EditAsset from '../views/asset/EditAsset.vue'
import ViewAsset from '../views/asset/ViewAsset.vue'
import CreateAsset from '../views/asset/CreateAsset.vue'
import BulkUploadAssets from '../views/asset/BulkUploadAssets.vue'
import AssetReport from '../views/asset/AssetReport.vue'

import Renewals from '../views/renewal/Renewals.vue'
import EditRenewal from '../views/renewal/EditRenewal.vue'
import CreateRenewal from '../views/renewal/CreateRenewal.vue'

import Maintenance from '../views/maintenance/Maintenance.vue'
import EditMaintenance from '../views/maintenance/EditMaintenance.vue'
import CreateMaintenance from '../views/maintenance/CreateMaintenance.vue'

import Vendors from '../views/vendor/Vendors.vue'
import EditVendor from '../views/vendor/EditVendor.vue'
import CreateVendor from '../views/vendor/CreateVendor.vue'
import VendorDocuments from '../views/vendor/VendorDocuments.vue'

import Insurers from '../views/insurer/Insurers.vue'
import EditInsurer from '../views/insurer/EditInsurer.vue'
import CreateInsurer from '../views/insurer/CreateInsurer.vue'

import UserApproval from '../views/approval/UserApproval.vue'
import EditApproval from '../views/approval/EditUserApproval.vue'
import GMDApproval from '../views/approval/GMDApproval.vue'
import ARMApproval from '../views/approval/ARMApproval.vue'
import ViewApproval from '../views/approval/ViewApproval.vue'
import EDApproval from '../views/approval/EDApproval.vue'
import Approvals from '../views/approval/Approvals.vue'

import GMDRetirementApproval from '../views/approval/retirement/GMDApproval.vue'
import ARMRetirementApproval from '../views/approval/retirement/ARMApproval.vue'
import ViewRetirementApproval from '../views/approval/retirement/ViewApproval.vue'
import EDRetirementApproval from '../views/approval/retirement/EDApproval.vue'
import RetirementApprovals from '../views/approval/retirement/Approvals.vue'

import Claim from '../views/claim/Claim.vue'
import CreateClaim from '../views/claim/CreateClaim.vue'
import EditClaim from '../views/claim/EditClaim.vue'
import ClaimDocuments from '../views/claim/ClaimDocuments.vue'
import ClaimReport from '../views/claim/ClaimReport.vue'
import Mail from '../views/claim/Mail.vue'

import Tasks from '../views/task/Tasks.vue'
import EditTask from '../views/task/EditTask.vue'
import CreateTask from '../views/task/CreateTask.vue'

import Company from '../views/company/Company.vue'
import EditCompany from '../views/company/EditCompany.vue'
import CreateCompany from '../views/company/CreateCompany.vue'

import Departments from '../views/department/Departments.vue'
import EditDepartment from '../views/department/EditDepartment.vue'
import CreateDepartment from '../views/department/CreateDepartment.vue'

import Delivery from '../views/delivery/Delivery.vue'
import EditDelivery from '../views/delivery/EditDelivery.vue'
import CreateDelivery from '../views/delivery/CreateDelivery.vue'

import Requisition from '../views/requisition/Requisition.vue'
import EditRequisition from '../views/requisition/EditRequisition.vue'
import CreateRequisition from '../views/requisition/CreateRequisition.vue'
import UnitHeadApproval from '../views/requisition/UnitHeadApproval.vue'
import AdminHeadApproval from '../views/requisition/AdminHeadApproval.vue'

import RenewalReport from '../views/report/RenewalReport.vue'
import MaintenanceReport from '../views/report/MaintenanceReport.vue'
import DeliveryReport from '../views/report/DeliveryReport.vue'
import RequisitionReport from '../views/report/RequisitionReport.vue'
import VendorReport from '../views/report/VendorReport.vue'
import AssetReallocationReport from '../views/report/AssetReallocationReport.vue'
import AssetDisposalReport from '../views/report/AssetDisposalReport.vue'
import AssetTransferReport from '../views/report/AssetTransferReport.vue'
import AssetRetirementReport from '../views/report/AssetRetirementReport.vue'
import AuditReport from '../views/report/AuditReport.vue'

import Retirements from '../views/retirement/Retirements.vue'
import CreateRetirement from '../views/retirement/CreateRetirement.vue'
import EditRetirement from '../views/retirement/EditRetirement.vue'




import Error from '../components/Error.vue'
//import ClaimReport from '../views/report/ClaimReport.vue'

const routes = [
  {
    path: '/error',
    name: 'Error',
    component: Error
  },

  {
    path: '/',
    name: 'Home',
    component: Home
  },
  // {
  //   path: '/login',
  //   name: 'Login',
  //   component: Login
  // },
  

  {
    path: '/createUser',
    name: 'createUser',
    component: createUser
  },

  {
    path: '/editUser:id',
    name: 'Edit User',
    component: EditUser
  },

  {
    path: '/users',
    name: 'Users',
    component: Users
  },

  {
    path: '/renewaltypes',
    name: 'Renewal Types',
    component: RenewalTypes
  },

  {
    path: '/createrenewaltype',
    name: 'Create Renewal Type',
    component: CreateRenewalType
  },

  {
    path: '/editrenewaltype:id',
    name: 'Edit Renewal Type',
    component: UpdateRenewalType
  },

  {
    path: '/maintenancetypes',
    name: 'Maintenance Types',
    component: MaintenanceTypes
  },

  {
    path: '/createmaintenancetype',
    name: 'Create Maintenance Type',
    component: CreateMaintenanceType
  },

  {
    path: '/editmaintenancetype:id',
    name: 'Edit Maintenance Type',
    component: EditMaintenanceType
  },

  {
    path: '/categories',
    name: 'Categories',
    component: Categories
  },

  {
    path: '/createcategory',
    name: 'Create Category',
    component: CreateCategory
  },

  {
    path: '/editcategory:id',
    name: 'Edit Category',
    component: EditCategory
  },

  {
    path: '/assets',
    name: 'Assets',
    component: Assets
  },

  {
    path: '/createasset',
    name: 'Create Asset',
    component: CreateAsset
  },

  {
    path: '/bulkuploadassets',
    name: 'Bulk Upload Assets',
    component: BulkUploadAssets
  },

  {
    path: '/editasset:id',
    name: 'Edit Asset',
    component: EditAsset
  },

  {
    path: '/viewasset:id',
    name: 'View Complete Asset Details',
    component: ViewAsset
  },

  {
    path: '/assetreport',
    name: 'Asset Report',
    component: AssetReport
  },


  {
    path: '/renewals',
    name: 'Renewals',
    component: Renewals
  },

  {
    path: '/createrenewal:id',
    name: 'Create Renewal Schedule',
    component: CreateRenewal
  },

  {
    path: '/editrenewal:id',
    name: 'Edit Renewal',
    component: EditRenewal
  },


  {
    path: '/maintenance',
    name: 'Maintenance',
    component: Maintenance
  },

  {
    path: '/createmaintenance:id',
    name: 'Schedule Maintenance',
    component: CreateMaintenance
  },

  {
    path: '/editmaintenance:id',
    name: 'Edit Maintenance',
    component: EditMaintenance
  },

  {
    path: '/vendors',
    name: 'Vendors',
    component: Vendors
  },

  {
    path: '/createvendor',
    name: 'Create Vendor',
    component: CreateVendor
  },

  {
    path: '/editvendor:id',
    name: 'Edit Vendor',
    component: EditVendor
  },

  {
    path: '/vendordocuments:id',
    name: 'Vendor Documents',
    component: VendorDocuments
  },

  {
    path: '/insurers',
    name: 'Insurers',
    component: Insurers
  },

  {
    path: '/createinsurer',
    name: 'Create Insurer',
    component: CreateInsurer
  },

  {
    path: '/editinsurer:id',
    name: 'Edit Insurer',
    component: EditInsurer
  },

  {
    path: '/userapproval:id',
    name: 'User Approval',
    component: UserApproval
  },

  {
    path: '/editapproval:id',
    name: 'Edit Approval',
    component: EditApproval
  },

  {
    path: '/gmdapproval:id',
    name: 'GMD Approval',
    component: GMDApproval
  },

  {
    path: '/edapproval:id',
    name: 'ED Approval',
    component: EDApproval
  },

  {
    path: '/armapproval:id',
    name: 'ARM Approval',
    component: ARMApproval
  },

  {
    path: '/viewapproval:id',
    name: 'View Approval',
    component: ViewApproval
  },

  {
    path: '/approvals',
    name: 'Approvals',
    component: Approvals
  },

  {
    path: '/claim',
    name: 'Claim',
    component: Claim
  },

  {
    path: '/createclaim',
    name: 'Asset Claim',
    component: CreateClaim
  },

  {
    path: '/claimdocuments:id',
    name: 'Claim Documents',
    component: ClaimDocuments
  },

  {
    path: '/editclaim:id',
    name: 'Edit Claim',
    component: EditClaim
  },

  {
    path: '/claimreport',
    name: 'Claim Report',
    component: ClaimReport
  },

  {
    path: '/mail',
    name: 'Mail',
    component: Mail
  },

  {
    path: '/tasks',
    name: 'Tasks',
    component: Tasks
  },

  {
    path: '/createtask',
    name: 'Create Task',
    component: CreateTask
  },

  {
    path: '/edittask:id',
    name: 'Edit Task',
    component: EditTask
  },


  {
    path: '/company',
    name: 'Company',
    component: Company
  },

  {
    path: '/createcompany',
    name: 'Create Company',
    component: CreateCompany
  },

  {
    path: '/editcompany:id',
    name: 'Edit Company',
    component: EditCompany
  },


  {
    path: '/departments',
    name: 'Departments',
    component: Departments
  },

  {
    path: '/createdepartment',
    name: 'Create Department',
    component: CreateDepartment
  },

  {
    path: '/editdepartment:id',
    name: 'Edit Department',
    component: EditDepartment
  },

  {
    path: '/delivery',
    name: 'Delivery',
    component: Delivery
  },

  {
    path: '/createdelivery',
    name: 'Create Delivery',
    component: CreateDelivery
  },

  {
    path: '/editdelivery:id',
    name: 'Edit Delivery',
    component: EditDelivery
  },

  {
    path: '/requisition',
    name: 'Requisition',
    component: Requisition
  },

  {
    path: '/createrequisition',
    name: 'Create Requisition',
    component: CreateRequisition
  },

  {
    path: '/editrequisition:id',
    name: 'Edit Requisition',
    component: EditRequisition
  },

  {
    path: '/unitheadapproval',
    name: 'Unit Head Approval',
    component: UnitHeadApproval
  },

  {
    path: '/adminheadapproval',
    name: 'Admin Head Approval',
    component: AdminHeadApproval
  },

  {
    path: '/renewalreport',
    name: 'Renewal Report',
    component: RenewalReport
  },

  {
    path: '/maintenancereport',
    name: 'Maintenance Report',
    component: MaintenanceReport
  },

  {
    path: '/deliveryreport',
    name: 'Delivery Report',
    component: DeliveryReport
  },

  {
    path: '/requisitionreport',
    name: 'Requisition Report',
    component: RequisitionReport
  },

  {
    path: '/vendorreport',
    name: 'Vendor Report',
    component: VendorReport
  },

  {
    path: '/assetreallocationreport',
    name: 'Asset Reallocation Report',
    component: AssetReallocationReport
  },

  {
    path: '/assetdisposalreport',
    name: 'Asset Disposal Report',
    component: AssetDisposalReport
  },

  {
    path: '/assettransferreport',
    name: 'Asset Transfer Report',
    component: AssetTransferReport
  },

  {
    path: '/assetretirementreport',
    name: 'Asset Retirement Report',
    component: AssetRetirementReport
  },

  {
    path: '/auditreport',
    name: 'Audit Report',
    component: AuditReport
  },

  {
    path: '/retirements',
    name: 'Retirements',
    component: Retirements,
  },

  {
    path: '/gmdretirementapproval:id',
    name: 'GMD Retirement Approval',
    component: GMDRetirementApproval
  },

  {
    path: '/edretirementapproval:id',
    name: 'ED Retirement Approval',
    component: EDRetirementApproval
  },

  {
    path: '/armretirementapproval:id',
    name: 'ARM Retirement Approval',
    component: ARMRetirementApproval
  },

  {
    path: '/viewretirementapproval:id',
    name: 'View Retirement Approval',
    component: ViewRetirementApproval
  },

  {
    path: '/retirementapprovals',
    name: 'Retirement Approvals',
    component: RetirementApprovals
  },
  
  {
    path: '/createretirement',
    name: 'Create Retirement',
    component: CreateRetirement
  },

  {
    path: '/editretirement:id',
    name: 'Edit Retirement',
    component: EditRetirement
  },

  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
