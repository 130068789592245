<template>
  <!--Main Content-->
  <div class="main-content px-0 app-content">
    <!--Main Content Container-->
    <div class="container-fluid">
      <!--Page Header-->
      <Breadcrumb />
      <!--Page Header-->

      <!--Row-->
      <div class="row row-sm">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-margin">
          <div class="card">
            <div class="card-body pb-0 border-bottom">
              <div class="d-flex mb-3">
                <h6 class="card-title mb-0">Requisition</h6>
                <div class="card-option ml-auto d-none d-md-flex">
                  <div class="mr-2"></div>
                </div>
              </div>
            </div>
            <div class="">
              <div class="table-responsive">
                <table
                  id="example"
                  class="table table-striped"
                  style="width: 100%"
                >
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>Company</th>
                      <th>Department</th>
                      <th>Type</th>
                      <th>Description</th>
                      <th>Status</th>

                      <th>Approve / Disapprove</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="requisition in requisitions"
                      :key="requisition.id"
                    >
                      <td>{{ requisition.id }}</td>
                      <td>{{ requisition.company }}</td>
                      <td>{{ requisition.department }}</td>
                      <td>{{ requisition.type }}</td>
                      <td>{{ requisition.description }}</td>
                      <td>{{ requisition.status }}</td>

                      <td v-if="requisition.status == 'Unit Head Approved'">
                        <button
                          type="button"
                          class="disabled btn btn-success"
                          data-toggle="modal"
                          data-target="#approve"
                        >
                          Approved
                        </button>
                      </td>
                      <td
                        v-else-if="requisition.status == 'Admin Head Approved'"
                      >
                        <button
                          type="button"
                          class="disabled btn btn-success"
                          data-toggle="modal"
                          data-target="#approve"
                        >
                          Approval Complete
                        </button>
                      </td>

                      <td v-else>
                        <button
                          type="button"
                          @click.prevent="fetchData(requisition)"
                          class="btn btn-primary"
                          data-toggle="modal"
                          data-target="#approve"
                        >
                          Approve
                        </button>

                        <button
                          type="button"
                          @click.prevent="fetchData(requisition)"
                          class="btn btn-primary ml-3"
                          data-toggle="modal"
                          data-target="#disapprove"
                        >
                          Disapprove
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- COL END -->
      </div>

      <!-- Modal -->
      <div
        class="modal fade"
        id="approve"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Approve Requisition
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form>
              <div class="modal-body">
                <label>ID</label>
                <input
                  type="text"
                  class="form-control"
                  :value="requisition.id"
                  disabled
                />

                <label>Company ID</label>
                <input
                  type="text"
                  class="form-control"
                  :value="requisition.companyId"
                  disabled
                />

                <label>Department Id</label>
                <input
                  type="text"
                  class="form-control"
                  :value="requisition.departmentId"
                  disabled
                />

                <label>Type</label>
                <input
                  type="text"
                  class="form-control"
                  :value="requisition.type"
                  disabled
                />

                <label>serialNumber</label>
                <input
                  type="text"
                  class="form-control"
                  :value="requisition.serialNumber"
                  disabled
                />

                <label>date</label>
                <input
                  type="text"
                  class="form-control"
                  :value="requisition.date"
                  disabled
                />

                <label>description</label>
                <input
                  type="text"
                  class="form-control"
                  :value="requisition.description"
                  disabled
                />

                <label>quantityRequired</label>
                <input
                  type="text"
                  class="form-control"
                  :value="requisition.quantityRequired"
                  disabled
                />

                <label>codeno</label>
                <input
                  type="text"
                  class="form-control"
                  :value="requisition.codeno"
                  disabled
                />

                <label>quantitySupplied</label>
                <input
                  type="text"
                  class="form-control"
                  :value="requisition.quantitySupplied"
                  disabled
                />

                <label>remark</label>
                <input
                  type="text"
                  class="form-control"
                  :value="requisition.remark"
                  disabled
                />

                <label>requisitionBy</label>
                <input
                  type="text"
                  class="form-control"
                  :value="requisition.requisitionBy"
                  disabled
                />

                <label>status</label>
                <input
                  type="text"
                  class="form-control"
                  :value="requisition.status"
                  disabled
                />

                <label>receivedBy</label>
                <input
                  type="text"
                  class="form-control"
                  :value="requisition.receivedBy"
                  disabled
                />

                <label>Unit Head Comments</label>
                <textarea
                  v-model="comment"
                  class="form-control"
                  rows="3"
                ></textarea>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  @click="approve()"
                  class="btn btn-primary"
                >
                  Approve
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <!-- Modal -->
      <div
        class="modal fade"
        id="disapprove"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Disapprove Requisition
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div class="modal-body">
              <label>ID</label>
              <input
                type="text"
                class="form-control"
                :value="requisition.id"
                disabled
              />

              <label>Company ID</label>
              <input
                type="text"
                class="form-control"
                :value="requisition.companyId"
                disabled
              />

              <label>Department Id</label>
              <input
                type="text"
                class="form-control"
                :value="requisition.departmentId"
                disabled
              />

              <label>Type</label>
              <input
                type="text"
                class="form-control"
                :value="requisition.type"
                disabled
              />

              <label>serialNumber</label>
              <input
                type="text"
                class="form-control"
                :value="requisition.serialNumber"
                disabled
              />

              <label>date</label>
              <input
                type="text"
                class="form-control"
                :value="requisition.date"
                disabled
              />

              <label>description</label>
              <input
                type="text"
                class="form-control"
                :value="requisition.description"
                disabled
              />

              <label>quantityRequired</label>
              <input
                type="text"
                class="form-control"
                :value="requisition.quantityRequired"
                disabled
              />

              <label>codeno</label>
              <input
                type="text"
                class="form-control"
                :value="requisition.codeno"
                disabled
              />

              <label>quantitySupplied</label>
              <input
                type="text"
                class="form-control"
                :value="requisition.quantitySupplied"
                disabled
              />

              <label>remark</label>
              <input
                type="text"
                class="form-control"
                :value="requisition.remark"
                disabled
              />

              <label>requisitionBy</label>
              <input
                type="text"
                class="form-control"
                :value="requisition.requisitionBy"
                disabled
              />

              <label>status</label>
              <input
                type="text"
                class="form-control"
                :value="requisition.status"
                disabled
              />

              <label>receivedBy</label>
              <input
                type="text"
                class="form-control"
                :value="requisition.receivedBy"
                disabled
              />

              <label>Unit Head Comments</label>
              <textarea
                v-model="comment"
                class="form-control"
                rows="3"
              ></textarea>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                @click="disapprove()"
                class="btn btn-primary"
              >
                Disapprove
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Main Content Container-->
  </div>
  <!--Main Content-->
</template>

<script>
import $ from "jquery";
import Breadcrumb from "@/components/Breadcrumb";

export default {
  components: {
    Breadcrumb,
  },
  props: ["user", "BaseURL"],
  data() {
    return {
      requisitions: [],

      comment: "",

      requisition: {},
    };
  },

  methods: {
    fetchData(data) {
      fetch(
        this.BaseURL +
          "/getStationeryRequisitionICTConsumableDeviceById" +
          data.id,
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          //console.log(data)
          this.requisition = data;
        });
    },

    approve() {
      fetch(
        this.BaseURL + "/unitHeadStationeryRequisitionICTConsumableDevice",
        {
          method: "POST",
          body: JSON.stringify({
            id: this.requisition.id,
            status: "Unit Head Approved",
            submittedBy: this.user.email,
            unitHeadComment: this.comment,
          }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          this.isSuccessful = data.isSuccessful;
          this.errorMessage = data.message;

          if (this.isSuccessful == true) {
            location.reload();
          }
        });
    },

    disapprove() {
      fetch(
        this.BaseURL + "/unitHeadStationeryRequisitionICTConsumableDevice",
        {
          method: "POST",
          body: JSON.stringify({
            id: this.requisition.id,
            status: "Unit Head Disapproved",
            submittedBy: this.user.email,
            unitHeadComment: this.comment,
          }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          this.isSuccessful = data.isSuccessful;
          this.errorMessage = data.message;

          if (this.isSuccessful == true) {
            location.reload();
          }
        });
    },

    close() {
      this.closed = true;
    },
  },
  beforeCreate() {
    if (localStorage.getItem("role") !== "Unit Head") {
      window.location = "/error";
    }
  },
  mounted() {
    fetch(this.BaseURL + "/getAllStationeryRequisitionICTConsumableDevice", {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        //console.log(data)
        this.requisitions = data;
        $(() => {
          $("#example").DataTable();
        });
      });
  },
};
</script>