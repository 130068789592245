<template>
  <!--Main Content-->
  <div class="main-content px-0 app-content">
    <!--Main Content Container-->
    <div class="container-fluid">
      <!--Page Header-->
      <Breadcrumb />
      <!--Page Header-->

      <div class="row row-sm">
        <div class="col-lg-4">
          <div class="card border-left border-info mg-b-20">
            <div class="card-body">
              <div class="d-sm-flex">
                <div class="col-lg-10 mt-2">
                  <h2 class="mb-1">
                    <span class="tx-30 font-weight-bold">{{
                      assets.length
                    }}</span>
                  </h2>
                  <h6>Total Recorded Company Assets</h6>
                  <router-link class="btn btn-info rounded-lg mt-2" to="/assets"
                    >View All</router-link
                  >
                </div>

                <div class="mr-3 mt-5">
                  <img
                    src="../assets/assets.png"
                    alt="img"
                    class="wd-70 asset-image float-right"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="card border-left border-success mg-b-20">
            <div class="card-body">
              <div class="d-sm-flex">
                <div class="col-lg-10 mt-2">
                  <h2 class="mb-1">
                    <span class="tx-30 font-weight-bold">{{
                      pendingTasks.length + tasks.length
                    }}</span>
                  </h2>
                  <h6>Total Created Tasks</h6>
                  <router-link
                    class="btn btn-success rounded-lg mt-2"
                    to="/createtask"
                    >Create New Task</router-link
                  >
                </div>

                <div class="mr-3 mt-5">
                  <img
                    src="../assets/to-do-list.png"
                    alt="img"
                    class="wd-70 asset-image float-right"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="card border-left border-warning mg-b-20">
            <div class="card-body">
              <div class="d-sm-flex">
                <div class="col-lg-10 mt-2">
                  <h2 class="mb-1">
                    <span class="tx-30 font-weight-bold">{{
                      pendingTasks.length
                    }}</span>
                  </h2>
                  <h6>Total Pending Tasks</h6>
                  <router-link
                    class="btn btn-warning rounded-lg mt-2"
                    to="/tasks"
                    >View All Tasks</router-link
                  >
                </div>

                <div class="mr-3 mt-5">
                  <img
                    src="../assets/clock.png"
                    alt="img"
                    class="wd-70 asset-image float-right"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Actions-->

        <!-- <div class="col-lg-3">
						<div class="card bg-info mg-b-20">
							<div class="card-body">
								<div class="d-sm-flex">

								
									
									<div class="col-lg-10 mt-2">
										<h2 class="mb-1"><span class="tx-30 font-weight-bold">{{assets.length}}</span></h2>
										<h6>Total Recorded Company Assets</h6>
										
									</div>

									<div class="mr-3 mt-5">
										<img src="../assets/assets.png" alt="img" class="wd-70 asset-image float-right">
									</div>

									
								</div>
							</div>
						</div>
          </div>


          <div class="col-lg-3">
						<div class="card bg-success mg-b-20">
							<div class="card-body">
								<div class="d-sm-flex">

								
									
									<div class="col-lg-10 mt-2">
										<h2 class="mb-1"><span class="tx-30 font-weight-bold">{{assets.length}}</span></h2>
										<h6>Total Recorded Company Assets</h6>
										
									</div>

									<div class="mr-3 mt-5">
										<img src="../assets/assets.png" alt="img" class="wd-70 asset-image float-right">
									</div>

									
								</div>
							</div>
						</div>
          </div>



          <div class="col-lg-3">
						<div class="card bg-warning mg-b-20">
							<div class="card-body">
								<div class="d-sm-flex">

								
									
									<div class="col-lg-10 mt-2">
										<h2 class="mb-1"><span class="tx-30 font-weight-bold">{{assets.length}}</span></h2>
										<h6>Total Recorded Company Assets</h6>
										
									</div>

									<div class="mr-3 mt-5">
										<img src="../assets/assets.png" alt="img" class="wd-70 asset-image float-right">
									</div>

									
								</div>
							</div>
						</div>
          </div>

          <div class="col-lg-3">
						<div class="card bg-danger mg-b-20">
							<div class="card-body">
								<div class="d-sm-flex">

								
									
									<div class="col-lg-10 mt-2">
										<h2 class="mb-1"><span class="tx-30 font-weight-bold">{{assets.length}}</span></h2>
										<h6>Total Recorded Company Assets</h6>
										
									</div>

									<div class="mr-3 mt-5">
										<img src="../assets/assets.png" alt="img" class="wd-70 asset-image float-right">
									</div>

									
								</div>
							</div>
						</div>
          </div> -->

        <!-- Actions -->

        <div class="col-lg-7">
          <div class="pending-card card mg-b-20">
            <div class="card-body">
              <div class="card-header pt-0 pl-0 pr-0 d-flex">
                <div>
                  <label class="main-content-label mb-1"
                    >Task Completion Status</label
                  >
                  <span class="d-block tx-12 text-muted"
                    >Project Session Details From Past & Days</span
                  >
                </div>
                <div class="card-option ml-auto d-none d-md-flex">
                  <a href="#" class="text-dark"
                    ><i class="fe fe-more-horizontal"></i
                  ></a>
                </div>
              </div>
              <div class="row mt-2 row-sm">
                <div class="col-md-8">
                  <div class="mb-4">
                    <h5 class="mb-2 d-block">
                      <span class="tx-14 text-muted"
                        ><b>All Completed Task</b> Status</span
                      >
                      <span class="float-right"
                        >{{ completedTasksPercentage }}% Done</span
                      >
                    </h5>
                    <div class="progress rounded-lg mt-1">
                      <div
                        :class="
                          'progress-bar ht-15 bg-info wd-' +
                          Math.round(completedTasksPercentage / 5) * 5 +
                          'p'
                        "
                      ></div>
                    </div>
                  </div>
                  <div class="mb-0">
                    <h5 class="mb-2 d-block">
                      <span class="tx-14 text-muted"
                        ><b>All Uncompleted Task</b> Status</span
                      >
                      <span class="float-right"
                        >{{ pendingTasksPercentage }}% Undone</span
                      >
                    </h5>
                    <div class="progress rounded-lg mt-1">
                      <div
                        :class="
                          'progress-bar ht-15 bg-secondary wd-' +
                          Math.round(pendingTasksPercentage / 5) * 5 +
                          'p'
                        "
                      ></div>
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <!--div class="chart-circle mt-lg-3" data-value="2.00" data-thickness="20" data-color="#4c84ff"><canvas width="140" height="140"></canvas>
													<div class="chart-circle-value circle-style"><div class="tx-20 font-weight-bold">75%</div></div>
												</div-->

                  <img
                    style="width: 50%"
                    class="float-right mt-5 float-sm-start"
                    src="../assets/task.svg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-5">
          <div class="card pending-card mg-b-20">
            <div class="pending-card-body card-body">
              <div class="card-header pt-0 pl-0 pr-0 d-flex">
                <div>
                  <label class="main-content-label mb-1"
                    >Latest Pending Tasks</label
                  >
                  <span class="d-block tx-12 text-muted"
                    >Due tasks for this week</span
                  >
                </div>
                <div class="card-option ml-auto d-none d-md-flex">
                  <a href="#" class="text-dark"
                    ><i class="fe fe-more-horizontal"></i
                  ></a>
                </div>
              </div>
              <div class="row mt-2 row-sm">
                <div class="col-md-12">
                  <div v-if="pendingTasks.length == 0">
                    <img
                      src="https://cdn.dribbble.com/users/1753953/screenshots/3818675/animasi-emptystate.gif"
                      style="width: 30%"
                      class="d-block mx-auto"
                    />
                    <p class="text-center">No <b>pending </b>tasks</p>
                  </div>
                  <table v-else class="table">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Task Name</th>
                        <th scope="col">Description</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(task, i) in pendingTasks" :key="task.id">
                        <th scope="row">{{ i + 1 }}</th>
                        <td>{{ task.name }}</td>
                        <td>{{ task.description }}</td>
                        <td>
                          <button
                            v-if="loading"
                            class="btn btn-success"
                            disabled
                          >
                            Loading
                            <div
                              class="spinner-border spinner-border-sm"
                              role="status"
                            >
                              <span
                                style="width: 2px"
                                class="sr-only text-light"
                                >Loading...</span
                              >
                            </div>
                          </button>
                          <button
                            v-else
                            @click="markTaskDone(task)"
                            class="btn btn-success"
                          >
                            Mark As Done
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- ROW-3 -->

      <!--Row-->
      <div class="row row-sm">
        <div class="col-sm-12 mb-4 col-md-6 col-lg-6 col-xl-6 grid-margin">
          <div class="card t-card">
            <div class="card-body pb-0 border-bottom">
              <div class="d-flex mb-3">
                <h6 class="card-title mb-0">
                  {{ allMaintenance.length }} Assets Up For Maintenance
                </h6>
                <div class="card-option ml-auto d-none d-md-flex">
                  <a href="#" class="text-dark"
                    ><i class="fe fe-more-horizontal"></i
                  ></a>
                </div>
              </div>
            </div>
            <div class="">
              <div class="table-responsive">
                <table
                  id="dtBasicExample"
                  class="table table-striped"
                  style="width: 100%"
                  cellspacing="0"
                >
                  <thead>
                    <tr>
                      <th>Maintenance Id</th>
                      <th>Maintenance Name</th>
                      <th>Asset ID</th>
                      <th>Staff Assigned To</th>
                      <th>Date</th>
                      <th>Maintenance Due Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="maintenance in allMaintenance"
                      :key="maintenance.id"
                    >
                      <td>{{ maintenance.id }}</td>
                      <td>{{ maintenance.name }}</td>
                      <td>{{ maintenance.assetId }}</td>
                      <td>{{ maintenance.staffAssignTo }}</td>
                      <td>{{ maintenance.date.split("T")[0] }}</td>
                      <td>
                        {{ maintenance.maintenanceDueDate.split("T")[0] }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- COL END -->

        <div
          class="table-card col-sm-12 col-md-6 col-lg-6 col-xl-6 grid-margin"
        >
          <div class="card t-card">
            <div class="card-body pb-0 border-bottom">
              <div class="d-flex mb-3">
                <h6 class="card-title mb-0">
                  {{ renewals.length }} Assets Up For Renewals
                </h6>
                <div class="card-option ml-auto d-none d-md-flex">
                  <a href="#" class="text-dark"
                    ><i class="fe fe-more-horizontal"></i
                  ></a>
                </div>
              </div>
            </div>
            <div class="">
              <div class="table-responsive">
                <table
                  id="example"
                  class="table table-striped"
                  style="width: 100%"
                >
                  <thead>
                    <tr>
                      <th>Renewal Name</th>
                      <th>Renewal Type</th>
                      <th>Registration Number</th>
                      <th>Expiry Date</th>
                      <th>Renewal Cost</th>
                      <th>Driver Name</th>
                      <th>Total Amount</th>
                      <th>Staff Assigned To</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="renewal in renewals" :key="renewal.id">
                      <td>{{ renewal.name }}</td>
                      <td>{{ renewal.renewalTypeId }}</td>
                      <td>{{ renewal.registrationNumber }}</td>
                      <td>{{ renewal.expirydate.split("T")[0] }}</td>
                      <td>{{ renewal.renewalCost }}</td>
                      <td>{{ renewal.driverName }}</td>
                      <td>{{ renewal.totalAmount }}</td>
                      <td>{{ renewal.staffAssignTo }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- COL END -->
      </div>
    </div>
    <!--Main Content Container-->
  </div>
  <!--Main Content-->
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'
// import Topbar from '@/components/Topbar'
// import Sidebar from '@/components/Sidebar'
import Breadcrumb from "@/components/Breadcrumb";

export default {
  name: "Home",
  components: {
    // HelloWorld
    Breadcrumb,
  },
  props: ["user", "BaseURL"],
  data() {
    return {
      loading: false,
      errorMessage: "",
      isSuccessful: "",

      assets: [],
      tasks: [],
      pendingTasks: [],
      allMaintenance: [],
      renewals: [],
      allRenewalNotification: [],
      allMaintenanceNotification: [],
      username: localStorage.getItem("username"),
    };
  },
  methods: {
    markTaskDone(task) {
      this.loading = true;
      fetch(this.BaseURL + "/updateTask", {
        method: "POST",
        body: JSON.stringify({
          id: task.id,
          name: task.name,
          assetId: task.assetId,
          description: task.description,
          submittedBy: this.user.email,
          dueDate: task.dueDate,
          status: true,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          this.isSuccessful = data.isSuccessful;
          this.errorMessage = data.message;

          if (this.isSuccessful == true) {
            this.loading = false;
            location.reload();
          } else {
            alert(`Error : ${this.errorMessage}`);
          }
        });
    },
  },

  computed: {
    pendingTasksPercentage() {
      var pendingTasksPercent =
        (this.pendingTasks.length /
          (this.pendingTasks.length + this.tasks.length)) *
        100;
      if (this.pendingTasks.length < 1) {
        return "0.00";
      }
      return Math.round(pendingTasksPercent);
    },

    completedTasksPercentage() {
      var completedTasksPercent =
        (this.tasks.length / (this.pendingTasks.length + this.tasks.length)) *
        100;

      if (this.tasks.length < 1) {
        return "0.00";
      }
      return Math.round(completedTasksPercent);
    },
  },

  beforeCreate() {
    if (localStorage.getItem("role") == "Admin") {
      window.location = "/users";
    }
  },
  mounted() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    today = mm + "/" + dd + "/" + yyyy;
    //console.log(today);

    fetch(this.BaseURL + "/getAllAsset", {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        //console.log(data)
        this.assets = data;
      });

    fetch(this.BaseURL + "/getAllPendingTask?SubmittedBy=" + this.username, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        //console.log(data)
        this.pendingTasks = data;
      });

    fetch(this.BaseURL + "/getAllCompletedTask?SubmittedBy=" + this.username, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        //console.log(data)
        this.tasks = data;
      });

    fetch(this.BaseURL + "/getAllRenewal", {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        //console.log(data)
        this.renewals = data;
      });

    fetch(this.BaseURL + "/getAllMaintenance", {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        //console.log(data)
        this.allMaintenance = data;
      });
  },
};
</script>

<style scoped>
.table-card {
  height: 50vh;
}

.p-btn {
  padding: 5px;
}

.pending-card {
  height: 30vh;

  overflow: scroll;
}

.t-card {
  height: 50vh;
  overflow: scroll;
}

.pending-card::-webkit-scrollbar {
  display: none;
}
</style>
