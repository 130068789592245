<template>
  <!--Main Content-->
  <div class="main-content px-0 app-content">
    <!--Main Content Container-->
    <div class="container-fluid">
      <!--Page Header-->
      <Breadcrumb />
      <!--Page Header-->
      <div class="forms card rounded">
        <div
          v-bind:class="[closed ? 'close' : '']"
          class="mb-4 bg-warning p-3 text-dark"
          v-if="isSuccessful === false"
        >
          <b>Error : {{ errorMessage }}</b>
          <i @click="close()" class="fas fa-close"></i>
        </div>
        <div v-else></div>

        <form @submit.prevent="editRequisition()">
          <label>Select Company</label>
          <select
            @change="fetchDepartments()"
            v-model="requisition.companyId"
            class="form-control mb-3"
          >
            <option selected>Select Company</option>
            <option
              v-for="company in groupOfCompany"
              :key="company.id"
              :value="company.id"
            >
              {{ company.name }}
            </option>
          </select>

          <label>Change Department</label>
          <select v-model="requisition.departmentId" class="form-control mb-3">
            <option selected>Select</option>
            <option
              v-for="department in departments"
              :key="department.id"
              :value="department.id"
            >
              {{ department.name }}
            </option>
          </select>

          <label>Type</label>
          <input
            v-model="requisition.type"
            type="text"
            class="form-control"
            required
          />

          <label>Serial Number</label>
          <input
            v-model="requisition.serialNumber"
            type="number"
            class="form-control"
            required
          />

          <label>Date</label>
          <input
            v-model="requisition.date.split('T')[0]"
            type="date"
            class="form-control"
            required
          />

          <label>Description</label>
          <input
            v-model="requisition.description"
            type="text"
            class="form-control"
            required
          />

          <label>Quantity Required</label>
          <input
            v-model="requisition.quantityRequired"
            type="number"
            class="form-control"
            required
          />

          <label>Code No</label>
          <input
            v-model="requisition.codeno"
            type="text"
            class="form-control"
            required
          />

          <label>Requisition By</label>
          <input
            v-model="requisition.requisitionBy"
            type="text"
            class="form-control"
            disabled
          />

          <label>Recieved By</label>
          <input
            v-model="requisition.receivedBy"
            type="text"
            class="form-control"
            required
          />

          <label>Quantity Supplied</label>
          <input
            v-model="requisition.quantitySupplied"
            type="number"
            class="form-control"
            required
          />

          <label>Remark</label>
          <input
            v-model="requisition.remark"
            type="text"
            class="form-control"
            required
          />

          <button type="submit" class="btn btn-primary">
            Update Requisition
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";

export default {
  components: {
    Breadcrumb,
  },

  props: ["user", "BaseURL"],

  data() {
    return {
      requisition: {},

      groupOfCompany: [],
      departments: [],

      isSuccessful: "",
      errorMessage: "",

      closed: false,
    };
  },

  methods: {
    fetchDepartments() {
      fetch(
        this.BaseURL +
          "/getDepartmentDD?CompanyId=" +
          this.requisition.companyId,
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          //console.log(data)
          this.departments = data;
        });
    },

    editRequisition() {
      fetch(this.BaseURL + "/updateStationeryRequisitionICTConsumableDevice", {
        method: "POST",
        body: JSON.stringify(this.requisition),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((data) => {
          //console.log(data)
          this.isSuccessful = data.isSuccessful;
          this.errorMessage = data.message;

          if (this.isSuccessful == true) {
            alert("Requisition Edited successfully");
            window.location = "/requisition";
          }
        });
    },

    close() {
      this.closed = !this.closed;
    },
  },

  beforeCreate() {
    fetch(
      this.BaseURL +
        "/getStationeryRequisitionICTConsumableDeviceById" +
        this.$route.params.id,
      {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        //console.log(data)
        this.requisition = data;

        fetch(this.BaseURL + "/getDepartmentDD?CompanyId=" + data.companyId, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
          .then((res) => res.json())
          .then((data) => {
            //console.log(data)
            this.departments = data;
          });
      });

    fetch(this.BaseURL + "/getAllCompany", {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        //console.log(data)
        this.groupOfCompany = data;
      });
  },
};
</script>