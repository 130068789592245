<template>
  <!--Main Content-->
  <div class="main-content px-0 app-content">
    <!--Main Content Container-->
    <div class="container-fluid">
      <!--Page Header-->
      <Breadcrumb />
      <!--Page Header-->

      <!--Row-->
      <div class="row row-sm">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-margin">
          <div class="card">
            <div class="card-body pb-0 border-bottom">
              <div class="d-flex mb-3">
                <h6 class="card-title mb-0">All Insurers</h6>
                <div class="ml-auto d-md-flex">
                  <div class="mr-2">
                    <router-link to="/createInsurer"
                      ><button class="btn btn-primary btn-sm rounded-lg fs-13">
                        <i class="mdi mdi-plus-circle-outline"></i>Create New
                        Insurer
                      </button></router-link
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="">
              <div class="table-responsive">
                <table
                  id="example"
                  class="table table-striped"
                  style="width: 100%"
                >
                  <thead>
                    <tr>
                      <th>Maintenance Id</th>
                      <th>Insurer</th>
                      <th>Edit</th>
                      <!--th>Delete</th-->
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="maintenance in insurers" :key="maintenance.id">
                      <td>{{ maintenance.id }}</td>
                      <td>{{ maintenance.name }}</td>
                      <td>
                        <router-link
                          :to="{
                            name: 'Edit Insurer',
                            params: { id: maintenance.id },
                          }"
                          ><button class="btn btn-primary">
                            Edit
                          </button></router-link
                        >
                      </td>
                      <!--td>
                        <router-link
                          :to="{
                            name: 'Edit Insurer',
                            params: { id: maintenance.id },
                          }"
                          ><button class="btn btn-warning">
                            Delete
                          </button></router-link
                        >
                      </td-->
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- COL END -->
      </div>
    </div>
    <!--Main Content Container-->
  </div>
  <!--Main Content-->
</template>

<script>
import $ from "jquery";
import Breadcrumb from "@/components/Breadcrumb";

export default {
  components: {
    Breadcrumb,
  },
  props: ["user", "BaseURL"],
  data() {
    return {
      insurers: [],
    };
  },

  methods: {},

  beforeCreate() {
    if (localStorage.getItem("role") !== "Admin") {
      window.location = "/error";
    }
  },

  mounted() {
    fetch(this.BaseURL + "/getAllInsurer", {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        //console.log(data)
        this.insurers = data;
        $(() => {
          $("#example").DataTable();
        });
      });
  },
};
</script>