<template>
  <!--Main Content-->
  <div class="main-content px-0 app-content">
    <!--Main Content Container-->
    <div class="container-fluid">
      <!--Page Header-->
      <Breadcrumb />
      <!--Page Header-->
      <div class="forms card rounded">
        <div
          v-bind:class="[closed ? 'close' : '']"
          class="mb-4 bg-warning p-3 text-dark"
          v-if="isSuccessful === false"
        >
          <b>Error : {{ errorMessage }}</b>
          <i @click="close()" class="fas fa-close"></i>
        </div>
        <div v-else></div>
        <form @submit.prevent="approve()">
          <!-- <label>Asset Id</label>
          <input :value="requisition.id" type="text" class="form-control" disabled /> -->

          <label>Asset Name</label>
          <input
            :value="requisition.assetName"
            type="text"
            class="form-control"
            disabled
          />

          <!--label>Asset Purchase Date</label>
          <input
            :value="requisition.purchaseDate.split('T')[0]"
            type="date"
            class="form-control"
            disabled
          />

          <label>Asset Purchase Amount</label>
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">₦</span>
            </div>

            <input
              type="text"
              :value="
                new Intl.NumberFormat().format(requisition.purchaseAmount)
              "
              class="form-control"
              disabled
            />
            <div class="input-group-append">
              <span class="input-group-text">.00</span>
            </div>
          </div-->

          <label>Present Location Of Asset</label>
          <input
            v-model="requisition.presentLocation"
            type="text"
            class="form-control"
            required
          />

          <label>Class Of Asset</label>
          <input
            v-model="requisition.classofAsset"
            type="text"
            class="form-control"
            required
          />

          <label>Type</label>
          <select v-model="requisition.type" class="form-control mb-3" required>
            <option selected>Select</option>
            <option value="Reallocation">Reallocation</option>
            <option value="Disposal">Disposal</option>
            <option value="Transfer">Transfer</option>
          </select>

          <!-- <label>Sales Value</label>
          <input
            v-model="salesValue"
            type="number"
            class="form-control"
            required
          /> -->

          <label>Date Of {{ requisition.type }}</label>
          <input
            :value="requisition.date.split('T')[0]"
            type="text"
            class="form-control"
            disabled
          />

          <label>New Date Of {{ requisition.type }}</label>
          <input v-model="requisition.date" type="date" class="form-control" />

          <div v-if="requisition.type == 'Reallocation' || type == 'Disposal'">
            <label>Asset Sales Value</label>
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text">₦</span>
              </div>

              <input
                type="text"
                @change="format1()"
                @click="salesValue = ''"
                v-model="salesValue"
                class="form-control"
              />
              <div class="input-group-append">
                <span class="input-group-text">.00</span>
              </div>
            </div>

            <label>{{ type }} Destination</label>
            <input
              v-model="requisition.destination"
              type="text"
              class="form-control"
            />

            <label>Asset Accumulated Depreciation</label>
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text">₦</span>
              </div>

              <input
                type="text"
                @change="format2()"
                @click="accumulatedDepreciation = ''"
                v-model="requisition.accumulatedDepreciation"
                class="form-control"
              />
              <div class="input-group-append">
                <span class="input-group-text">.00</span>
              </div>
            </div>

            <!-- <label>Accumulated Depreciation</label>
          <input
            v-model="accumulatedDepreciation"
            type="number"
            class="form-control"
            required
          /> -->

            <label>Receipt Number</label>
            <input
              v-model="requisition.receiptNumber"
              type="number"
              class="form-control"
            />
          </div>

          <div v-if="requisition.type == 'Transfer'">
            <label>Admin Officer</label>
            <input
              v-model="requisition.adminOfficer"
              type="text"
              class="form-control"
            />

            <label>Fixed Asset Manager</label>
            <input
              v-model="requisition.fixedAssetManager"
              type="text"
              class="form-control"
              required
            />
          </div>

          <label>ED Approval Submitted By</label>
          <input
            :value="requisition.edApproval"
            type="text"
            class="form-control"
            disabled
          />

          <label>ED Approval Date</label>
          <input
            :value="requisition.edApprovalDate.split('T')[0]"
            type="text"
            class="form-control"
            disabled
          />

          <label>ED Comment</label>
          <textarea
            v-model="requisition.edComment"
            class="form-control mb-3"
            rows="3"
            disabled
          ></textarea>

          <label>GMD Approval Submitted By</label>
          <input
            :value="requisition.gmdApproval"
            type="text"
            class="form-control"
            disabled
          />

          <label>GMD Approval Date</label>
          <input
            :value="requisition.gmdApprovalDate.split('T')[0]"
            type="text"
            class="form-control"
            disabled
          />

          <label>GMD Comment</label>
          <textarea
            v-model="requisition.gmdComment"
            class="form-control mb-3"
            rows="3"
            disabled
          ></textarea>

          <label>ARM Approval Submitted By</label>
          <input
            :value="requisition.armApproval"
            type="text"
            class="form-control"
            disabled
          />

          <label>ARM Approval Date</label>
          <input
            :value="requisition.armApprovalDate.split('T')[0]"
            type="text"
            class="form-control"
            disabled
          />

          <label>ARM Comment</label>
          <textarea
            v-model="requisition.armComment"
            class="form-control mb-3"
            rows="3"
            disabled
          ></textarea>

          <label>User Comment</label>
          <textarea
            v-model="requisition.userComment"
            class="form-control mb-3"
            rows="3"
            required
          ></textarea>

          <label>Approval Submitted By</label>
          <input
            :value="user.email"
            type="text"
            class="form-control"
            disabled
          />

          <button type="submit" class="btn btn-primary">
            Approve {{ type }}
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";

export default {
  components: {
    Breadcrumb,
  },
  props: ["user", "BaseURL"],
  data() {
    return {
      requisition: {},

      requisitionClass: " ",
      requisitionLocation: " ",
      date: "0000-00-00",
      salesValue: "0",
      destination: "In House",
      type: " ",
      accumulatedDepreciation: "0",
      recieptNumber: 0,
      userComment: " ",
      requisitionManager: " ",
      adminOfficer: " ",

      isSuccessful: "",
      errorMessage: "",

      closed: "",
    };
  },

  methods: {
    format1() {
      this.salesValue = new Intl.NumberFormat().format(
        parseInt(this.salesValue.replace(",", ""))
      );
    },

    format2() {
      this.accumulatedDepreciation = new Intl.NumberFormat().format(
        parseInt(this.accumulatedDepreciation.replace(",", ""))
      );
    },

    approve() {
      fetch(this.BaseURL + "/UserApprovalEdit", {
        method: "POST",
        body: JSON.stringify({
          id: this.requisition.id,
          assetId: this.requisition.assetId,
          assetName: this.requisition.assetName,
          classofAsset: this.requisition.classofAsset,
          presentLocation: this.requisition.presentLocation,
          adminOfficer: this.requisition.adminOfficer,
          fixedAssetManager: this.requisition.fixedAssetManager,
          date: this.requisition.date,
          salesvalue: this.requisition.salesvalue,
          destination: this.requisition.destination,
          type: this.requisition.type,
          accumulatedDepreciation: this.requisition.accumulatedDepreciation,
          receiptNumber: this.requisition.recieptNumber,
          submittedBy: this.user.email,
          userComment: this.requisition.userComment,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          this.isSuccessful = data.isSuccessful;
          this.errorMessage = data.message;

          if (this.isSuccessful == true) {
            alert(
              this.requisition.assetName +
                " Approved For " +
                this.requisition.type +
                " Again"
            );
            window.location = "/approvals";
          }
        });
    },

    close() {
      this.closed = true;
    },
  },

  beforeCreate() {
    if (localStorage.getItem("role") !== "User") {
      window.location = "/error";
    }
  },

  created() {
    fetch(
      this.BaseURL +
        "/getByIdAssetReallocationDisposal" +
        this.$route.params.id,
      {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        //console.log(data)
        this.requisition = data;
      });
  },
};
</script>