<template>
  <!--Main Content-->
  <div class="main-content px-0 app-content">
    <!--Main Content Container-->
    <div class="container-fluid">
      <!--Page Header-->
      <Breadcrumb />
      <!--Page Header-->

      <ErrorMessage />

      <div class="forms card rounded">
        <label>Claim ID</label>
        <input
          type="text"
          :value="claim.id"
          class="form-control"
          disabled="disabled"
        />

        <label>Asset ID</label>
        <input
          type="text"
          :value="claim.assetId"
          class="form-control"
          disabled="disabled"
        />

        <label>Asset Name</label>
        <input
          type="text"
          :value="claim.assetName"
          class="form-control"
          disabled="disabled"
        />

        <label>Insurer Name</label>
        <input
          type="text"
          :value="claim.insurerName"
          class="form-control"
          disabled="disabled"
        />

        <label>Claim Status</label>
        <input
          type="text"
          :value="claim.status"
          class="form-control"
          disabled="disabled"
        />

        <Label>Uploaded Documents ({{ claim.documentsUrl.length }})</Label>
        <ul v-for="document in claim.documentsUrl" :key="document.id">
          <li>
            <b>{{ document.documentName }}</b>
            <a
              class="ml-3"
              :href="
                'https://chiadminapi.chiplc.com/IMAGE/' + document.documentsUrl
              "
              target="_blank"
              >( View Document )</a
            >
          </li>
        </ul>

        <form @submit.prevent="upload()">
          <label class="mt-5">Document Name</label>
          <input type="text" v-model="name" class="form-control" />

          <label class="mg-b-20">Choose Document</label>

          <div class="">
            <input
              type="file"
              @change="handleFileUpload($event)"
              class="dropify"
              data-height="180"
            />
          </div>

          <button type="submit" class="btn btn-primary">Upload Document</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import ErrorMessage from "@/components/ErrorMessage";
import Breadcrumb from "@/components/Breadcrumb";
import $ from "jquery";

export default {
  components: {
    Breadcrumb,
    ErrorMessage,
  },

  props: ["user", "BaseURL"],

  data() {
    return {
      claim: {},
      document: null,
      name: "",
    };
  },

  created() {
    fetch(this.BaseURL + "/getClaimById" + this.$route.params.id, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        //console.log(data)
        this.claim = data;
      });
  },

  methods: {
    handleFileUpload(e) {
      this.document = e.target.files[0];
    },

    upload() {
      let formData = new FormData();
      formData.append("FormFile", this.document);
      formData.append("ClaimId", this.claim.id);
      formData.append("DocumentsUrl", " ");
      formData.append("DocumentName", this.name);
      formData.append("SubmittedBy", this.user.email);

      fetch(this.BaseURL + "/UploadClaimDocument", {
        method: "POST",
        body: formData,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          this.isSuccessful = data.isSuccessful;
          this.errorMessage = data.message;

          if (this.isSuccessful == true) {
            window.location.reload();
          } else {
            $("#myModal").modal("show");
          }
        });
    },
  },
};
</script>