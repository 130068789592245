<template>
  <!--Main Content-->
  <div class="main-content px-0 app-content">
    <!--Main Content Container-->
    <div class="container-fluid">
      <!--Page Header-->
      <Breadcrumb />
      <!--Page Header-->
      <div class="forms card rounded">
        <CreateUser :BaseURL="this.BaseURL" />
      </div>
    </div>
  </div>
</template>

<script>
import CreateUser from "@/components/CreateUser";
import Breadcrumb from "@/components/Breadcrumb";

export default {
  components: {
    CreateUser,
    Breadcrumb,
  },
  props: ["BaseURL"],
};
</script>