<template>
  <!--Main Content-->
  <div class="main-content px-0 app-content">
    <!--Main Content Container-->
    <div class="container-fluid">
      <!--Page Header-->
      <Breadcrumb />
      <!--Page Header-->
      <div class="forms card rounded">
        <RenewalSchedulingForm :BaseURL="this.BaseURL" :user="this.user" />
      </div>
    </div>
  </div>
</template>

<script>
import RenewalSchedulingForm from "@/components/RenewalSchedulingForm";
import Breadcrumb from "@/components/Breadcrumb";

export default {
  components: {
    RenewalSchedulingForm,
    Breadcrumb,
  },

  props: ["user", "BaseURL"],

  beforeCreate() {
    if (localStorage.getItem("role") !== "User") {
      window.location = "/error";
    }
  },
};
</script>