<template>
  <!--Main Content-->
  <div class="main-content px-0 app-content">
    <!--Main Content Container-->
    <div class="container-fluid">
      <!--Page Header-->
      <Breadcrumb />
      <!--Page Header-->
      <div class="forms card rounded">
        <div
          v-bind:class="[closed ? 'close' : '']"
          class="mb-4 bg-warning p-3 text-dark"
          v-if="isSuccessful === false"
        >
          <b>Error : {{ errorMessage }}</b>
          <i @click="close()" class="fas fa-close"></i>
        </div>
        <div v-else></div>
        <form @submit.prevent="edit()">
          <label>Name</label>
          <input
            v-model="user.name"
            type="text"
            class="form-control"
            required
          />

          <label>Email</label>
          <input
            v-model="user.email"
            type="text"
            class="form-control"
            required
          />

          <label>UserName</label>
          <input
            v-model="user.userName"
            type="text"
            class="form-control"
            required
          />

          <label>Phone Number</label>
          <input
            v-model="user.phoneNumber"
            type="tel"
            class="form-control"
            required
          />

          <div class="mb-4">
            <p class="mg-b-10">Role</p>
            <select
              v-model="user.role"
              class="form-control select2-no-search"
              required
            >
              <option label="Choose one"></option>
              <option value="Admin">Admin</option>
              <option value="User">User</option>
              <option value="ED">ED</option>
              <option value="GMD">GMD</option>
              <option value="ARM">ARM</option>
              <option value="Unit Head">Unit Head</option>
              <option value="Admin Head">Admin Head</option>
            </select>
          </div>

          <button type="submit" class="btn btn-primary">Submit</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";

export default {
  components: {
    Breadcrumb,
  },
  props: ["BaseURL"],

  data() {
    return {
      user: {},

      isSuccessful: "",
      errorMessage: "",

      closed: false,
    };
  },

  methods: {
    edit() {
      fetch(this.BaseURL + "/updateUser", {
        method: "POST",
        body: JSON.stringify({
          name: this.user.name,
          email: this.user.email,
          userName: this.user.userName,
          phoneNumber: this.user.phoneNumber,
          submittedBy: this.user.email,
          role: this.user.role,
          id: this.user.userId,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((data) => {
          this.isSuccessful = data.isSuccessful;
          this.errorMessage = data.message;

          if (this.isSuccessful == true) {
            alert("User Edited successfully");
            window.location = "/users";
          }
        });
    },

    close() {
      this.closed = !this.closed;
    },
  },

  mounted() {
    fetch(this.BaseURL + "/getUserById" + this.$route.params.id, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        //console.log(data)
        this.user = data;
      });
  },
};
</script>