<template>
  <!-- main-header -->
  <div class="main-header">
    <div class="container-fluid">
      <div class="main-header-left">
        <!--logo-->
        <div>
          <a class="main-logo desktop-logo" href="/"
            ><img src="../../logo.png" alt="logo" />
            <span style="font-size: 22px">CHI Assets</span></a
          >
          <a class="main-logo mobile-logo" href="/"
            ><img src="../../logo.png" alt="logo"
          /></a>
          <a class="main-logo dark-theme-logo" href="/"
            ><img src="../../logo.png" alt="logo"
          /></a>
        </div>
        <!--/logo-->
        <!-- sidebar-toggle-->
        <div class="app-sidebar__toggle" data-toggle="sidebar">
          <a class="open-toggle" href="#"
            ><i class="header-icons" data-eva="menu-outline"></i
          ></a>
          <a class="close-toggle" href="#"
            ><i class="header-icons" data-eva="close-outline"></i
          ></a>
        </div>
        <!-- /sidebar-toggle-->
        <div class="header-formsearch">
          <input class="form-control" placeholder="Search" type="search" />
          <button class="btn"><i class="fe fe-search"></i></button>
        </div>
      </div>
      <div class="main-header-right ml-auto">
        <div class="dropdown main-header-search mobile-search">
          <a class="new header-link" href="">
            <i class="header-icons" data-eva="search-outline"></i>
          </a>
          <div class="dropdown-menu">
            <div class="p-2 main-form-search">
              <input
                class="form-control"
                placeholder="Search here..."
                type="search"
              />
              <button class="btn"><i class="fe fe-search"></i></button>
            </div>
          </div>
        </div>
        <div class="main-header-fullscreen fullscreen-button">
          <a href="#" class="header-link">
            <i class="header-icons" data-eva="expand-outline"></i>
          </a>
        </div>
        <div class="dropdown main-header-message">
          <a class="new header-link" href="">
            <i class="header-icons" data-eva="bell-outline"></i>

            <span class="badge bg-danger nav-link-badge">{{
              notificationCount
            }}</span>
          </a>
          <div style="background-color: #fcfbf9" class="dropdown-menu">
            <div
              style="background-color: #fff"
              class="p-3 border-bottom text-center"
            >
              <h6 class="main-notification-title">Notifications</h6>
            </div>
            <div class="main-notification-list">
              <div class="d-block" v-if="notificationCount == 0">
                <img
                  style="width: 200px"
                  class="d-block mx-auto"
                  src="../assets/notis.gif"
                />

                <p class="bold text-center mt-3">
                  When you have notifications, They will appear here
                </p>
              </div>

              <div
                v-else
                v-for="notification in allRenewalNotification"
                :key="notification.id"
                class="media new"
              >
                <div class="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-bell"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z"
                    />
                  </svg>
                </div>
                <router-link
                  :to="{
                    name: 'Edit Renewal',
                    params: { id: notification.id },
                  }"
                >
                  <div class="media-body">
                    <p>
                      <strong>{{ notification.assetName }}</strong> Is due for
                      renewal by
                    </p>
                    <span>{{ notification.expirydate.split("T")[0] }}</span>
                  </div>
                </router-link>
              </div>

              <div
                v-for="notification in allMaintenanceNotification"
                :key="notification.id"
                class="media new"
              >
                <div class="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-bell"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z"
                    />
                  </svg>
                </div>
                <router-link
                  :to="{
                    name: 'Edit Maintenance',
                    params: { id: notification.id },
                  }"
                >
                  <div class="media-body">
                    <p>
                      <strong>{{ notification.assetName }}</strong> Is due for
                      {{ notification.name }}
                      maintenance by
                    </p>
                    <span>{{
                      notification.maintenanceDueDate.split("T")[0]
                    }}</span>
                  </div>
                </router-link>
              </div>

              <div
                v-for="notification in allTaskNotification"
                :key="notification.id"
                class="media new"
              >
                <div class="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-bell"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z"
                    />
                  </svg>
                </div>
                <router-link
                  :to="{
                    name: 'Edit Task',
                    params: { id: notification.id },
                  }"
                >
                  <div class="media-body">
                    <p>
                      Your Task <strong>{{ notification.name }}</strong> on
                      <strong>{{ notification.assetName }}</strong> Is due by
                    </p>
                    <span>{{ notification.dueDate.split("T")[0] }}</span>
                  </div>
                </router-link>
              </div>

              <div
                v-for="notification in allDeliveryNotification"
                :key="notification.id"
                class="media new"
              >
                <div class="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-bell"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z"
                    />
                  </svg>
                </div>
                <router-link
                  :to="{
                    name: 'Edit Delivery',
                    params: { id: notification.id },
                  }"
                >
                  <div class="media-body">
                    <p>
                      Delivery on Item :
                      <strong>{{ notification.item }}</strong> for
                      <strong>{{ notification.receiverName }}</strong> Is due by
                    </p>
                    <span>{{ notification.date.split("T")[0] }}</span>
                  </div>
                </router-link>
              </div>

              <!--div
                v-for="notification in allApprovalNotification"
                :key="notification.id"
                class="media new"
              >
                <div class="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-bell"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z"
                    />
                  </svg>
                </div>
                <router-link
                  :to="{
                    name: 'Approvals',
                    params: { id: notification.id },
                  }"
                >
                  <div class="media-body">
                    <p>
                      <strong>{{ notification.status }}</strong>
                      <strong>{{ notification.type }}</strong> on
                      <strong>{{ notification.assetName }}</strong>
                    </p>
                    <span>{{ notification.date.split("T")[0] }}</span>
                  </div>
                </router-link>
              </div-->
            </div>
            <div class="dropdown-footer">
              <!--a href="">View All Notifications</a-->
            </div>
          </div>
        </div>
        <!-- <div class="dropdown main-header-notification d-none d-md-flex">
						<a class="new header-link" href="">
							<i class="header-icons" data-eva="bell-outline"></i>
							<span class="pulse bg-danger"></span>
						</a>
						<div class="dropdown-menu">
							<div class="p-3 border-bottom text-center">
								<h6 class="main-notification-title">Notifications</h6>
							</div>
							<div class="main-notification-list">
								<a href="#" class="dropdown-item d-flex">
									<div class="text-primary tx-18 mr-3 ">
										<i class="fe fe-mail"></i>
									</div>
									<div>
										<h6 class="mb-1">Commented on your post.</h6>
										<div class="small text-muted">3 hours ago</div>
									</div>
								</a>
								<a href="#" class="dropdown-item d-flex border-top">
									<div class="text-pink tx-18 mr-3 ">
										<i class="fe fe-user"></i>
									</div>
									<div>
										<h6 class="mb-1">New User Registered</h6>
										<div class="small text-muted">1 day ago</div>
									</div>
								</a>
								<a href="#" class="dropdown-item d-flex  border-top">
									<div class="text-success tx-18 mr-3 ">
										<i class="fe fe-thumbs-up"></i>
									</div>
									<div>
										<h6 class="mb-1">Someone likes our posts</h6>
										<div class="small text-muted">5 mins ago</div>
									</div>
								</a>
								<a href="#" class="dropdown-item d-flex border-top">
									<div class="text-purple tx-18 mr-3 ">
										<i class="fe fe-upload"></i>
									</div>
									<div>
										<h6 class="mb-1">New file has been uploaded</h6>
										<div class="small text-muted">50 sec ago</div>
									</div>
								</a>
								<a href="#" class="dropdown-item d-flex border-top">
									<div class="text-danger tx-18 mr-3 ">
										<i class="fe fe-alert-circle"></i>
									</div>
									<div>
										<h6 class="mb-1">System alert</h6>
										<div class="small text-muted">2 days ago</div>
									</div>
								</a>
								<a href="#" class="dropdown-item d-flex border-top">
									<div class="text-warning tx-18 mr-3 ">
										<i class="fe fe-server"></i>
									</div>
									<div>
										<h6 class="mb-1">Server Rebooted</h6>
										<div class="small text-muted">45 mins ago</div>
									</div>
								</a>
							</div>
							<div class="dropdown-footer">
								<a href="">View All Notifications</a>
							</div>
						</div>
					</div> -->
        <div class="dropdown main-profile-menu">
          <a class="main-img-user" href="">
            <img alt="user-image" src="../assets/user.png" />
          </a>
          <div class="dropdown-menu">
            <div class="main-header-profile">
              <a class="main-img-user" href="">
                <img alt="user-image" src="../assets/user.png" />
              </a>
              <p>
                <b>{{ user.name }}</b>
              </p>
              <span>{{ user.role }}</span>
            </div>
            <!-- <a class="dropdown-item" href="#"
              ><i class="si si-user"></i> Profile</a
            >
            <a class="dropdown-item" href="#"
              ><i class="si si-envelope-open"></i> Inbox</a
            >
            <a class="dropdown-item" href="#"
              ><i class="si si-calendar"></i> Activity</a
            >
            <a class="dropdown-item" href="#"
              ><i class="si si-bubbles"></i> Chat</a
            >
            <a class="dropdown-item" href="#"
              ><i class="si si-settings"></i> Settings</a
            > -->
            <router-link class="dropdown-item" @click.prevent="logout" to="#"
              ><i class="si si-power"></i> Log Out
            </router-link>
          </div>
        </div>
        <div class="main-header-sidebar-notification">
          <a
            href="#"
            class="header-link"
            data-toggle="sidebar-right"
            data-target=".sidebar-right"
          >
            <i class="header-icons" data-eva="options-2-outline"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
  <!--/main-header-->
</template>

<script>
export default {
  props: ["user", "BaseURL"],

  data() {
    return {
      allRenewalNotification: [],
      allMaintenanceNotification: [],
      allTaskNotification: [],
      allDeliveryNotification: [],
    };
  },

  computed: {
    notificationCount() {
      return (
        this.allMaintenanceNotification.length +
        this.allTaskNotification.length +
        this.allDeliveryNotification.length +
        this.allRenewalNotification.length
      );
    },
  },

  methods: {
    logout() {
      localStorage.clear();
      window.location = "/";
    },

    // getDays(notification){
    // 	var today = new Date();
    // 	var dd = today.getDate().padStart(2, '0');
    // 	var mm = (today.getMonth() + 1).padStart(2, '0'); //January is 0!
    // 	var yyyy = today.getFullYear();

    // 	//today = mm + '/' + dd + '/' + yyyy;
    // 	today = yyyy + '-' + mm + '-' + dd ;
    // 	console.log(Math.abs(notification.expirydate.split('T')[0] - today) / (1000 * 3600 * 24) );

    // 	//return  notification.expirydate - today
    // }
  },

  beforeCreate() {
    fetch(this.BaseURL + "/getAllMaintenanceNotification", {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        //console.log(data);
        this.allMaintenanceNotification = data;
      })
      .then(
        fetch(this.BaseURL + "/getAllRenewalNotification", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
          .then((res) => res.json())
          .then((data) => {
            //console.log(data);
            this.allRenewalNotification = data;
          })
      )
      .then(
        fetch(this.BaseURL + "/getAllTaskNotification", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
          .then((res) => res.json())
          .then((data) => {
            //console.log(data);
            this.allTaskNotification = data;
          })
      )
      .then(
        fetch(this.BaseURL + "/getDeliveryNotification", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
          .then((res) => res.json())
          .then((data) => {
            //console.log(data);
            this.allDeliveryNotification = data;
          })
      );
    // .then(
    //   fetch(this.BaseURL + "/getAllAssetReallocationDisposalNotification", {
    //     headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    //   })
    //     .then((res) => res.json())
    //     .then((data) => {
    //       //console.log(data);
    //       this.allApprovalNotification = data;
    //     })
    // );
  },
};
</script>