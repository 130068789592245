<template>
  <div class="frame">
    <div class="card show">
      <div class="card-header">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="26"
          height="26"
          fill="currentColor"
          class="bi bi-x-circle"
          viewBox="0 0 26 26"
        >
          <path
            d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
          />
          <path
            d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
          />
        </svg>
      </div>
      <div class="card-body">
        <h2>Error</h2>
        <p class="p-2">{{ message }}</p>
      </div>
      <div @click="close" class="btn btn-primary">Dismiss</div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  props: ["message", "status"],
  methods: {
    close() {
      $(".card").addClass("away");
      location.reload();
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Pattaya|Quicksand:400,500,700");
.frame {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 400px;
  height: 400px;
  margin-top: -200px;
  margin-left: -200px;
  border-radius: 2px;
  box-shadow: 4px 8px 16px 0 rgba(0, 0, 0, 0);
  overflow: hidden;
  background: #201c2971;
  color: rgba(0, 0, 0, 0.8);
  font-family: "Quicksand", Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  border: none !important;
  width: 80%;
  box-shadow: 0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.4);
  justify-content: flex-start;
  align-items: center;
  -webkit-animation: show 0.5s ease-in-out;
  animation: show 0.5s ease-in-out;
}
.card .card-header {
  color: red;
  font-size: 4rem;
  margin: 0;
  border: none;
  padding: 1rem 0 0;
}
.card .card-body {
  font-size: 1.2rem;
  text-align: center;
  padding: 0;
}

.card .btn {
  color: white;
  font-size: 1.2rem;
  height: 3.3rem;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 3px 3px;
}
.card .btn:hover {
  transition: 0.2s;
  background: linear-gradient(297deg, #ff0000, #ae2020);
  background-size: 200% 200%;
  -webkit-animation: bg 2s ease infinite;
  animation: bg 2s ease infinite;
  letter-spacing: 3px;
}
@-webkit-keyframes bg {
  0% {
    background-position: 18% 0%;
  }
  50% {
    background-position: 83% 100%;
  }
  100% {
    background-position: 18% 0%;
  }
}
@keyframes bg {
  0% {
    background-position: 18% 0%;
  }
  50% {
    background-position: 83% 100%;
  }
  100% {
    background-position: 18% 0%;
  }
}

/* Makes the modal appear with bouncing effect */
@-webkit-keyframes show {
  0% {
    transform: scale(0);
  }
  60% {
    transform: scale(1.1);
  }
  80% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes show {
  0% {
    transform: scale(0);
  }
  60% {
    transform: scale(1.1);
  }
  80% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}
.away {
  -webkit-animation: away 0.5s ease-in-out;
  animation: away 0.5s ease-in-out;
  transform: scale(0);
}

/* Makes the modal disappear after button is clicked, with slight growing effect before disappearing */
@-webkit-keyframes away {
  0% {
    transform: scale(1);
  }
  10% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes away {
  0% {
    transform: scale(1);
  }
  10% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(0);
  }
}
</style>