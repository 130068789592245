<template>
  <!--Main Content-->
  <div class="main-content px-0 app-content">
    <!--Main Content Container-->
    <div class="container-fluid">
      <!--Page Header-->
      <Breadcrumb />
      <!--Page Header-->
      <div class="forms card rounded">
        <TaskSchedulingForm :BaseURL="BaseURL" :user="this.user" />
      </div>
    </div>
  </div>
</template>

<script>
import TaskSchedulingForm from "@/components/TaskSchedulingForm";
import Breadcrumb from "@/components/Breadcrumb";

export default {
  components: {
    TaskSchedulingForm,
    Breadcrumb,
  },

  props: ["user", "BaseURL"],

  beforeCreate() {
    if (localStorage.getItem("role") !== "User") {
      window.location = "/error";
    }
  },
};
</script>