<template>
  <!--Main Content-->
  <div class="main-content px-0 app-content">
    <!--Main Content Container-->
    <div class="container-fluid">
      <!--Page Header-->
      <Breadcrumb />
      <!--Page Header-->
      <div class="forms card rounded">
        <div
          v-bind:class="[closed ? 'close' : '']"
          class="mb-4 bg-warning p-3 text-dark"
          v-if="isSuccessful === false"
        >
          <b>Error : {{ errorMessage }}</b>
          <i @click="close()" class="fas fa-close"></i>
        </div>
        <div v-else></div>
        <form @submit.prevent="edit()">
          <label>Renewal Type</label>
          <input
            v-model="renewaltype.name"
            type="text"
            class="form-control"
            required
          />

          <button type="submit" class="btn btn-primary">Submit</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";

export default {
  components: {
    Breadcrumb,
  },

  props: ["user", "BaseURL"],

  data() {
    return {
      renewaltype: {},

      isSuccessful: "",
      errorMessage: "",

      closed: false,
    };
  },

  beforeCreate() {
    if (localStorage.getItem("role") !== "Admin") {
      window.location = "/error";
    }
  },

  methods: {
    edit() {
      fetch(this.BaseURL + "/updateRenewalType", {
        method: "POST",
        body: JSON.stringify({
          name: this.renewaltype.name,
          submittedBy: this.user.email,
          id: this.renewaltype.id,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          this.isSuccessful = data.isSuccessful;
          this.errorMessage = data.message;

          if (this.isSuccessful == true) {
            alert("Renewal Type Edited successfully");
            window.location = "/renewaltypes";
          }
        });
    },

    close() {
      this.closed = !this.closed;
    },
  },

  mounted() {
    fetch(this.BaseURL + "/getRenewalTypeById" + this.$route.params.id, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        //console.log(data)
        this.renewaltype = data;
      });
  },
};
</script>