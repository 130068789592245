<template>
  <!--Main Content-->
  <div class="main-content px-0 app-content">
    <!--Main Content Container-->
    <div class="container-fluid">
      <!--Page Header-->
      <Breadcrumb />
      <!--Page Header-->
      <div class="forms card rounded">
        <div
          v-bind:class="[closed ? 'close' : '']"
          class="mb-4 bg-warning p-3 text-dark"
          v-if="isSuccessful === false"
        >
          <b>Error : {{ errorMessage }}</b>
          <i @click="close()" class="fas fa-close"></i>
        </div>
        <div v-else></div>
        <form @submit.prevent="approve()">
          <label>Asset Id</label>
          <input
            :value="approval.assetId"
            type="text"
            class="form-control"
            disabled
          />

          <label>Asset Name</label>
          <input
            :value="approval.assetName"
            type="text"
            class="form-control"
            disabled
          />

          <label>Class Of Asset</label>
          <input
            v-model="approval.classofAsset"
            type="text"
            class="form-control"
            disabled
          />

          <label>Date</label>
          <input
            :value="approval.date.split('T')[0]"
            type="text"
            class="form-control"
            disabled
          />

          <label>Sales Value</label>
          <input
            v-model="approval.salesvalue"
            type="number"
            class="form-control"
            disabled
          />

          <label>Destination</label>
          <input
            v-model="approval.destination"
            type="text"
            class="form-control"
            disabled
          />

          <label>Type</label>
          <input
            v-model="approval.type"
            type="text"
            class="form-control"
            disabled
          />

          <label>Accumulated Depreciation</label>
          <input
            v-model="approval.accumulatedDepreciation"
            type="number"
            class="form-control"
            disabled
          />

          <label>Reciept Number</label>
          <input
            v-model="approval.receiptNumber"
            type="number"
            class="form-control"
            disabled
          />

          <label>User Approval Submitted By</label>
          <input
            :value="approval.userApproval"
            type="text"
            class="form-control"
            disabled
          />

          <label>User Comment</label>
          <textarea
            v-model="approval.userComment"
            class="form-control mb-3"
            rows="3"
            disabled
          ></textarea>

          <label>ED Approval Submitted By</label>
          <input
            :value="approval.edApproval"
            type="text"
            class="form-control"
            disabled
          />

          <label>ED Comment</label>
          <textarea
            v-model="approval.edComment"
            class="form-control mb-3"
            rows="3"
            disabled
          ></textarea>

          <label>GMD Comment</label>
          <textarea
            v-model="gmdComment"
            class="form-control mb-3"
            rows="3"
            required
          ></textarea>

          <button type="submit" class="btn btn-primary">
            Approve {{ approval.type }}
          </button>

          <button
            v-if="approval.status != 'GMD Disapproved'"
            type="button"
            @click.prevent="disapprove()"
            class="btn btn-primary ml-5"
          >
            Disapprove {{ approval.type }}
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";

export default {
  components: {
    Breadcrumb,
  },
  props: ["user", "BaseURL"],
  data() {
    return {
      approval: {},

      gmdComment: "",

      isSuccessful: "",
      errorMessage: "",

      closed: "",
    };
  },

  methods: {
    approve() {
      fetch(this.BaseURL + "/GMApproval", {
        method: "POST",
        body: JSON.stringify({
          id: this.approval.id,
          submittedBy: this.user.email,
          gmdComment: this.gmdComment,
          status: true,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          this.isSuccessful = data.isSuccessful;
          this.errorMessage = data.message;

          if (this.isSuccessful == true) {
            alert(
              this.approval.assetName + " Approved For " + this.approval.type
            );
            window.location = "/approvals";
          }
        });
    },

    disapprove() {
      fetch(this.BaseURL + "/GMApproval", {
        method: "POST",
        body: JSON.stringify({
          id: this.approval.id,
          submittedBy: this.user.email,
          gmdComment: this.gmdComment,
          status: false,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          this.isSuccessful = data.isSuccessful;
          this.errorMessage = data.message;

          if (this.isSuccessful == true) {
            alert(
              this.approval.assetName + " Disapproved For " + this.approval.type
            );
            window.location = "/approvals";
          }
        });
    },

    close() {
      this.closed = true;
    },
  },

  beforeCreate() {
    fetch(
      this.BaseURL +
        "/getByIdAssetReallocationDisposal" +
        this.$route.params.id,
      {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        //console.log(data)
        this.approval = data;
      });
  },
};
</script>