<template>
  <!--Main Content-->
  <div class="main-content px-0 app-content">
    <!--Main Content Container-->
    <div class="container-fluid">
      <!--Page Header-->
      <Breadcrumb />
      <!--Page Header-->

      <!--Row-->
      <div class="row row-sm">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-margin">
          <div class="card">
            <div class="card-body pb-0 border-bottom">
              <div class="d-flex mb-3">
                <h6 class="card-title mb-0">All Retirement Approval</h6>
                <div class="card-option ml-auto d-none d-md-flex">
                  <div class="mr-2">
                    <router-link to="/createretirement"
                      ><button class="btn btn-primary btn-sm rounded-lg fs-13">
                        <i class="mdi mdi-plus-circle-outline"></i>Setup New
                        Asset Retirement
                      </button></router-link
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="">
              <div class="table-responsive">
                <table
                  id="example"
                  class="table table-striped"
                  style="width: 100%"
                >
                  <thead>
                    <tr>
                      <th>Retirement Id</th>
                      <th>Asset Name</th>
                      <th>Payee</th>

                      <th>Retirement Date</th>
                      <th>Purpose</th>
                      <th>Status</th>
                      <th v-if="user.role == 'User'">Status Message</th>
                      <!-- <th>Level</th> -->

                      <th v-if="user.role == 'User'">View</th>
                      <th v-else>Approve ({{ user.role }})</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="retirement in Retirements" :key="retirement.id">
                      <td>{{ retirement.id }}</td>
                      <td>{{ retirement.assetName }}</td>
                      <td>{{ retirement.payee }}</td>

                      <td>{{ retirement.date.split("T")[0] }}</td>
                      <td>
                        {{ retirement.purpose }}
                      </td>
                      <td>{{ retirement.status }}</td>
                      <td
                        :class="'text-' + statusMessage(retirement).color"
                        v-if="user.role == 'User'"
                      >
                        {{ statusMessage(retirement).message }}
                      </td>
                      <!-- <td>
                        {{ retirement.approvalLevel }}
                      </td> -->

                      <td v-if="user.role == 'ED'">
                        <router-link
                          v-if="retirement.approvalLevel < 1"
                          :to="{
                            name: 'ED Retirement Approval',
                            params: { id: retirement.id },
                          }"
                          ><button class="btn btn-primary">
                            View
                          </button></router-link
                        >

                        <button v-else class="disabled btn btn-success">
                          Approved
                        </button>
                      </td>
                      <td v-if="user.role == 'GMD'">
                        <router-link
                          v-if="retirement.approvalLevel < 2"
                          :to="{
                            name: 'GMD Retirement Approval',
                            params: { id: retirement.id },
                          }"
                          ><button class="btn btn-warning">
                            View
                          </button></router-link
                        >
                        <button v-else class="disabled btn btn-success">
                          Approved
                        </button>
                      </td>
                      <td v-if="user.role == 'ARM'">
                        <router-link
                          v-if="retirement.approvalLevel < 3"
                          :to="{
                            name: 'ARM Retirement Approval',
                            params: { id: retirement.id },
                          }"
                          ><button class="btn btn-primary">
                            View
                          </button></router-link
                        >
                        <button v-else class="disabled btn btn-success">
                          Approved
                        </button>
                      </td>

                      <td v-if="user.role == 'User'">
                        <router-link
                          :to="{
                            name: 'View Retirement Approval',
                            params: { id: retirement.id },
                          }"
                        >
                          <button class="btn btn-primary">View Details</button>
                        </router-link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- COL END -->
      </div>
    </div>
    <!--Main Content Container-->
  </div>
  <!--Main Content-->
</template>

<script>
import $ from "jquery";
import Breadcrumb from "@/components/Breadcrumb";

export default {
  components: {
    Breadcrumb,
  },
  props: ["user", "BaseURL"],
  data() {
    return {
      Retirements: [],
      sc: "danger",
    };
  },

  methods: {
    statusMessage(retirement) {
      var status = { message: "Pending", color: "danger" };
      //var sc = "danger";

      if (retirement.approvalLevel < 1) {
        status.message = "Waiting On ED Approval";
      } else if (retirement.approvalLevel < 2) {
        status.message = "Waiting On GMD Approval ";
      } else if (retirement.approvalLevel < 3) {
        status.message = "Waiting On ARM Approval ";
      } else if (retirement.approvalLevel >= 3) {
        status.message = "All Approvals Complete";
        status.color = "success";
      }

      return status;
    },
  },

  beforeCreate() {
    if (
      localStorage.getItem("role") !== "User" &&
      localStorage.getItem("role") !== "ED" &&
      localStorage.getItem("role") !== "GMD" &&
      localStorage.getItem("role") !== "ARM"
    ) {
      window.location = "/error";
    }
  },

  mounted() {
    fetch(this.BaseURL + "/getAllRetirement", {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        this.Retirements = data;
        $(() => {
          $("#example").DataTable();
        });
      });
  },

  computed: {},
};
</script>
