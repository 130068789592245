<template>
  <!--Main Content-->
  <div class="main-content px-0 app-content">
    <!--Main Content Container-->
    <div class="container-fluid">
      <!--Page Header-->
      <Breadcrumb />
      <!--Page Header-->
      <div class="forms card rounded">
        <div
          v-bind:class="[closed ? 'close' : '']"
          class="mb-4 bg-warning p-3 text-dark"
          v-if="isSuccessful === false"
        >
          <b>Error : {{ errorMessage }}</b>
          <i @click="close()" class="fas fa-close"></i>
        </div>
        <div v-else></div>
        <form @submit.prevent="createCompany()">
          <label>Company</label>
          <input v-model="name" type="text" class="form-control" required />

          <button type="submit" class="btn btn-primary">Create Company</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";

export default {
  components: {
    Breadcrumb,
  },

  props: ["user", "BaseURL"],
  data() {
    return {
      name: "",
      email: "",

      isSuccessful: "",
      errorMessage: "",

      closed: "",
    };
  },

  beforeCreate() {
    if (localStorage.getItem("role") !== "Admin") {
      window.location = "/error";
    }
  },

  methods: {
    createCompany() {
      fetch(this.BaseURL + "/addCompany", {
        method: "POST",
        body: JSON.stringify({
          name: this.name,
          submittedBy: this.user.email,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((data) => {
          this.isSuccessful = data.isSuccessful;
          this.errorMessage = data.message;

          if (this.isSuccessful == true) {
            window.location = "/company";
          }
        });
    },

    close() {
      this.closed = true;
    },
  },
};
</script>