<template>
  <!--Main Content-->
  <div class="main-content px-0 app-content">
    <!--<Error v-if="role !== 'Admin'" />
     <div v-if="role !== 'Admin'" class="container-fluid text-center">
      <h1 class="pt-5">ERROR 404</h1>
      <p class="font-weight-bolder fs-1">
        You Are Not Allowed Access To This Page
      </p>
      <img
        style="width: 40%"
        class="mt-5"
        src="https://assets.materialup.com/uploads/26541cce-49c6-4e35-a055-e11b90ffad68/preview.gif"
      />
    </div> -->

    <!--Main Content Container-->
    <div class="container-fluid">
      <!--Page Header-->
      <Breadcrumb />
      <!--Page Header-->

      <!--Row-->
      <div class="row row-sm">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-margin">
          <div class="card">
            <div class="card-body pb-0 border-bottom">
              <div class="d-flex mb-3">
                <h6 class="card-title mb-0">All created users</h6>
                <div class="card-option ml-auto d-none d-md-flex">
                  <a href="#" class="text-dark"
                    ><i class="fe fe-more-horizontal"></i
                  ></a>
                </div>
              </div>
            </div>

            <!-- Loader -->
            <center v-if="users === []">
              <div
                style="margin-top: 45vh"
                class="spinner-grow text-primary text-center center"
                role="status"
              >
                <span class="sr-only">Loading...</span>
              </div>
            </center>

            <div v-else class="">
              <div class="d-flex table-responsive p-3">
                <div class="mr-2">
                  <router-link to="/createUser"
                    ><button class="btn btn-primary btn-sm rounded-lg fs-13">
                      <i class="mdi mdi-plus-circle-outline"></i>Create New User
                    </button></router-link
                  >
                </div>

                <div class="ml-auto mr-2 mt-1 border-0 d-none d-md-block">
                  <input
                    type="text"
                    v-model="term"
                    @keyup="searchLessons"
                    class="form-control"
                    placeholder="Search Here"
                  />
                </div>
              </div>
              <div class="table-responsive">
                <table
                  id="example"
                  class="table table-striped"
                  style="width: 100%"
                >
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Username</th>
                      <th>Phone Number</th>
                      <th>Status</th>
                      <th>role</th>
                      <th>Activate / Deactivate</th>

                      <th>Edit</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="user in users" :key="user.email">
                      <td>{{ user.name }}</td>
                      <td>{{ user.email }}</td>
                      <td>{{ user.userName }}</td>
                      <td>{{ user.phoneNumber }}</td>
                      <td>{{ user.status }}</td>
                      <td>{{ user.role }}</td>
                      <td
                        v-if="
                          user.status == 'Inactive' || user.status == 'Pending'
                        "
                      >
                        <button @click="activate(user)" class="btn btn-success">
                          Activate
                        </button>
                      </td>
                      <td v-if="user.status == 'Active'">
                        <button
                          @click="deactivate(user)"
                          class="btn btn-danger"
                        >
                          Deactivate
                        </button>
                      </td>
                      <td>
                        <router-link
                          :to="{
                            name: 'Edit User',
                            params: { id: user.userId },
                          }"
                          ><button class="btn btn-primary">
                            Edit
                          </button></router-link
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- COL END -->
      </div>
    </div>
    <!--Main Content Container-->
  </div>
  <!--Main Content-->
</template>

<script>
// import $ from 'jquery'
import Breadcrumb from "@/components/Breadcrumb";
//import Error from "@/components/Error";

export default {
  components: {
    Breadcrumb,
    //Error,
  },
  props: ["role", "user", "BaseURL"],
  data() {
    return {
      users: [],
      sUsers: [],
      term: "",
    };
  },

  methods: {
    // Search For Users
    searchLessons: function () {
      this.users = this.sUsers;
      this.users = this.users.filter(
        (user) =>
          (user.name || user.userName).toLowerCase().includes(this.term) == true
      );
    },

    //getUsers
    getUsers() {
      fetch(this.BaseURL + "/getAllUser", {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((res) => res.json())
        .then((data) => {
          //console.log(data)
          this.users = data;
          this.sUsers = data;
        });
    },

    activate(user) {
      fetch(this.BaseURL + "/Activate", {
        method: "POST",
        body: JSON.stringify({
          id: user.userId,
          submittedBy: this.user.email,
          status: "Active",
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((data) => {
          alert(data.message);
          this.getUsers();
        });
    },

    deactivate(user) {
      fetch(this.BaseURL + "/Deactivate", {
        method: "POST",
        body: JSON.stringify({
          id: user.userId,
          submittedBy: this.user.email,
          status: "Inactive",
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((data) => {
          alert(data.message);
          this.getUsers();
        });
    },
  },
  beforeCreate() {
    if (localStorage.getItem("role") !== "Admin") {
      window.location = "/error";
    }
  },
  mounted() {
    // window.location.reload(true);
    this.getUsers();
  },
};
</script>