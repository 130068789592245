<template>
  <!--Main Content-->
  <div class="main-content px-0 app-content">
    <!--Main Content Container-->
    <div class="container-fluid">
      <!--Page Header-->
      <Breadcrumb />
      <!--Page Header-->
      <div class="forms card rounded">
        <form @submit.prevent="uploadDocuments()">
          <label>Vendor Id</label>
          <input
            type="text"
            :value="$route.params.id"
            class="form-control"
            disabled
          />

          <label>Vendor Name</label>
          <input
            type="text"
            :value="vendor.registeredCompanyName"
            class="form-control"
            disabled
          /><br /><br />

          <label>Document Name</label>
          <input
            type="text"
            v-model="documentName"
            class="form-control"
            required
          />

          <p class="mg-b-20">Upload Document</p>
          <div class="">
            <input
              type="file"
              @change="handleFileUpload($event)"
              class="dropify"
              data-height="180"
              required
            />
          </div>

          <button type="submit" class="btn btn-primary">Submit</button
          ><br /><br />
        </form>
      </div>

      <div class="forms card rounded mt-5">
        <h4>
          Already Uploaded Documents ({{ vendor.vendorDocumentDTO.length }})
        </h4>
        <br />

        <ul v-for="document in vendor.vendorDocumentDTO" :key="document.id">
          <li>
            <b>{{ document.name }}</b>
            <a
              class="ml-3"
              :href="'https://chiadminapi.chiplc.com/IMAGE/' + document.path"
              target="_blank"
              >( View Document )</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";

export default {
  components: {
    Breadcrumb,
  },

  props: ["user", "BaseURL"],

  data() {
    return {
      documentName: "",
      document: null,
      isSuccessful: "",
      errorMessage: "",

      closed: false,

      vendor: {},

      signedFormFile: "",
    };
  },

  created() {
    fetch(this.BaseURL + "/getVendorById" + this.$route.params.id, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        //console.log(data)
        this.vendor = data;
      });
  },

  methods: {
    handleFileUpload(e) {
      this.document = e.target.files[0];
    },

    uploadDocuments() {
      const formData = new FormData();
      formData.append("FormFile", this.document);
      formData.append("Name", this.documentName);
      formData.append("Path", "");
      formData.append("SubmittedBy", this.user.email);
      formData.append("VendorId", this.$route.params.id);

      fetch(this.BaseURL + "/UploadVendorDocument", {
        method: "POST",
        body: formData,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          this.isSuccessful = data.isSuccessful;
          this.errorMessage = data.message;

          if (this.isSuccessful == true) {
            location.reload();
          }
        });
    },
  },
};
</script>