<template>
  <!--Main Content-->
  <div class="main-content px-0 app-content">
    <!--Main Content Container-->
    <div class="container-fluid">
      <!--Page Header-->
      <Breadcrumb />
      <!--Page Header-->
      <div class="forms card rounded">
        <div
          v-bind:class="[closed ? 'close' : '']"
          class="mb-4 bg-warning p-3 text-dark"
          v-if="isSuccessful === false"
        >
          <b>Error : {{ errorMessage }}</b>
          <i @click="close()" class="fas fa-close"></i>
        </div>
        <div v-else></div>
        <form @submit.prevent="updateVendor()">
          <label>Vendor ID</label>
          <input
            v-model="vendor.id"
            type="text"
            class="form-control"
            disabled
            required
          />

          <label>Registered Company Name</label>
          <input
            v-model="vendor.registeredCompanyName"
            type="text"
            class="form-control"
            required
          />

          <label>Company Registration Number</label>
          <input
            v-model="vendor.companyRegistrationNumber"
            type="text"
            class="form-control"
            required
          />

          <label>Tax Identification Number</label>
          <input
            v-model="vendor.taxIdentificationNumber"
            type="text"
            class="form-control"
            required
          />

          <label>Address</label>
          <input
            v-model="vendor.address"
            type="text"
            class="form-control"
            required
          />

          <label>Office Phone Number</label>
          <input
            v-model="vendor.telephoneNumber"
            type="number"
            class="form-control"
            required
          />

          <label>Mobile Number</label>
          <input
            v-model="vendor.mobileNumber"
            type="number"
            class="form-control"
            required
          />

          <label>Vendor Email</label>
          <input
            v-model="vendor.email"
            type="text"
            class="form-control"
            required
          />

          <label>Vendor Website</label>
          <input
            v-model="vendor.website"
            type="text"
            class="form-control"
            required
          />

          <label>Vendor Industry</label>
          <input
            v-model="vendor.industry"
            type="text"
            class="form-control"
            required
          />

          <label>Overall Number Of Staff</label>
          <input
            v-model="vendor.numberOfStaff"
            type="number"
            class="form-control"
            required
          />

          <label>Number Of Years in Business</label>
          <input
            v-model="vendor.yearsinBusiness"
            type="number"
            class="form-control"
            required
          />

          <label>Does the company belong to any Group of Companies ?</label>
          <select
            v-model="vendor.groupofCompanies"
            class="form-control mb-3"
            required
          >
            <option selected>Select</option>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>

          <label>Name Of Group of Companies</label>
          <input
            v-model="vendor.nameofGroupofCompanies"
            type="text"
            class="form-control"
            required
          />

          <label>Does the company have several plants / branches ?</label>
          <select v-model="vendor.branches" class="form-control mb-3" required>
            <option selected>Select</option>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>

          <label>Does the company have any subsidiary?</label>
          <select
            v-model="vendor.subsidiary"
            class="form-control mb-3"
            required
          >
            <option selected>Select</option>
            <option :value="true">Yes</option>
            <option :value="false">No</option>
          </select>

          <div v-if="vendor.subsidiary === true">
            <table id="example" class="table table-striped" style="width: 100%">
              <thead>
                <tr>
                  <th>Subsidiary Company name</th>
                  <th>Location</th>
                  <th>Field of Activity</th>
                  <th>Edit</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(subsidiary, index) in vendor.subsidiaryDTO"
                  :key="subsidiary.id"
                >
                  <td>{{ subsidiary.subsidiaryCompanyName }}</td>
                  <td>{{ subsidiary.location }}</td>
                  <td>{{ subsidiary.fieldofActivity }}</td>
                  <td>
                    <button
                      class="btn btn-primary"
                      data-toggle="modal"
                      data-target="#editVendor"
                      @click.prevent="fetchData(index, subsidiary)"
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="jumbotron">
              <label>Subsidiary Company name</label>
              <input
                v-model="subsidiaryCompanyName"
                type="text"
                class="form-control"
              />

              <label>Subsidiary Location</label>
              <input
                v-model="subsidiaryLocation"
                type="text"
                class="form-control"
              />

              <label>Field of Activity</label>
              <textarea
                v-model="subsidiaryFieldOfActivity"
                rows="3"
                class="form-control mb-3"
              ></textarea>

              <button
                type="button"
                @click="addSubsidiary"
                class="btn btn-primary"
              >
                Add
              </button>
            </div>
          </div>
          <button v-if="loading == true" class="btn btn-primary" disabled>
            Loading
            <div class="spinner-border spinner-border-sm" role="status">
              <span style="width: 2px" class="sr-only text-light"
                >Loading...</span
              >
            </div>
          </button>
          <button v-else type="submit" class="btn btn-primary">
            Update Vendor
          </button>
        </form>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div
    class="modal fade"
    id="editVendor"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            Edit Vendor Subsidiary
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <label>Subsidiary Company Name</label>
          <input
            type="text"
            class="form-control"
            v-model="tmpSub.subsidiaryCompanyName"
            required
          />

          <label>Subsidiary Company Location</label>
          <input
            type="text"
            class="form-control"
            v-model="tmpSub.location"
            required
          />

          <label>Subsidiary Company Field Of Activity</label>
          <input
            type="text"
            class="form-control"
            v-model="tmpSub.fieldofActivity"
            required
          />
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Close
          </button>
          <button
            type="button"
            @click="edit(index)"
            data-dismiss="modal"
            class="btn btn-primary"
          >
            Edit
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";

export default {
  components: {
    Breadcrumb,
  },
  props: ["user", "BaseURL"],
  data() {
    return {
      vendor: {},
      tmpSub: {},
      subIndex: null,

      subsidiaryCompanyName: "",
      subsidiaryLocationsubsidiaryLocation: "",
      subsidiaryFieldOfActivity: "",

      isSuccessful: "",
      errorMessage: "",
      loading: false,

      closed: "",
    };
  },

  methods: {
    fetchData(index, subsidiary) {
      this.tmpSub = subsidiary;
      this.subIndex = index;
    },

    edit() {
      this.vendor.subsidiaryDTO[this.subIndex] = this.tmpSub;
      //console.log(this.vendor);
    },

    addSubsidiary() {
      this.vendor.subsidiaryDTO.push({
        id: 0,
        subsidiaryCompanyName: this.subsidiaryCompanyName,
        type: "New",
        location: this.subsidiaryLocation,
        fieldofActivity: this.subsidiaryFieldOfActivity,
        vendorId: this.vendor.id,
        vendorNamee: this.vendor.registeredCompanyName,
      });

      this.subsidiaryCompanyName = null;
      this.subsidiaryLocation = null;
      this.subsidiaryFieldOfActivity = null;

      //console.log(this.vendor);
    },

    updateVendor() {
      this.loading = true;
      fetch(this.BaseURL + "/updateVendor", {
        method: "POST",
        body: JSON.stringify({
          id: this.vendor.id,
          submittedBy: this.user.email,
          registeredCompanyName: this.vendor.registeredCompanyName,
          companyRegistrationNumber: this.vendor.companyRegistrationNumber,
          taxIdentificationNumber: this.vendor.taxIdentificationNumber,
          address: this.vendor.address,
          telephoneNumber: this.vendor.telephoneNumber,
          mobileNumber: this.vendor.mobileNumber,
          email: this.vendor.email,
          subsidary: this.vendor.subsidiary,
          website: this.vendor.website,
          industry: this.vendor.industry,
          numberOfStaff: this.vendor.numberOfStaff,
          yearsinBusiness: this.vendor.yearsinBusiness,
          groupofCompanies: this.vendor.groupofCompanies,
          nameofGroupofCompanies: this.vendor.nameofGroupofCompanies,
          branches: this.vendor.branches,
          status: this.vendor.status,
          subsidiary: this.vendor.subsidiary,
          subsidiaryCompanyName: this.vendor.subsidiaryCompanyName,
          location: this.vendor.location,
          fieldofActivity: this.vendor.fieldofActivity,
          subsidiaryDTO: this.vendor.subsidiaryDTO,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          this.isSuccessful = data.isSuccessful;
          this.errorMessage = data.message;

          if (this.isSuccessful == true) {
            this.loading = false;
            alert("Vendor Edited Successfully");
            window.location = "/vendors";
          } else {
            this.loading = false;
            alert("Error : " + this.errorMessage);
          }
        });
    },

    close() {
      this.closed = true;
    },
  },

  created() {
    fetch(this.BaseURL + "/getVendorById" + this.$route.params.id, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        //console.log(data)

        this.vendor = data;
      });
  },
};
</script>