<template>
<div class="page-header px-0">
					<h3 class="page-title">{{$route.name}}</h3>
					<ol class="breadcrumb mb-0">
						<li class="breadcrumb-item"><a href="#" @click="$router.go(-1)">Go Back</a></li>
						<li class="breadcrumb-item active" aria-current="page">{{$route.name}}</li>
					</ol>
				</div>
</template>

<script>
export default{
    
}
</script>