<template>
  <!--Main Content-->
  <div class="main-content px-0 app-content">
    <!--Main Content Container-->
    <div class="container-fluid">
      <!--Page Header-->
      <Breadcrumb />
      <!--Page Header-->
      <div class="forms card rounded">
        <div
          v-bind:class="[closed ? 'close' : '']"
          class="mb-4 bg-warning p-3 text-dark"
          v-if="isSuccessful === false"
        >
          <b>Error : {{ errorMessage }}</b>
          <i @click="close()" class="fas fa-close"></i>
        </div>
        <div v-else></div>
        <form @submit.prevent="approve()">
          <label>Asset To Retire</label>
          <select
            @change="getAsset()"
            v-model="assetId"
            class="form-control mb-3"
            required
          >
            <option value="" selected>Select Asset</option>
            <option v-for="asset in assets" :key="asset.id" :value="asset.id">
              {{ asset.name }} ({{ asset.code }})
            </option>
          </select>

          <label>Payee</label>
          <input type="text" v-model="payee" class="form-control" required />

          <label>Retirement Date</label>
          <input type="date" v-model="date" class="form-control" required />

          <label>Purpose</label>
          <textarea
            class="form-control"
            v-model="purpose"
            rows="4"
            required
          ></textarea>

          <label>Amount Advanced</label>
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">₦</span>
            </div>

            <input
              type="text"
              v-model="amountAdvanced"
              class="form-control"
              required
            />
            <div class="input-group-append">
              <span class="input-group-text">.00</span>
            </div>
          </div>

          <label>Amount Spent</label>
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">₦</span>
            </div>

            <input
              type="text"
              v-model="amountSpent"
              class="form-control"
              required
            />
            <div class="input-group-append">
              <span class="input-group-text">.00</span>
            </div>
          </div>

          <label>Serial Number</label>
          <input v-model="serialNumber" type="number" class="form-control" />

          <label>Account Code</label>
          <input
            v-model="accountCode"
            type="text"
            class="form-control"
            required
          />

          <label>DR.</label>
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">₦</span>
            </div>

            <input type="text" v-model="debit" class="form-control" required />
            <div class="input-group-append">
              <span class="input-group-text">.00</span>
            </div>
          </div>

          <label>CR.</label>
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">₦</span>
            </div>

            <input type="text" v-model="credit" class="form-control" required />
            <div class="input-group-append">
              <span class="input-group-text">.00</span>
            </div>
          </div>

          <label>Refund to Coy</label>
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">₦</span>
            </div>

            <input
              type="text"
              v-model="refundtoCoy"
              class="form-control"
              required
            />
            <div class="input-group-append">
              <span class="input-group-text">.00</span>
            </div>
          </div>

          <label>Refund to Payee </label>
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">₦</span>
            </div>

            <input
              type="text"
              v-model="refundtoPayee"
              class="form-control"
              required
            />
            <div class="input-group-append">
              <span class="input-group-text">.00</span>
            </div>
          </div>

          <label>Submitted By</label>
          <input
            :value="user.email"
            type="text"
            class="form-control"
            disabled
          />
          <button v-if="loading == true" class="btn btn-primary" disabled>
            Loading
            <div class="spinner-border spinner-border-sm" role="status">
              <span style="width: 2px" class="sr-only text-light"
                >Loading...</span
              >
            </div>
          </button>
          <button type="submit" class="btn btn-primary">
            Approve {{ type }}
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";

export default {
  components: {
    Breadcrumb,
  },
  props: ["user", "BaseURL"],
  data() {
    return {
      loading: false,
      assets: [],
      asset: {},
      assetId: "",

      payee: "",
      date: "",
      status: "",
      jvNo: "",
      purpose: "",
      amountAdvanced: 0,
      amountSpent: 0,
      serialNumber: 0,
      accountCode: "",
      details: "",
      debit: 0,
      credit: 0,
      refundtoCoy: 0,
      refundtoPayee: 0,

      isSuccessful: "",
      errorMessage: "",

      closed: "",
    };
  },

  methods: {
    // format1() {
    //   this.salesValue = new Intl.NumberFormat().format(
    //     parseInt(this.salesValue.replace(",", ""))
    //   );
    // },

    // format2() {
    //   this.accumulatedDepreciation = new Intl.NumberFormat().format(
    //     parseInt(this.accumulatedDepreciation.replace(",", ""))
    //   );
    // },

    getAsset() {
      fetch(this.BaseURL + "/getAssetById" + this.assetId, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((res) => res.json())
        .then((data) => {
          //console.log(data)
          this.asset = data;
        });
    },

    approve() {
      this.loading = true;
      fetch(this.BaseURL + "/addretirement", {
        method: "POST",
        body: JSON.stringify({
          assetId: this.assetId,
          assetName: this.asset.name,
          payee: this.payee,
          date: this.date,
          status: this.status,
          jvNo: this.jvNo,
          purpose: this.purpose,
          amountAdvanced: parseInt(this.amountAdvanced),
          amountSpent: parseInt(this.amountSpent),
          serialNumber: this.serialNumber,
          accountCode: this.accountCode,
          details: this.details,
          debit: parseInt(this.debit),
          credit: parseInt(this.credit),
          refundtoCoy: parseInt(this.refundtoCoy),
          refundtoPayee: parseInt(this.refundtoPayee),
          submittedBy: this.user.email,
          //userComment: this.userComment,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          this.isSuccessful = data.isSuccessful;
          this.errorMessage = data.message;

          if (this.isSuccessful == true) {
            alert(this.asset.name + " Approved For Retirement ");
            this.loading = false;
            window.location = "/retirements";
          } else {
            this.loading = false;
            alert(`Error : ${this.errorMessage}`);
          }
        });
    },

    close() {
      this.closed = true;
    },
  },

  beforeCreate() {
    if (localStorage.getItem("role") !== "User") {
      window.location = "/error";
    }
  },

  created() {
    fetch(this.BaseURL + "/getAllAsset", {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        //console.log(data)
        this.assets = data;
      });
  },
};
</script>