<template>
  <!--Main Content-->
  <div class="main-content px-0 app-content">
    <!--Main Content Container-->
    <div class="container-fluid">
      <!--Page Header-->
      <Breadcrumb />
      <!--Page Header-->

      <!--Row-->
      <div class="row row-sm">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-margin">
          <div class="card">
            <div class="card-body pb-0 border-bottom">
              <div class="d-flex mb-3">
                <h6 class="card-title mb-0">All Active Maintenance</h6>
                <div class="card-option ml-auto d-none d-md-flex">
                  <div class="mr-2">
                    <!-- <router-link to="/createmaintenance"><button class="btn btn-primary btn-sm rounded-lg fs-13"><i class="mdi mdi-plus-circle-outline"></i>Create New Maintenance </button></router-link> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="">
              <div class="table-responsive">
                <table
                  id="example"
                  class="table table-striped"
                  style="width: 100%"
                >
                  <thead>
                    <tr>
                      <th>Asset ID</th>
                      <th>Asset Name</th>
                      <th>Maintenance Name</th>

                      <th>Assigned Staff</th>
                      <th>Date Created</th>
                      <th>Maintenance Due Date</th>
                      <th>View More</th>
                      <th>Cancel</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="maintenance in allMaintenance"
                      :key="maintenance.id"
                    >
                      <td>{{ maintenance.assetId }}</td>
                      <td>{{ maintenance.assetName }}</td>
                      <td>{{ maintenance.name }}</td>

                      <td>{{ maintenance.staffAssignTo }}</td>
                      <td>{{ maintenance.date.split("T")[0] }}</td>
                      <td>
                        {{ maintenance.maintenanceDueDate.split("T")[0] }}
                      </td>

                      <td>
                        <router-link
                          :to="{
                            name: 'Edit Maintenance',
                            params: { id: maintenance.id },
                          }"
                          ><button class="btn btn-primary">
                            View / Edit
                          </button></router-link
                        >
                      </td>

                      <td>
                        <button
                          @click.prevent="cancel(maintenance)"
                          class="btn btn-primary"
                        >
                          Cancel Maintenance
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- COL END -->
      </div>

      <!--Row-->
      <div class="mt-5 row row-sm">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-margin">
          <div class="card">
            <div class="card-body pb-0 border-bottom">
              <div class="d-flex mb-3">
                <h6 class="card-title mb-0">All Cancelled Maintenance</h6>
                <div class="card-option ml-auto d-none d-md-flex">
                  <div class="mr-2">
                    <!-- <router-link to="/createmaintenance"><button class="btn btn-primary btn-sm rounded-lg fs-13"><i class="mdi mdi-plus-circle-outline"></i>Create New Maintenance </button></router-link> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="">
              <div class="table-responsive">
                <table
                  id="example2"
                  class="table table-striped"
                  style="width: 100%"
                >
                  <thead>
                    <tr>
                      <th>Asset ID</th>
                      <th>Asset Name</th>
                      <th>Maintenance Name</th>

                      <th>Assigned Staff</th>
                      <th>Date Created</th>
                      <th>Maintenance Due Date</th>
                      <th>View More</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="maintenance in allCancelledMaintenance"
                      :key="maintenance.id"
                    >
                      <td>{{ maintenance.assetId }}</td>
                      <td>{{ maintenance.assetName }}</td>
                      <td>{{ maintenance.name }}</td>

                      <td>{{ maintenance.staffAssignTo }}</td>
                      <td>{{ maintenance.date.split("T")[0] }}</td>
                      <td>
                        {{ maintenance.maintenanceDueDate.split("T")[0] }}
                      </td>

                      <td>
                        <router-link
                          :to="{
                            name: 'Edit Maintenance',
                            params: { id: maintenance.id },
                          }"
                          ><button class="btn btn-primary">
                            View
                          </button></router-link
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- COL END -->
      </div>
    </div>
    <!--Main Content Container-->
  </div>
  <!--Main Content-->
</template>

<script>
import $ from "jquery";
import Breadcrumb from "@/components/Breadcrumb";

export default {
  components: {
    Breadcrumb,
  },
  props: ["user", "BaseURL"],
  data() {
    return {
      allMaintenance: [],
      allCancelledMaintenance: [],
    };
  },

  methods: {
    cancel(maintenance) {
      //console.log(task);
      if (
        confirm(
          "Are you sure you want to cancel this maintenance ? This Action Is Irrevokable"
        )
      ) {
        fetch(this.BaseURL + "/CancelMaintenance", {
          method: "POST",
          body: JSON.stringify({
            id: maintenance.id,
            submittedBy: this.user.email,
            status: "cancel",
          }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
          .then((res) => res.json())
          .then((data) => {
            // console.log(data);
            this.isSuccessful = data.isSuccessful;
            this.errorMessage = data.message;

            if (this.isSuccessful == true) {
              window.location = "/maintenance";
            }
          });
      }
    },
  },

  beforeCreate() {
    if (localStorage.getItem("role") !== "User") {
      window.location = "/error";
    }
  },

  mounted() {
    fetch(this.BaseURL + "/getAllMaintenance", {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        //console.log(data);
        this.allMaintenance = data;
        $(() => {
          $("#example").DataTable();
        });
      });

    fetch(this.BaseURL + "/getAllCancelMaintenance", {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        //console.log(data);
        this.allCancelledMaintenance = data;
        $(() => {
          $("#example2").DataTable();
        });
      });
  },
};
</script>
