<template>
  <!--Main Content-->
  <div class="main-content px-0 app-content">
    <!--Main Content Container-->
    <div class="container-fluid">
      <!--Page Header-->
      <Breadcrumb />
      <!--Page Header-->
      <div class="forms card rounded">
        <form @submit.prevent="updateRenewal()">
          <!-- <label>Asset Name</label>
    <input type="text" :value="$route.params.id" class="form-control" disabled /> -->

          <label>Asset Name</label>
          <input
            type="text"
            v-model="renewal.assetName"
            class="form-control"
            disabled
          />

          <label>Renewal Name</label>
          <input
            type="text"
            v-model="renewal.name"
            class="form-control"
            required
          />

          <label>Registration Number</label>
          <input
            type="text"
            v-model="renewal.registrationNumber"
            class="form-control"
          />

          <label>Expiry Date </label>
          <input
            type="date"
            v-model="renewal.expirydate.split('T')[0]"
            class="form-control"
            Disabled
          />

          <label>New Expiry Date </label>
          <input
            type="date"
            v-model="renewal.expirydate"
            class="form-control"
          />

          <label>Renewal Cost</label>
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">₦</span>
            </div>
            <input
              type="number"
              v-model="renewal.renewalCost"
              class="form-control"
              required
            />
            <div class="input-group-append">
              <span class="input-group-text">.00</span>
            </div>
          </div>

          <label>Renewal Type</label>
          <select v-model="renewal.renewalTypeId" class="form-control mb-3">
            <option selected>Select Category</option>
            <option
              v-for="renewalType in renewalTypes"
              :key="renewalType.id"
              :value="renewalType.id"
            >
              {{ renewalType.name }}
            </option>
          </select>

          <!-- <label>Renewal Frequency</label>
          <select v-model="renewal.frequency" class="form-control mb-3">
             <option selected>{{claim.insurerName}}</option> 
            <option value="Recurring">Recurring</option>

            <option value="One Off">One Off</option>
          </select> -->

          <label>Driver's Name</label>
          <input
            type="text"
            v-model="renewal.driverName"
            class="form-control"
          />

          <label>User (Staff) Name</label>
          <input
            type="text"
            v-model="renewal.staffAssignTo"
            class="form-control"
          />

          <!-- <label>Total Sum</label>
          <input
            type="number"
            v-model="renewal.totalAmount"
            class="form-control"
            required
          /> -->
          <button v-if="loading" class="btn btn-primary" disabled>
            Loading
            <div class="spinner-border spinner-border-sm" role="status">
              <span style="width: 2px" class="sr-only text-light"
                >Loading...</span
              >
            </div>
          </button>

          <button
            v-else-if="renewal.status != 'cancel'"
            type="submit"
            class="btn btn-primary"
          >
            Update Renewal
          </button>
          <!-- <button
            @click="printDiv('invoice')"
            type="button"
            class="ml-4 btn btn-primary"
          >
            Generate Invoice
          </button> -->
        </form>

        <!-- <div id="invoice">
          <h3>Asset Maintenance Invoice</h3>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";

export default {
  components: {
    Breadcrumb,
  },

  props: ["user", "BaseURL"],

  data() {
    return {
      loading: false,
      renewal: {},
      asset: {},
      renewalTypes: [],

      isSuccessful: "",
      errorMessage: "",

      closed: false,
    };
  },

  methods: {
    printDiv(divName) {
      var printContents = document.getElementById(divName).innerHTML;
      var originalContents = document.body.innerHTML;

      document.body.innerHTML = printContents;

      window.print();

      document.body.innerHTML = originalContents;
    },

    updateRenewal() {
      this.loading = true;
      fetch(this.BaseURL + "/updateRenewal", {
        method: "POST",
        body: JSON.stringify(this.renewal),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          this.isSuccessful = data.isSuccessful;
          this.errorMessage = data.message;

          if (this.isSuccessful == true) {
            alert("Renewal Edited successfully");
            this.loading = false;
            window.location = "/renewals";
          } else {
            this.loading = false;
            alert(`Error : ${this.errorMessage}`);
          }
        });
    },

    close() {
      this.closed = !this.closed;
    },
  },

  beforeCreate() {
    if (localStorage.getItem("role") !== "User") {
      window.location = "/error";
    }
  },

  created() {
    fetch(this.BaseURL + "/getAssetById" + this.renewal.assetId, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        //console.log(data)
        this.asset = data;
      });

    fetch(this.BaseURL + "/getRenewalById" + this.$route.params.id, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        //console.log(data)
        this.renewal = data;
      });

    fetch(this.BaseURL + "/getAllRenewalType", {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        //console.log(data)
        this.renewalTypes = data;
      });
  },
};
</script>

<style scoped>
#invoice {
  display: none;
}
</style>