<template>
  <!--Main Content-->
  <div class="main-content px-0 app-content">
    <!--Main Content Container-->
    <div class="container-fluid">
      <!--Page Header-->
      <Breadcrumb />
      <!--Page Header-->

      <!--Row-->
      <div class="row row-sm">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-margin">
          <div class="card">
            <div class="card-body pb-0 border-bottom">
              <div class="d-flex mb-3">
                <h6 class="card-title mb-0">Requisition</h6>
                <div class="ml-auto d-md-flex">
                  <div class="mr-2">
                    <router-link to="/createrequisition"
                      ><button class="btn btn-primary btn-sm rounded-lg fs-13">
                        <i class="mdi mdi-plus-circle-outline"></i>Make New
                        Requisition
                      </button></router-link
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="">
              <div class="table-responsive">
                <table
                  id="example"
                  class="table table-striped"
                  style="width: 100%"
                >
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>Requesting Company</th>
                      <th>Requesting Department</th>
                      <th>Requisition Type</th>
                      <th>Request Description</th>
                      <th>Requisition Status</th>
                      <th>Head Admin</th>
                      <th>Head Admin Comment</th>
                      <th>Unit Head</th>
                      <th>Unit Head Comment</th>

                      <th>View / Edit</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="requisition in requisitions"
                      :key="requisition.id"
                    >
                      <td>{{ requisition.id }}</td>
                      <td>{{ requisition.company }}</td>
                      <td>{{ requisition.department }}</td>
                      <td>{{ requisition.type }}</td>
                      <td>{{ requisition.description }}</td>
                      <td>{{ requisition.status }}</td>
                      <td>{{ requisition.headAdmin }}</td>
                      <td>{{ requisition.headAdminComment }}</td>
                      <td>{{ requisition.unitHead }}</td>
                      <td>{{ requisition.unitHeadComment }}</td>

                      <td>
                        <router-link
                          :to="{
                            name: 'Edit Requisition',
                            params: { id: requisition.id },
                          }"
                          ><button class="btn btn-primary">
                            View / Edit
                          </button></router-link
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- COL END -->
      </div>
    </div>
    <!--Main Content Container-->
  </div>
  <!--Main Content-->
</template>

<script>
import $ from "jquery";
import Breadcrumb from "@/components/Breadcrumb";

export default {
  components: {
    Breadcrumb,
  },
  props: ["user", "BaseURL"],
  data() {
    return {
      requisitions: [],
    };
  },

  methods: {},
  mounted() {
    fetch(this.BaseURL + "/getAllStationeryRequisitionICTConsumableDevice", {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        //console.log(data)
        this.requisitions = data;
        $(() => {
          $("#example").DataTable();
        });
      });
  },
};
</script>