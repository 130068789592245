<template>
  <!--Main Content-->
  <div class="main-content px-0 app-content">
    <!--Main Content Container-->
    <div class="container-fluid">
      <!--Page Header-->
      <Breadcrumb />
      <!--Page Header-->
      <div class="forms card rounded">
        <div
          v-bind:class="[closed ? 'close' : '']"
          class="mb-4 bg-warning p-3 text-dark"
          v-if="isSuccessful === false"
        >
          <b>Error : {{ errorMessage }}</b>
          <i @click="close()" class="fas fa-close"></i>
        </div>
        <div v-else></div>
        <form @submit.prevent="edit()">
          <label>Asset Name</label>
          <input
            type="text"
            v-model="asset.name"
            class="form-control"
            required
          />

          <!--<label>Asset Code</label>
          <input
            type="text"
            v-model="asset.code"
            class="form-control"
            disabled
          />-->

          <label>Asset Tag Number</label>
          <input
            type="text"
            v-model="asset.tagNumber"
            class="form-control"
            disabled
          />

          <label>Days of Service</label>
          <input
            type="number"
            v-model="asset.daysofService"
            class="form-control"
            required
          />

          <label>Asset Purchase Date</label>

          <input
            type="date"
            v-model="asset.purchaseDate.split('T')[0]"
            class="form-control"
            disabled
          />
          <label>Select New Asset Purchase Date</label>

          <input
            type="date"
            v-model="asset.purchaseDate"
            class="form-control"
            required
          />

          <!-- v-model="asset.purchaseDate.split('T')[0]" -->

          <label>Asset Invoice Number</label>
          <input
            type="text"
            v-model="asset.invoiceNo"
            class="form-control"
            required
          />

          <label>Asset Purchase Amount</label>
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">₦</span>
            </div>

            <input
              type="number"
              v-model="asset.purchaseAmount"
              class="form-control"
              required
              aria-label="Amount (to the nearest naira)"
            />
            <div class="input-group-append">
              <span class="input-group-text">.00</span>
            </div>
          </div>

          <label>Asset Vendor</label>
          <input
            type="text"
            v-model="asset.vendor"
            class="form-control"
            required
          />

          <label>Asset Category</label>
          <select v-model="asset.categoryId" class="form-control mb-3">
            <option selected>Select Category</option>
            <option
              v-for="category in categories"
              :key="category.id"
              :value="category.id"
            >
              {{ category.name }}
            </option>
          </select>

          <label>Mode Of Payment</label>
          <select
            v-model="asset.modeOfPayment"
            class="form-control mb-3"
            required
          >
            <option selected>Select Payment Method</option>
            <option value="Transfer">Transfer</option>
            <option value="Cash">Cash</option>
          </select>

          <!-- <label>Total Sum</label>
          <input
            v-model="asset.approvedAmount"
            type="number"
            class="form-control"
          /> -->

          <div class="form-check mt-4 mb-4">
            <input
              class="form-check-input"
              type="checkbox"
              v-model="asset.maintenance"
              :value="true"
              id="maintenance"
            />
            <label class="form-check-label" for="flexCheckDefault">
              Maintenance
            </label>
          </div>

          <div class="form-check mb-4">
            <input
              class="form-check-input"
              type="checkbox"
              v-model="asset.renewal"
              :value="true"
              id="renewal"
            />
            <label class="form-check-label" for="flexCheckDefault">
              Renewal
            </label>
          </div>

          <button type="submit" class="btn btn-primary">Update</button>
        </form>
      </div>

      <div class="mt-5 forms card rounded">
        <P
          ><b
            >Set Asset ({{ asset.name }}) Up For Disposal Or Reallocation</b
          ></P
        >
        <router-link
          :to="{ name: 'User Approval', params: { id: $route.params.id } }"
          ><button type="button" class="btn btn-primary">
            Set Up
          </button></router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";

export default {
  components: {
    Breadcrumb,
  },

  props: ["user", "BaseURL"],

  data() {
    return {
      asset: {},

      maintenance: false,
      renewal: false,

      isSuccessful: "",
      errorMessage: "",

      closed: false,

      categories: [],
    };
  },

  methods: {
    edit() {
      fetch(this.BaseURL + "/updateAsset", {
        method: "POST",
        body: JSON.stringify(this.asset),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          this.isSuccessful = data.isSuccessful;
          this.errorMessage = data.message;

          if (this.isSuccessful == true) {
            alert("Asset Updated successfully");
            window.location = "/assets";
          }
        });
    },

    close() {
      this.closed = !this.closed;
    },
  },
  beforeCreate() {
    if (localStorage.getItem("role") !== "User") {
      window.location = "/error";
    }
  },

  created() {
    fetch(this.BaseURL + "/getAssetById" + this.$route.params.id, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        //console.log(data)
        this.asset = data;
      });

    fetch(this.BaseURL + "/getAllCategory", {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        //console.log(data)
        this.categories = data;
      });
  },

  computed: {
    // ifMaintenance() {
    //   return this.maintenance == "Maintenance" ? true : false;
    // },
    // ifRenewal() {
    //   return this.maintenance == "Renewal" ? true : false;
    // },
  },
};
</script>
