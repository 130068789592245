<template>
  <!--Main Content-->
  <div class="main-content px-0 app-content">
    <!--Main Content Container-->
    <div class="container-fluid">
      <!--Page Header-->
      <Breadcrumb />
      <!--Page Header-->

      <!--Row-->
      <div class="row row-sm">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-margin">
          <div class="card">
            <div class="card-body pb-0 border-bottom">
              <div class="d-flex mb-3">
                <h6 class="card-title mb-0">All Assets Approval</h6>
                <div class="ml-auto d-md-flex">
                  <!-- <div class="mr-2">
										<router-link to="/createcategory"><button class="btn btn-primary btn-sm rounded-lg fs-13"><i class="mdi mdi-plus-circle-outline"></i>Create New Category Type</button></router-link>
									</div> -->
                </div>
              </div>
            </div>
            <div class="">
              <div class="table-responsive">
                <table
                  id="example"
                  class="table table-striped"
                  style="width: 100%"
                >
                  <thead>
                    <tr>
                      <th>Approval Id</th>
                      <!-- <th>Asset Id</th> -->
                      <th>Asset Name</th>
                      <th>Asset Class</th>
                      <!-- <th>Date</th>
                                                <th>Sales Value</th> -->
                      <th>Destination</th>
                      <th>Asset Type</th>
                      <!-- <th>Accumulated Depreciation</th>
                                                <th>Reciept Number</th> -->
                      <!-- <th>Approval Level</th> -->
                      <th>Status</th>
                      <th v-if="user.role == 'User'">Status Message</th>

                      <!-- <th>User Approval</th>
                                                <th>User Comment</th> -->
                      <!-- <th>User Approval Date</th> -->
                      <!-- <th>ED Approval</th>
                                                <th>ED Comment</th> -->
                      <!-- <th>ED Approval Date</th> -->
                      <!-- <th>GMD Approval</th>
                                                <th>GMD Comment</th> -->
                      <!-- <th>GMD Approval Date</th> -->
                      <!-- <th>ARM Approval</th>
                                                <th>ARM Comment</th> -->
                      <!-- <th>ARM Approval Date</th> -->

                      <th v-if="user.role !== 'User'">
                        Approve ({{ user.role }})
                      </th>
                      <th v-else>View</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="approval in approvals" :key="approval.id">
                      <td>{{ approval.id }}</td>
                      <!-- <td>{{approval.assetId}}</td> -->
                      <td>{{ approval.assetName }}</td>
                      <td>{{ approval.classofAsset }}</td>
                      <!-- <td>{{approval.date}}</td>
                                                <td>{{approval.salesvalue}}</td> -->
                      <td>{{ approval.destination }}</td>
                      <td>{{ approval.type }}</td>
                      <!-- <td>{{approval.accumulatedDepreciation}}</td>
                                                <td>{{approval.receiptNumber}}</td> -->
                      <!-- <td>{{ approval.approvalLevel }}</td> -->
                      <td>{{ approval.status }}</td>
                      <td
                        :class="'text-' + statusMessage(approval).color"
                        v-if="user.role == 'User'"
                      >
                        {{ statusMessage(approval).message }}
                      </td>

                      <!-- <td>{{approval.userApproval}}</td>
                                                <td>{{approval.userComment}}</td> -->
                      <!-- <td>{{approval.userApprovalDate}}</td> -->
                      <!-- <td>{{approval.edApproval}}</td>
                                                <td>{{approval.edComment}}</td> -->
                      <!-- <td>{{approval.edApprovalDate}}</td> -->
                      <!-- <td>{{approval.gmdApproval}}</td>
                                                <td>{{approval.gmdComment}}</td> -->
                      <!-- <td>{{approval.gmdApprovalDate}}</td> -->
                      <!-- <td>{{approval.armApproval}}</td>
                                                <td>{{approval.armComment}}</td> -->
                      <!-- <td>{{approval.armApprovalDate}}</td> -->
                      <td v-if="user.role == 'ED'">
                        <router-link
                          v-if="approval.approvalLevel < 1"
                          :to="{
                            name: 'ED Approval',
                            params: { id: approval.id },
                          }"
                        >
                          <button class="btn btn-primary">View</button>
                        </router-link>

                        <button
                          v-else-if="
                            user.role == 'ED' &&
                            approval.status.includes('Disapproved')
                          "
                          class="btn disabled btn-warning"
                        >
                          view
                        </button>

                        <button v-else class="disabled btn btn-success">
                          Approved
                        </button>
                      </td>
                      <td v-if="user.role == 'GMD'">
                        <router-link
                          v-if="approval.approvalLevel < 2"
                          :to="{
                            name: 'GMD Approval',
                            params: { id: approval.id },
                          }"
                          ><button class="btn btn-warning">
                            View
                          </button></router-link
                        >

                        <button v-else class="disabled btn btn-success">
                          Approved
                        </button>
                      </td>
                      <td v-if="user.role == 'ARM'">
                        <router-link
                          v-if="approval.approvalLevel < 3"
                          :to="{
                            name: 'ARM Approval',
                            params: { id: approval.id },
                          }"
                          ><button class="btn btn-primary">
                            View
                          </button></router-link
                        >
                        <button v-else class="disabled btn btn-success">
                          Approved
                        </button>
                      </td>

                      <td
                        v-if="
                          user.role == 'User' &&
                          approval.status.includes('Disapproved')
                        "
                      >
                        <router-link
                          :to="{
                            name: 'Edit Approval',
                            params: { id: approval.id },
                          }"
                        >
                          <button class="btn btn-warning">Edit Details</button>
                        </router-link>
                      </td>
                      <td v-else-if="user.role == 'User'">
                        <router-link
                          :to="{
                            name: 'View Approval',
                            params: { id: approval.id },
                          }"
                        >
                          <button class="btn btn-primary">View Details</button>
                        </router-link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- COL END -->
      </div>
    </div>
    <!--Main Content Container-->
  </div>
  <!--Main Content-->
</template>

<script>
import $ from "jquery";
import Breadcrumb from "@/components/Breadcrumb";

export default {
  components: {
    Breadcrumb,
  },
  props: ["user", "BaseURL"],
  data() {
    return {
      approvals: [],
    };
  },

  methods: {
    statusMessage(approval) {
      var status = { message: "Pending", color: "danger" };
      //var sc = "danger";

      if (approval.approvalLevel < 1) {
        status.message = "Waiting On ED Approval";
      } else if (approval.approvalLevel < 2) {
        status.message = "Waiting On GMD Approval ";
      } else if (approval.approvalLevel < 3) {
        status.message = "Waiting On ARM Approval ";
      } else if (approval.approvalLevel >= 3) {
        status.message = "All Approvals Complete";
        status.color = "success";
      }

      return status;
    },
  },
  beforeCreate() {
    if (
      localStorage.getItem("role") !== "User" &&
      localStorage.getItem("role") !== "ED" &&
      localStorage.getItem("role") !== "GMD" &&
      localStorage.getItem("role") !== "ARM"
    ) {
      window.location = "/error";
    }
  },
  mounted() {
    fetch(this.BaseURL + "/getAllAssetReallocationDisposal", {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        this.approvals = data;
        $(() => {
          $("#example").DataTable({ ordering: false });
        });
      });
  },
};
</script>