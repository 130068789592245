<template>
  <!--Main Content-->
  <div class="main-content px-0 app-content">
    <!--Main Content Container-->
    <div class="container-fluid">
      <!--Page Header-->
      <Breadcrumb />
      <!--Page Header-->

      <!--Row-->
      <div class="row row-sm">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-margin">
          <div class="card">
            <div class="card-body pb-0 border-bottom">
              <div class="d-flex mb-3">
                <h6 class="card-title mb-0">All Pending Tasks</h6>
                <div class="ml-auto d-md-flex">
                  <div class="mr-2">
                    <router-link to="/createtask"
                      ><button class="btn btn-primary btn-sm rounded-lg fs-13">
                        <i class="mdi mdi-plus-circle-outline"></i>Create New
                        Task
                      </button></router-link
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="">
              <div class="table-responsive">
                <table
                  id="example"
                  class="table table-striped"
                  style="width: 100%"
                >
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>Task Name</th>
                      <th>Asset Name</th>
                      <th>Description</th>
                      <th>Status</th>
                      <th>Date Created</th>
                      <th>Due Date</th>
                      <th>Edit</th>
                      <th>Cancel</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="task in pendingTasks" :key="task.id">
                      <td>{{ task.id }}</td>
                      <td>{{ task.name }}</td>
                      <td>{{ task.assetId }}</td>
                      <td>{{ task.description }}</td>
                      <td>Pending</td>
                      <td>{{ task.submittedOn.split("T")[0] }}</td>
                      <td>{{ task.dueDate.split("T")[0] }}</td>

                      <td>
                        <router-link
                          :to="{ name: 'Edit Task', params: { id: task.id } }"
                          ><button class="btn btn-primary">
                            Edit
                          </button></router-link
                        >
                      </td>

                      <td>
                        <button
                          @click.prevent="cancel(task)"
                          class="btn btn-primary"
                        >
                          Cancel Task
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- COL END -->
      </div>

      <!--Row-->
      <div class="row row-sm mt-5">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-margin">
          <div class="card">
            <div class="card-body pb-0 border-bottom">
              <div class="d-flex mb-3">
                <h6 class="card-title mb-0">All Completed Tasks</h6>
                <div class="card-option ml-auto d-none d-md-flex">
                  <a href="#" class="text-dark"
                    ><i class="fe fe-more-horizontal"></i
                  ></a>

                  <!-- <div class="mr-2">
                  <router-link to="/createtask"
                    ><button class="btn btn-primary btn-sm rounded-lg fs-13">
                      <i class="mdi mdi-plus-circle-outline"></i>Create New
                      Task
                    </button></router-link
                  >
                </div> -->
                </div>
              </div>
            </div>
            <div class="">
              <div class="table-responsive">
                <table
                  id="example2"
                  class="table table-striped"
                  style="width: 100%"
                >
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>Task Name</th>
                      <th>Asset Name</th>
                      <th>Description</th>
                      <th>Status</th>
                      <th>Date Created</th>
                      <th>Due Date</th>
                      <th>Edit</th>
                      <th>Cancel</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="task in completedTasks" :key="task.id">
                      <td>{{ task.id }}</td>
                      <td>{{ task.name }}</td>
                      <td>{{ task.assetId }}</td>
                      <td>{{ task.description }}</td>
                      <td>Completed</td>
                      <td>{{ task.submittedOn.split("T")[0] }}</td>
                      <td>{{ task.dueDate.split("T")[0] }}</td>

                      <td>
                        <router-link
                          :to="{ name: 'Edit Task', params: { id: task.id } }"
                          ><button class="btn btn-primary">
                            Edit
                          </button></router-link
                        >
                      </td>

                      <td>
                        <button
                          @click.prevent="cancel(task)"
                          class="btn btn-primary"
                        >
                          Cancel Task
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- COL END -->
      </div>
    </div>
    <!--Main Content Container-->
  </div>
  <!--Main Content-->
</template>

<script>
import $ from "jquery";
import Breadcrumb from "@/components/Breadcrumb";

export default {
  components: {
    Breadcrumb,
  },
  props: ["user", "BaseURL"],
  data() {
    return {
      pendingTasks: [],
      completedTasks: [],

      username: localStorage.getItem("username"),

      isSuccessful: "",
      errorMessage: "",
    };
  },

  methods: {
    cancel(task) {
      //console.log(task);
      if (confirm("Are you sure you want to cancel this task")) {
        fetch(this.BaseURL + "/CancelTask", {
          method: "POST",
          body: JSON.stringify({
            id: task.id,
            submittedBy: this.user.email,
            deleted: 1,
          }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
          .then((res) => res.json())
          .then((data) => {
            console.log(data);
            this.isSuccessful = data.isSuccessful;
            this.errorMessage = data.message;

            if (this.isSuccessful == true) {
              window.location = "/tasks";
            }
          });
      }
    },
  },

  beforeCreate() {
    if (localStorage.getItem("role") !== "User") {
      window.location = "/error";
    }
  },

  mounted() {
    fetch(this.BaseURL + "/getAllPendingTask?SubmittedBy=" + this.username, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        //console.log(this.user.email);
        this.pendingTasks = data;
        $(() => {
          $("#example").DataTable();
        });
      });

    fetch(this.BaseURL + "/getAllCompletedTask?SubmittedBy=" + this.username, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        //console.log(data)
        this.completedTasks = data;
        $(() => {
          $("#example2").DataTable();
        });
      });
  },
};
</script>