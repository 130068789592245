<template>
  <!--Main Content-->
  <div class="main-content px-0 app-content">
    <!--Main Content Container-->
    <div class="container-fluid">
      <!--Page Header-->
      <Breadcrumb />
      <!--Page Header-->
      <div class="forms card rounded">
        <div
          v-bind:class="[closed ? 'close' : '']"
          class="mb-4 bg-warning p-3 text-dark"
          v-if="isSuccessful === false"
        >
          <b>Error : {{ errorMessage }}</b>
          <i @click="close()" class="fas fa-close"></i>
        </div>
        <div v-else></div>
        <form @submit.prevent="edit()">
          <label>Select Company</label>
          <select v-model="department.companyId" class="form-control mb-3">
            <option selected>Select Company</option>
            <option
              v-for="company in groupOfCompanys"
              :key="company.id"
              :value="company.id"
            >
              {{ company.name }}
            </option>
          </select>

          <label>Department</label>
          <input
            v-model="department.name"
            type="text"
            class="form-control"
            required
          />

          <button type="submit" class="btn btn-primary">Submit</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";

export default {
  components: {
    Breadcrumb,
  },

  props: ["user", "BaseURL"],

  data() {
    return {
      department: {},
      groupOfCompanys: [],

      isSuccessful: "",
      errorMessage: "",

      closed: false,
    };
  },

  beforeCreate() {
    if (localStorage.getItem("role") !== "Admin") {
      window.location = "/error";
    }
  },

  methods: {
    edit() {
      fetch(this.BaseURL + "/updateDepartment", {
        method: "POST",
        body: JSON.stringify({
          id: this.department.id,
          companyId: this.department.companyId,
          name: this.department.name,
          submittedBy: this.user.email,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          this.isSuccessful = data.isSuccessful;
          this.errorMessage = data.message;

          if (this.isSuccessful == true) {
            alert("Department Edited successfully");
            window.location = "/departments";
          }
        });
    },

    close() {
      this.closed = !this.closed;
    },
  },

  mounted() {
    fetch(this.BaseURL + "/getDepartmentById" + this.$route.params.id, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        this.department = data;
      });

    fetch(this.BaseURL + "/getAllCompany", {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        //console.log(data)
        this.groupOfCompanys = data;
      });
  },
};
</script>