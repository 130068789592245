<template>
  <!--Main Content-->
  <div class="main-content px-0 app-content">
    <!--Main Content Container-->
    <div class="container-fluid">
      <!--Page Header-->
      <Breadcrumb />
      <!--Page Header-->

      <!--Row-->
      <div class="row row-sm">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-margin">
          <div class="card">
            <div class="card-body pb-0 border-bottom">
              <div class="d-flex mb-3">
                <h6 class="card-title mb-0">All Pending Deliveries</h6>
                <div class="ml-auto d-md-flex">
                  <div class="mr-2">
                    <router-link to="/createdelivery"
                      ><button class="btn btn-primary btn-sm rounded-lg fs-13">
                        <i class="mdi mdi-plus-circle-outline"></i>Create New
                        Delivery
                      </button></router-link
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="">
              <div class="table-responsive">
                <table
                  id="example"
                  class="table table-striped"
                  style="width: 100%"
                >
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>Mail Type</th>
                      <th>Item</th>
                      <th>Reciever Name</th>
                      <th>Reciever Address</th>
                      <th>Delivery Status</th>

                      <th>View / Edit</th>
                      <th>Cancel Delivery</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="delivery in pendingGroupOfDeliverys"
                      :key="delivery.id"
                    >
                      <td>{{ delivery.id }}</td>
                      <td>{{ delivery.mailType }}</td>
                      <td>{{ delivery.item }}</td>
                      <td>{{ delivery.receiverName }}</td>
                      <td>{{ delivery.receiverAddress }}</td>
                      <td>{{ delivery.deliveryStatus }}</td>

                      <td>
                        <router-link
                          :to="{
                            name: 'Edit Delivery',
                            params: { id: delivery.id },
                          }"
                          ><button class="btn btn-primary">
                            View / Edit
                          </button></router-link
                        >
                      </td>
                      <td>
                        <button
                          @click="
                            if (delivery.deliveryStatus !== 'Delivered') {
                              cancel(delivery);
                            }
                          "
                          :class="[
                            delivery.deliveryStatus == 'Delivered'
                              ? 'disabled'
                              : '',
                          ]"
                          class="btn btn-primary"
                        >
                          Cancel Delivery
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- COL END -->
      </div>
      <!--Row-->
      <div class="row row-sm pt-5">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-margin">
          <div class="card">
            <div class="card-body pb-0 border-bottom">
              <div class="d-flex mb-3">
                <h6 class="card-title mb-0">All Completed Deliveries</h6>
                <div class="ml-auto d-md-flex">
                  <div class="mr-2">
                    <router-link to="/createdelivery"
                      ><button class="btn btn-primary btn-sm rounded-lg fs-13">
                        <i class="mdi mdi-plus-circle-outline"></i>Create New
                        Delivery
                      </button></router-link
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="">
              <div class="table-responsive">
                <table
                  id="example2"
                  class="table table-striped"
                  style="width: 100%"
                >
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>Mail Type</th>
                      <th>Item</th>
                      <th>Reciever Name</th>
                      <th>Reciever Address</th>
                      <th>Delivery Status</th>

                      <th>View</th>
                      <!-- <th>Cancel Delivery</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="delivery in groupOfDeliverys" :key="delivery.id">
                      <td>{{ delivery.id }}</td>
                      <td>{{ delivery.mailType }}</td>
                      <td>{{ delivery.item }}</td>
                      <td>{{ delivery.receiverName }}</td>
                      <td>{{ delivery.receiverAddress }}</td>
                      <td>{{ delivery.deliveryStatus }}</td>

                      <td>
                        <router-link
                          :to="{
                            name: 'Edit Delivery',
                            params: { id: delivery.id },
                          }"
                          ><button class="btn btn-primary">
                            View
                          </button></router-link
                        >
                      </td>
                      <!-- <td>
                        <button
                          @click="
                            if (delivery.deliveryStatus !== 'Delivered') {
                              cancel(delivery);
                            }
                          "
                          :class="[
                            delivery.deliveryStatus == 'Delivered'
                              ? 'disabled'
                              : '',
                          ]"
                          class="btn btn-primary"
                        >
                          Cancel Delivery
                        </button>
                      </td> -->
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- COL END -->
      </div>

      <!--Row-->
      <div class="row mt-5 row-sm">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-margin">
          <div class="card">
            <div class="card-body pb-0 border-bottom">
              <div class="d-flex mb-3">
                <h6 class="card-title mb-0">Cancelled Deliveries</h6>
                <div class="ml-auto d-md-flex">
                  <div class="mr-2"></div>
                </div>
              </div>
            </div>
            <div class="">
              <div class="table-responsive">
                <table
                  id="example3"
                  class="table table-striped"
                  style="width: 100%"
                >
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>Mail Type</th>
                      <th>Item</th>
                      <th>Reciever Name</th>
                      <th>Reciever Address</th>
                      <th>Delivery Status</th>

                      <th>View</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="delivery in cancelledDeliveries"
                      :key="delivery.id"
                    >
                      <td>{{ delivery.id }}</td>
                      <td>{{ delivery.mailType }}</td>
                      <td>{{ delivery.item }}</td>
                      <td>{{ delivery.receiverName }}</td>
                      <td>{{ delivery.receiverAddress }}</td>
                      <td>{{ delivery.deliveryStatus }}</td>

                      <td>
                        <router-link
                          :to="{
                            name: 'Edit Delivery',
                            params: { id: delivery.id },
                          }"
                          ><button class="btn btn-primary">
                            View
                          </button></router-link
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- COL END -->
      </div>
    </div>
    <!--Main Content Container-->
  </div>
  <!--Main Content-->
</template>

<script>
import $ from "jquery";
import Breadcrumb from "@/components/Breadcrumb";

export default {
  components: {
    Breadcrumb,
  },
  props: ["user", "BaseURL"],
  data() {
    return {
      groupOfDeliverys: [],
      pendingGroupOfDeliverys: [],
      cancelledDeliveries: [],
    };
  },

  methods: {
    cancel(delivery) {
      //console.log(task);
      if (confirm("Are you sure you want to cancel this delivery")) {
        fetch(this.BaseURL + "/CancelDelivery", {
          method: "POST",
          body: JSON.stringify({
            id: delivery.id,
            submittedBy: this.user.email,
            status: "cancel",
          }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
          .then((res) => res.json())
          .then((data) => {
            // console.log(data);
            this.isSuccessful = data.isSuccessful;
            this.errorMessage = data.message;

            if (this.isSuccessful == true) {
              window.location = "/delivery";
            }
          });
      }
    },
  },

  beforeCreate() {
    if (localStorage.getItem("role") !== "User") {
      window.location = "/error";
    }
  },

  mounted() {
    fetch(this.BaseURL + "/getAllDelivery", {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        //console.log(data)
        this.groupOfDeliverys = data.filter(
          (delivery) => delivery.deliveryStatus == "Delivered"
        );
        $(() => {
          $("#example").DataTable();
        });
        this.pendingGroupOfDeliverys = data.filter(
          (delivery) => delivery.deliveryStatus == "Not Delivered"
        );
        $(() => {
          $("#example2").DataTable();
        });
      });

    fetch(this.BaseURL + "/getAllCancelDelivery", {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        //console.log(data)
        this.cancelledDeliveries = data;
        $(() => {
          $("#example3").DataTable();
        });
      });
  },
};
</script>

<style>
.disabled:hover {
  cursor: not-allowed;
}
</style>