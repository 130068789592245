<template>
  <!--Main Content-->
  <div class="main-content px-0 app-content">
    <!--Main Content Container-->
    <div class="container-fluid">
      <!--Page Header-->
      <Breadcrumb />
      <!--Page Header-->
      <div class="forms mb-5 card rounded">
        <ClaimForm :user="this.user" :BaseURL="this.BaseURL" />
      </div>
    </div>
  </div>
</template>

<script>
import ClaimForm from "@/components/ClaimForm";
import Breadcrumb from "@/components/Breadcrumb";

export default {
  components: {
    ClaimForm,
    Breadcrumb,
  },

  props: ["user", "BaseURL"],

  data() {
    return {
      claims: [],
    };
  },

  methods: {},
};
</script>