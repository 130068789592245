<template>
  <!--Main Content-->
  <div class="main-content px-0 app-content">
    <!--Main Content Container-->
    <div class="container-fluid">
      <!--Page Header-->
      <Breadcrumb />
      <!--Page Header-->
      <div class="forms card rounded">
        <div
          v-bind:class="[closed ? 'close' : '']"
          class="mb-4 bg-warning p-3 text-dark"
          v-if="isSuccessful === false"
        >
          <b>Error : {{ errorMessage }}</b>
          <i @click="close()" class="fas fa-close"></i>
        </div>
        <div v-else></div>
        <form>
          <label>Selected Asset To Retire</label>
          <select
            @change="getAsset()"
            v-model="retirement.assetId"
            class="form-control mb-3"
            disabled
          >
            <option selected>Select Asset</option>
            <option v-for="asset in assets" :key="asset.id" :value="asset.id">
              {{ asset.name }} ({{ asset.code }})
            </option>
          </select>

          <label>Payee</label>
          <input
            type="text"
            v-model="retirement.payee"
            class="form-control"
            disabled
          />

          <label>Date</label>
          <input
            type="date"
            :value="retirement.date.split('T')[0]"
            class="form-control"
            disabled
          />

          <label>Purpose</label>
          <textarea
            class="form-control"
            v-model="retirement.purpose"
            rows="4"
            disabled
          ></textarea>

          <label>Amount Advanced</label>
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">₦</span>
            </div>

            <input
              type="text"
              v-model="retirement.amountAdvanced"
              class="form-control"
              disabled
            />
            <div class="input-group-append">
              <span class="input-group-text">.00</span>
            </div>
          </div>

          <label>Amount Spent</label>
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">₦</span>
            </div>

            <input
              type="text"
              v-model="retirement.amountSpent"
              class="form-control"
              disabled
            />
            <div class="input-group-append">
              <span class="input-group-text">.00</span>
            </div>
          </div>

          <label>Serial Number</label>
          <input
            v-model="retirement.serialNumber"
            type="number"
            class="form-control"
            disabled
          />

          <label>Account Code</label>
          <input
            v-model="retirement.accountCode"
            type="text"
            class="form-control"
            disabled
          />

          <label>DR.</label>
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">₦</span>
            </div>

            <input
              type="text"
              v-model="retirement.debit"
              class="form-control"
              disabled
            />
            <div class="input-group-append">
              <span class="input-group-text">.00</span>
            </div>
          </div>

          <label>CR.</label>
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">₦</span>
            </div>

            <input
              type="text"
              v-model="retirement.credit"
              class="form-control"
              disabled
            />
            <div class="input-group-append">
              <span class="input-group-text">.00</span>
            </div>
          </div>

          <label>Refund to Coy</label>
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">₦</span>
            </div>

            <input
              type="text"
              v-model="retirement.refundtoCoy"
              class="form-control"
              disabled
            />
            <div class="input-group-append">
              <span class="input-group-text">.00</span>
            </div>
          </div>

          <label>Refund to Payee </label>
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">₦</span>
            </div>

            <input
              type="text"
              v-model="retirement.refundtoPayee"
              class="form-control"
              disabled
            />
            <div class="input-group-append">
              <span class="input-group-text">.00</span>
            </div>
          </div>

          <label>Submitted By</label>
          <input
            :value="retirement.submittedBy"
            type="text"
            class="form-control"
            disabled
          />

          <label>ED Comment</label>
          <textarea
            class="form-control mb-4"
            v-model="edComment"
            rows="4"
            required
          ></textarea>

          <button
            @click.prevent="approve()"
            type="button"
            class="btn btn-primary"
          >
            Approve Retirement
          </button>
          <button
            @click.prevent="disapprove()"
            type="button"
            class="btn btn-primary ml-4"
          >
            Dispprove Retirement
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";

export default {
  components: {
    Breadcrumb,
  },
  props: ["user", "BaseURL"],
  data() {
    return {
      retirement: {},

      asset: {},
      assets: [],

      edComment: " ",

      isSuccessful: "",
      errorMessage: "",

      closed: "",
    };
  },

  methods: {
    getAsset() {
      fetch(this.BaseURL + "/getAssetById" + this.assetId, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((res) => res.json())
        .then((data) => {
          //console.log(data)
          this.asset = data;
        });
    },

    approve() {
      fetch(this.BaseURL + "/EDRetirementApproval", {
        method: "POST",
        body: JSON.stringify({
          id: this.retirement.id,
          submittedBy: this.user.email,
          edComment: this.edComment,
          status: true,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          this.isSuccessful = data.isSuccessful;
          this.errorMessage = data.message;

          if (this.isSuccessful == true) {
            alert("Approved Successfully");
            window.location = "/retirementapprovals";
          }
        });
    },

    disapprove() {
      fetch(this.BaseURL + "/EDRetirementApproval", {
        method: "POST",
        body: JSON.stringify({
          id: this.retirement.id,
          submittedBy: this.user.email,
          edComment: this.edComment,
          status: false,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          this.isSuccessful = data.isSuccessful;
          this.errorMessage = data.message;

          if (this.isSuccessful == true) {
            alert("Retirement Disapproved");
            window.location = "/retirementapprovals";
          }
        });
    },

    close() {
      this.closed = true;
    },
  },

  beforeCreate() {
    if (localStorage.getItem("role") !== "ED") {
      window.location = "/error";
    }
  },

  created() {
    fetch(this.BaseURL + "/getByIdRetirement" + this.$route.params.id, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        //console.log(data)
        this.retirement = data;
      });

    fetch(this.BaseURL + "/getAllAsset", {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        //console.log(data)
        this.assets = data;
      });
  },
};
</script>