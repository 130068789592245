<template>
  <form @submit.prevent="scheduleMaintenance()">
    <label>Asset Name</label>
    <input type="text" :value="asset.name" class="form-control" disabled />

    <label>Model</label>
    <input type="text" v-model="make" class="form-control" />

    <label>Speedometer</label>
    <input type="number" v-model="speedometre" class="form-control" />

    <label>Registration Number</label>
    <input type="text" v-model="regNo" class="form-control" />

    <label>Maintenance Type</label>
    <select v-model="maintenanceTypeId" class="form-control mb-3">
      <option selected>Select Maintenance Type</option>
      <option
        v-for="maintenanceType in maintenanceTypes"
        :key="maintenanceType.id"
        :value="maintenanceType.id"
      >
        {{ maintenanceType.name }}
      </option>
    </select>

    <label>Maintenance Name</label>
    <input type="text" v-model="name" class="form-control" required />

    <label>Maintenance Due Date </label>
    <input
      id="date"
      type="date"
      v-model="dueDate"
      class="form-control"
      required
    />

    <label>Description Of Maintenance</label>
    <textarea
      v-model="description"
      class="form-control mb-3"
      rows="3"
    ></textarea>

    <!-- <label>Maintenance Frequency</label>
    <select v-model="frequency" class="form-control mb-3">
      <option selected>{{claim.insurerName}}</option>
      <option value="Recurring">Recurring</option>

      <option value="One Off">One Off</option>
    </select> -->

    <!-- <label>Mentainance Cost</label>
    <input type="number" v-model="cost" class="form-control" /> -->

    <label>Maintenance Cost</label>
    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <span class="input-group-text">₦</span>
      </div>

      <input
        type="text"
        v-model="cost"
        class="form-control"
        required
        aria-label="Amount (to the nearest naira)"
      />
      <div class="input-group-append">
        <span class="input-group-text">.00</span>
      </div>
    </div>

    <label>Workshop Manager</label>
    <input type="text" v-model="manager" class="form-control" />

    <label>Driver's Name</label>
    <input type="text" v-model="driverName" class="form-control" />

    <label>Means of Identification</label>
    <input type="text" v-model="idcard" class="form-control" />

    <label>Means of Identification Number</label>
    <input type="text" v-model="idcardno" class="form-control" />

    <label>Date Of issue</label>
    <input type="date" v-model="date" class="form-control" />

    <label>Assigned Staff</label>
    <input type="text" v-model="staff" class="form-control" />

    <label>Designation</label>
    <input type="text" class="form-control" />

    <div class="row">
      <div class="col">
        <label>Signature</label>
        <textarea class="form-control mb-3" rows="2"></textarea>
      </div>

      <div class="col">
        <label>Signature Date</label>
        <textarea class="form-control mb-3" rows="2"></textarea>
      </div>
    </div>

    <!-- <label>Total Amount</label>
    <input type="number" v-model="totalAmount" class="form-control" /> -->

    <!--label>Designation</label>
    <input type="text" v-model="designation" class="form-control">

    <label>Head Of Admin Signature</label>
    <input type="text" v-model="name" class="form-control" required>

    <label>Signature</label>
    <input type="text" v-model="signature" class="form-control"-->

    <label>Head Of Admin Comments</label>
    <textarea v-model="comments" class="form-control mb-3" rows="3"></textarea>

    <div class="row">
      <div class="col">
        <label>Head Of Admin Signature</label>
        <textarea class="form-control mb-3" rows="2"></textarea>
      </div>

      <div class="col">
        <label>Head Of Admin Signature Date</label>
        <textarea class="form-control mb-3" rows="2"></textarea>
      </div>
    </div>

    <p class="mg-b-20">Upload Copy Of Signed Form</p>
    <div class="">
      <input
        type="file"
        v-on:change="handleFileUpload($event)"
        class="dropify"
        data-height="180"
        required
      />
    </div>

    <button
      v-if="loading == true"
      type="submit"
      class="btn btn-primary"
      disabled
    >
      Loading
      <div class="spinner-border spinner-border-sm" role="status">
        <span style="width: 2px" class="sr-only text-light">Loading...</span>
      </div>
    </button>
    <button v-else type="submit" class="btn btn-primary">Save</button
    ><br /><br />

    <span>
      1. The work or services should be carried out strictly according to
      instructions given and the Company reserves the right to reject the
      work/services if it does not conform with the report form.
      <br />2. Any variation of this report shall be binding only if made in
      writing and signed by Head of Administration.
    </span>
  </form>
</template>

<script>
import $ from "jquery";
export default {
  props: ["user", "BaseURL"],

  data() {
    return {
      loading: false,
      name: "",
      regNo: "",
      make: "",
      dueDate: "",
      date: "",
      speedometre: "",
      description: "",
      frequency: " ",
      cost: "",
      manager: "",
      driverName: "",
      idcard: "",
      idcardno: "",
      staff: "",
      comments: "",
      maintenanceTypeId: "Select Maintenance Type",
      totalAmount: 0,

      isSuccessful: "",
      errorMessage: "",

      closed: false,

      maintenanceTypes: [],

      signedFormFile: "",

      asset: {},
    };
  },

  created() {
    //console.log(this.$route.params.id);
    $(function () {
      var dtToday = new Date();

      var month = dtToday.getMonth() + 1;
      var day = dtToday.getDate();
      var year = dtToday.getFullYear();
      if (month < 10) month = "0" + month.toString();
      if (day < 10) day = "0" + day.toString();

      var maxDate = year + "-" + month + "-" + day;
      //alert(maxDate);
      $("#date").attr("min", maxDate);
    });

    fetch(this.BaseURL + "/getAllMaintenanceType", {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        //console.log(data)
        this.maintenanceTypes = data;
      });

    fetch(this.BaseURL + "/getAssetById" + this.$route.params.id, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        //console.log(data)
        this.asset = data;
      });
  },

  methods: {
    format() {
      this.cost = new Intl.NumberFormat().format(
        parseInt(this.cost.replace(",", ""))
      );
    },

    handleFileUpload(e) {
      this.signedFormFile = e.target.files[0];
    },

    scheduleMaintenance() {
      this.loading = true;
      let formData = new FormData();
      formData.append("FormFile", this.signedFormFile);
      formData.append("Name", this.name);
      formData.append("SubmittedBy", this.user.email);
      formData.append("AssetId", this.$route.params.id);
      formData.append("MaintenanceTypeId", this.maintenanceTypeId);
      formData.append("RegistrationNumber", this.regNo);
      formData.append("MaintenanceDueDate", this.dueDate);
      formData.append("Speedometre", this.speedometre);
      formData.append("Make", this.make);
      formData.append("TotalAmount", this.totalAmount);
      formData.append("DescriptionofMaintenance", this.description);
      formData.append("MaintenanceCost", parseInt(this.cost));
      formData.append("WorkshopManager", this.manager);
      formData.append("DriverName", this.driverName);
      formData.append("IdentityCard", this.idcard);
      formData.append("IdentityCardNo", this.idcardno);
      formData.append("Date", this.date);
      formData.append("StaffAssignTo", this.staff);
      formData.append("CommentsByHeadofAdmin", this.comments);
      formData.append("Uploadcopyofsignedform", " ");
      formData.append("TermsConditions", " ");
      formData.append("Frequency", this.frequency);

      // console.log(formData);

      fetch(this.BaseURL + "/addMaintenance", {
        method: "POST",
        body: formData,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log(data);
          this.isSuccessful = data.isSuccessful;
          this.errorMessage = data.message;

          if (this.isSuccessful == true) {
            alert("Maintenance Scheduled Successfully");
            this.loading = false;
            window.location = "/maintenance";
          } else {
            alert("Error : " + this.errorMessage);
            this.loading = false;
          }
        });
    },
  },
};
</script>

