<template>
  <!--Main Content-->
  <div class="main-content px-0 app-content">
    <!--Main Content Container-->
    <div class="container-fluid">
      <!--Page Header-->
      <Breadcrumb />
      <!--Page Header-->
      <!-- <div class="forms mb-5 card rounded">
        <ApprovalForm :user="this.user" />
      </div> -->

      <div class="">
        <div class="row row-sm">
          <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-margin">
            <div class="card">
              <div class="card-body pb-0 border-bottom">
                <div class="d-flex mb-3">
                  <h6 class="card-title mb-0">Assets For Transfer</h6>
                  <div class="card-option ml-auto d-none d-md-flex">
                    <div class="mr-2">
                      <!-- <router-link to="/createapproval"><button class="btn btn-primary btn-sm rounded-lg fs-13"><i class="mdi mdi-plus-circle-outline"></i>Create New Approval </button></router-link> -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="">
                <form class="card m-3" @submit.prevent="getAllApproval()">
                  <div class="m-3 p-3 row">
                    <div class="col-md-6">
                      <!-- <h5 class="mb-3 bg-light p-2">Generate By Date</h5> -->
                      <p>Start Date</p>
                      <input
                        class="form-control"
                        type="date"
                        required
                        v-model="startDate"
                        name="start"
                      />
                    </div>

                    <div class="col-md-6">
                      <!-- <h5 class="mb-3 bg-light p-2">
                      Generate By Category Or Status
                    </h5> -->

                      <p>End Date</p>
                      <input
                        v-model="endDate"
                        class="form-control"
                        type="date"
                        required
                        name="end"
                      />
                    </div>
                    <button v-if="loading" class="btn btn-primary" disabled>
                      Loading
                      <div
                        class="spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span style="width: 2px" class="sr-only text-light"
                          >Loading...</span
                        >
                      </div>
                    </button>
                    <input
                      v-else-if="approvals.length == 0"
                      class="ml-3 btn btn-primary"
                      type="submit"
                      value="Generate"
                    />
                    <button class="ml-5 btn btn-warning" @click.prevent="reset">
                      Reset
                    </button>
                  </div>
                </form>
                <div class="card m-3">
                  <div class="table-responsive">
                    <table
                      id="example"
                      class="table table-striped"
                      style="width: 100%"
                    >
                      <thead>
                        <tr>
                          <th>Id</th>
                          <!-- <th>Asset Id</th> -->
                          <th>Asset Name</th>
                          <th>Asset Class</th>
                          <!-- <th>Date</th>
                                                <th>Sales Value</th> -->
                          <th>Destination</th>
                          <th>Type</th>
                          <!-- <th>Accumulated Depreciation</th>
                                                <th>Reciept Number</th> -->
                          <!-- <th>Status</th> -->
                          <th>Approval Level</th>
                          <th>User Approval</th>
                          <th>User Comment</th>
                          <!-- <th>User Approval Date</th> -->
                          <th>ED Approval</th>
                          <th>ED Comment</th>
                          <!-- <th>ED Approval Date</th> -->
                          <th>GMD Approval</th>
                          <th>GMD Comment</th>
                          <!-- <th>GMD Approval Date</th> -->
                          <th>ARM Approval</th>
                          <th>ARM Comment</th>
                          <!-- <th>ARM Approval Date</th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="approval in approvals" :key="approval.id">
                          <td>{{ approval.id }}</td>
                          <!-- <td>{{approval.assetId}}</td> -->
                          <td>{{ approval.assetName }}</td>
                          <td>{{ approval.classofAsset }}</td>
                          <!-- <td>{{approval.date}}</td>
                                                <td>{{approval.salesvalue}}</td> -->
                          <td>{{ approval.destination }}</td>
                          <td>{{ approval.type }}</td>
                          <!-- <td>{{approval.accumulatedDepreciation}}</td>
                                                <td>{{approval.receiptNumber}}</td> -->
                          <!-- <td>{{approval.approvalLevel}}</td> -->
                          <td>{{ approval.status }}</td>

                          <td>{{ approval.userApproval }}</td>
                          <td>{{ approval.userComment }}</td>
                          <!-- <td>{{approval.userApprovalDate}}</td> -->
                          <td>{{ approval.edApproval }}</td>
                          <td>{{ approval.edComment }}</td>
                          <!-- <td>{{approval.edApprovalDate}}</td> -->
                          <td>{{ approval.gmdApproval }}</td>
                          <td>{{ approval.gmdComment }}</td>
                          <!-- <td>{{approval.gmdApprovalDate}}</td> -->
                          <td>{{ approval.armApproval }}</td>
                          <td>{{ approval.armComment }}</td>
                          <!-- <td>{{approval.armApprovalDate}}</td> -->
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- COL END -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
//import ApprovalForm from "@/components/ApprovalForm";
import Breadcrumb from "@/components/Breadcrumb";

export default {
  components: {
    //ApprovalForm,
    Breadcrumb,
  },

  props: ["user", "BaseURL"],

  data() {
    return {
      loading: false,
      approvals: [],

      startDate: "",
      endDate: "",
      categoryId: 0,
      status: "",
    };
  },

  beforeCreate() {
    if (localStorage.getItem("role") !== "User") {
      window.location = "/error";
    }
  },

  methods: {
    getAllApproval() {
      this.loading = true;
      fetch(
        this.BaseURL +
          "/getAssetTransferByDate/" +
          this.startDate +
          "/" +
          this.endDate,
        {
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          this.approvals = data;

          if (this.approvals.length == 0) {
            this.loading = false;
            alert(
              `No Data Available Within Specified Date Or Category To Show`
            );
          } else {
            this.loading = false;
            $(() => {
              $("#example").DataTable({
                dom: "Bfrtip",
                buttons: [
                  "colvis",
                  "copyHtml5",
                  "csvHtml5",
                  "excel",
                  "pdf",
                  "print",
                ],
              });
            });
          }
          //console.log(data);
          // this.isSuccessful = data.isSuccess;
          // this.errorMessage = data.message;
        });
    },

    reset() {
      window.location.reload(true);
    },
  },
};
</script>