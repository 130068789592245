import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'

//import VueSession from 'vue-session';
import 'jquery/dist/jquery.min.js';
import "datatables.net-bs5/js/dataTables.bootstrap5"
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css"

import 'datatables.net-buttons-bs5/css/buttons.bootstrap5.min.css'
import 'datatables.net-buttons-bs5/js/buttons.bootstrap5'

import 'datatables.net-buttons/js/buttons.colVis'
import 'datatables.net-buttons/js/buttons.flash'
import 'datatables.net-buttons/js/buttons.html5'
import 'datatables.net-buttons/js/buttons.print'
import 'datatables.net-buttons/js/dataTables.buttons'

// import 'accounting-js'

import 'bootstrap-modal-js'
import 'bootstrap-modal-js/dist/bootstrap-modal-js'


// import 'bootstrap-datepicker'
// import 'bootstrap-datepicker/js/bootstrap-datepicker'
// import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css'
// import 'bootstrap-datepicker/dist/js/bootstrap-datepicker'

import $ from 'jquery'


const assetManagementApp = createApp(App)

//Vue.use(VueSession)
assetManagementApp.use(router)
assetManagementApp.mount('#app')


var timeoutInMiliseconds = 900000;
var timeoutId; 
 
function startTimer() { 
    // window.setTimeout returns an Id that can be used to start and stop a timer
    timeoutId = window.setTimeout(doInactive, timeoutInMiliseconds)
}
 
function doInactive() {

    if(localStorage.getItem('token')){
        localStorage.clear()
    window.location.reload()
    }
    
}

function resetTimer() { 
    window.clearTimeout(timeoutId)
    startTimer();
}

function setupTimers () {
    document.addEventListener("mousemove", resetTimer, false);
    document.addEventListener("mousedown", resetTimer, false);
    document.addEventListener("keypress", resetTimer, false);
    document.addEventListener("touchmove", resetTimer, false);
     
    startTimer();
}

setupTimers();

$(function () {
    var dtToday = new Date();

    var month = dtToday.getMonth() + 1;
    var day = dtToday.getDate();
    var year = dtToday.getFullYear();
    if (month < 10)
      month = '0' + month.toString();
    if (day < 10)
      day = '0' + day.toString();

    var maxDate = year + '-' + month + '-' + day;
    //alert(maxDate);
    $('#date').attr('min', maxDate);
  });

// $('.date').datepicker({
//     format: 'dd/mm/yyyy',
//     startDate: '-3d'
// });


  




