<template>
  <!--Main Content-->
  <div class="main-content px-0 app-content">
    <!--Main Content Container-->
    <div class="container-fluid">
      <!--Page Header-->
      <Breadcrumb />
      <!--Page Header-->

      <!--Row-->
      <div class="row row-sm">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-margin">
          <div class="card">
            <div class="card-body pb-0 border-bottom">
              <div class="d-flex mb-3">
                <h6 class="card-title mb-0">All Active Renewals</h6>
                <div class="card-option ml-auto d-none d-md-flex">
                  <!--div class="mr-2">
										<router-link to="/createrenewal"><button class="btn btn-primary btn-sm rounded-lg fs-13"><i class="mdi mdi-plus-circle-outline"></i>Create New Renewal </button></router-link>
									</div-->
                </div>
              </div>
            </div>
            <div class="">
              <div class="table-responsive">
                <table
                  id="example"
                  class="table table-striped"
                  style="width: 100%"
                >
                  <thead>
                    <tr>
                      <th>Asset Name</th>
                      <th>Registration Number</th>
                      <th>Renewal Name</th>
                      <th>Renewal Type</th>

                      <th>Expiry Date</th>
                      <th>Renewal Cost</th>
                      <th>Driver Name</th>
                      <!-- <th>Total Amount</th> -->
                      <th>Assigned Staff</th>

                      <th>Edit</th>
                      <th>Cancel</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="renewal in renewals" :key="renewal.id">
                      <td>{{ renewal.assetName }}</td>
                      <td>{{ renewal.registrationNumber }}</td>
                      <td>{{ renewal.name }}</td>
                      <td>{{ renewal.renewalTypeId }}</td>

                      <td>{{ renewal.expirydate.split("T")[0] }}</td>
                      <td>{{ renewal.renewalCost }}</td>
                      <td>{{ renewal.driverName }}</td>
                      <!-- <td>{{ renewal.totalAmount }}</td> -->
                      <td>{{ renewal.staffAssignTo }}</td>

                      <td>
                        <router-link
                          :to="{
                            name: 'Edit Renewal',
                            params: { id: renewal.id },
                          }"
                          ><button class="btn btn-primary">
                            View / Edit
                          </button></router-link
                        >
                      </td>

                      <td>
                        <button
                          @click.prevent="cancel(renewal)"
                          class="btn btn-primary"
                        >
                          Cancel Renewal
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- COL END -->
      </div>

      <div class="mt-5 row row-sm">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-margin">
          <div class="card">
            <div class="card-body pb-0 border-bottom">
              <div class="d-flex mb-3">
                <h6 class="card-title mb-0">All Cancelled Renewals</h6>
                <div class="card-option ml-auto d-none d-md-flex">
                  <!--div class="mr-2">
										<router-link to="/createrenewal"><button class="btn btn-primary btn-sm rounded-lg fs-13"><i class="mdi mdi-plus-circle-outline"></i>Create New Renewal </button></router-link>
									</div-->
                </div>
              </div>
            </div>
            <div class="">
              <div class="table-responsive">
                <table
                  id="example2"
                  class="table table-striped"
                  style="width: 100%"
                >
                  <thead>
                    <tr>
                      <th>Asset Name</th>
                      <th>Registration Number</th>
                      <th>Renewal Name</th>
                      <th>Renewal Type</th>

                      <th>Expiry Date</th>
                      <th>Renewal Cost</th>
                      <th>Driver Name</th>
                      <!-- <th>Total Amount</th> -->
                      <th>Assigned Staff</th>

                      <th>View</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="renewal in cancelledRenewals" :key="renewal.id">
                      <td>{{ renewal.assetName }}</td>
                      <td>{{ renewal.registrationNumber }}</td>
                      <td>{{ renewal.name }}</td>
                      <td>{{ renewal.renewalTypeId }}</td>

                      <td>{{ renewal.expirydate.split("T")[0] }}</td>
                      <td>{{ renewal.renewalCost }}</td>
                      <td>{{ renewal.driverName }}</td>
                      <!-- <td>{{ renewal.totalAmount }}</td> -->
                      <td>{{ renewal.staffAssignTo }}</td>

                      <td>
                        <router-link
                          :to="{
                            name: 'Edit Renewal',
                            params: { id: renewal.id },
                          }"
                          ><button class="btn btn-primary">
                            View
                          </button></router-link
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- COL END -->
      </div>
    </div>
    <!--Main Content Container-->
  </div>
  <!--Main Content-->
</template>

<script>
import $ from "jquery";
import Breadcrumb from "@/components/Breadcrumb";

export default {
  components: {
    Breadcrumb,
  },
  props: ["user", "BaseURL"],
  data() {
    return {
      renewals: [],
      cancelledRenewals: [],
      renewalType: "",
    };
  },

  methods: {
    cancel(renewal) {
      //console.log(task);
      if (
        confirm(
          "Are you sure you want to cancel this renewal? This Action Is Irrevokable!!"
        )
      ) {
        fetch(this.BaseURL + "/CancelRenewal", {
          method: "POST",
          body: JSON.stringify({
            id: renewal.id,
            submittedBy: this.user.email,
            status: "cancel",
          }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
          .then((res) => res.json())
          .then((data) => {
            console.log(data);
            this.isSuccessful = data.isSuccessful;
            this.errorMessage = data.message;

            if (this.isSuccessful == true) {
              window.location = "/renewals";
            }
          });
      }
    },
  },

  beforeCreate() {
    if (localStorage.getItem("role") !== "User") {
      window.location = "/error";
    }
  },

  mounted() {
    fetch(this.BaseURL + "/getAllRenewal", {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        //console.log(data)
        this.renewals = data;
        $(() => {
          $("#example").DataTable();
        });
      });

    fetch(this.BaseURL + "/getAllCancelRenewal", {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        //console.log(data)
        this.cancelledRenewals = data;
        $(() => {
          $("#example2").DataTable();
        });
      });

    //     fetch(this.BaseURL+"/getRenewalTypeById"+this.renewal.renewalTypeId, {
    //    headers: {'Authorization': 'Bearer '+ localStorage.getItem('token')}
    //   }).then(res =>
    //           res.json()

    //     ).then(data =>{
    //         //console.log(data)
    //         this.renewalType = data
    //         }
    //     )
  },
};
</script>