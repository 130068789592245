<template>
  <!--App Sidebar-->
  <div class="app-sidebar__overlay" data-toggle="sidebar"></div>
  <aside class="app-sidebar">
    <div class="app-sidebar__user clearfix">
      <div class="user-pro-body">
        <div class="sideuser-img">
          <img src="../assets/user.png" alt="user-img" class="" />
          <span class="sidebar-icon"></span>
        </div>
        <div class="user-info">
          <h2 class="app-sidebar__user-name">{{ user.name }}</h2>
          <span class="app-sidebar__title">{{ user.role }}</span>
        </div>
      </div>
    </div>
    <ul class="side-menu">
      <div v-if="role == 'User' || role == 'Unit Head' || role == 'Admin Head'">
        <li><h3>Main</h3></li>
        <li>
          <router-link class="side-menu__item" to="/"
            ><i class="side-menu__icon" data-eva="monitor-outline"></i
            ><span class="side-menu__label">Dashboard</span></router-link
          >
        </li>

        <li><h3>Requisition</h3></li>
        <li>
          <router-link class="side-menu__item" to="/requisition"
            ><i class="side-menu__icon" data-eva="monitor-outline"></i
            ><span class="side-menu__label">Requisition</span></router-link
          >
        </li>

        <li v-if="role == 'Unit Head'">
          <router-link class="side-menu__item" to="/unitheadapproval"
            ><i class="side-menu__icon" data-eva="monitor-outline"></i
            ><span class="side-menu__label"
              >Unit Head Approval</span
            ></router-link
          >
        </li>

        <li v-if="role == 'Admin Head'">
          <router-link class="side-menu__item" to="/adminheadapproval"
            ><i class="side-menu__icon" data-eva="monitor-outline"></i
            ><span class="side-menu__label"
              >Admin Head Approval</span
            ></router-link
          >
        </li>
        `

        <li><h3>Operations</h3></li>

        <li>
          <router-link class="side-menu__item" to="/tasks"
            ><i class="side-menu__icon" data-eva="monitor-outline"></i
            ><span class="side-menu__label">All Tasks</span></router-link
          >
        </li>

        <li>
          <router-link class="side-menu__item" to="/assets"
            ><i class="side-menu__icon" data-eva="monitor-outline"></i
            ><span class="side-menu__label">All Assets</span></router-link
          >
        </li>

        <li>
          <router-link class="side-menu__item" to="/maintenance"
            ><i class="side-menu__icon" data-eva="monitor-outline"></i
            ><span class="side-menu__label">All Maintenance</span></router-link
          >
        </li>

        <li>
          <router-link class="side-menu__item" to="/renewals"
            ><i class="side-menu__icon" data-eva="monitor-outline"></i
            ><span class="side-menu__label">All Renewals</span></router-link
          >
        </li>

        <li>
          <router-link class="side-menu__item" to="vendors"
            ><i class="side-menu__icon" data-eva="monitor-outline"></i
            ><span class="side-menu__label">Vendors</span></router-link
          >
        </li>

        <li>
          <router-link class="side-menu__item" to="/claim"
            ><i class="side-menu__icon" data-eva="monitor-outline"></i
            ><span class="side-menu__label">Claim</span></router-link
          >
        </li>

        <li>
          <router-link class="side-menu__item" to="/delivery"
            ><i class="side-menu__icon" data-eva="monitor-outline"></i
            ><span class="side-menu__label">Delivery</span></router-link
          >
        </li>

        <li>
          <router-link class="side-menu__item" to="/retirements"
            ><i class="side-menu__icon" data-eva="monitor-outline"></i
            ><span class="side-menu__label">Retirements</span></router-link
          >
        </li>

        <li><h3>Reports</h3></li>

        <li>
          <router-link class="side-menu__item" to="/assetreport"
            ><i class="side-menu__icon" data-eva="monitor-outline"></i
            ><span class="side-menu__label">Asset Reports</span></router-link
          >
        </li>

        <li>
          <router-link class="side-menu__item" to="/claimreport"
            ><i class="side-menu__icon" data-eva="monitor-outline"></i
            ><span class="side-menu__label">Claim Reports</span></router-link
          >
        </li>

        <li>
          <router-link class="side-menu__item" to="/renewalreport"
            ><i class="side-menu__icon" data-eva="monitor-outline"></i
            ><span class="side-menu__label">Renewal Reports</span></router-link
          >
        </li>

        <li>
          <router-link class="side-menu__item" to="/maintenancereport"
            ><i class="side-menu__icon" data-eva="monitor-outline"></i
            ><span class="side-menu__label"
              >Maintenance Reports</span
            ></router-link
          >
        </li>

        <li>
          <router-link class="side-menu__item" to="/deliveryreport"
            ><i class="side-menu__icon" data-eva="monitor-outline"></i
            ><span class="side-menu__label">Delivery Reports</span></router-link
          >
        </li>

        <li>
          <router-link class="side-menu__item" to="/requisitionreport"
            ><i class="side-menu__icon" data-eva="monitor-outline"></i
            ><span class="side-menu__label"
              >Requisition Reports</span
            ></router-link
          >
        </li>

        <li>
          <router-link class="side-menu__item" to="/vendorreport"
            ><i class="side-menu__icon" data-eva="monitor-outline"></i
            ><span class="side-menu__label">Vendor Reports</span></router-link
          >
        </li>

        <li>
          <router-link class="side-menu__item" to="/assetreallocationreport"
            ><i class="side-menu__icon" data-eva="monitor-outline"></i
            ><span class="side-menu__label"
              >Asset Reallocation Reports</span
            ></router-link
          >
        </li>

        <li>
          <router-link class="side-menu__item" to="/assetdisposalreport"
            ><i class="side-menu__icon" data-eva="monitor-outline"></i
            ><span class="side-menu__label"
              >Asset Disposal Reports</span
            ></router-link
          >
        </li>

        <li>
          <router-link class="side-menu__item" to="/assettransferreport"
            ><i class="side-menu__icon" data-eva="monitor-outline"></i
            ><span class="side-menu__label"
              >Asset Transfer Reports</span
            ></router-link
          >
        </li>

        <li>
          <router-link class="side-menu__item" to="/assetretirementreport"
            ><i class="side-menu__icon" data-eva="monitor-outline"></i
            ><span class="side-menu__label"
              >Asset Retirement Reports</span
            ></router-link
          >
        </li>
      </div>

      <div v-if="role == 'Admin'">
        <li><h3>Admin</h3></li>

        <li>
          <router-link class="side-menu__item" to="/users"
            ><i class="side-menu__icon" data-eva="monitor-outline"></i
            ><span class="side-menu__label">Users</span></router-link
          >
        </li>

        <li><h3>Setup</h3></li>
        <li>
          <router-link class="side-menu__item" to="/renewaltypes"
            ><i class="side-menu__icon" data-eva="monitor-outline"></i
            ><span class="side-menu__label">Renewal Types</span></router-link
          >
        </li>

        <li>
          <router-link class="side-menu__item" to="/maintenancetypes"
            ><i class="side-menu__icon" data-eva="monitor-outline"></i
            ><span class="side-menu__label"
              >Maintenance Types</span
            ></router-link
          >
        </li>

        <li>
          <router-link class="side-menu__item" to="categories"
            ><i class="side-menu__icon" data-eva="monitor-outline"></i
            ><span class="side-menu__label">Categories</span></router-link
          >
        </li>

        <li>
          <router-link class="side-menu__item" to="vendors"
            ><i class="side-menu__icon" data-eva="monitor-outline"></i
            ><span class="side-menu__label">Vendors</span></router-link
          >
        </li>

        <li>
          <router-link class="side-menu__item" to="/insurers"
            ><i class="side-menu__icon" data-eva="monitor-outline"></i
            ><span class="side-menu__label">Insurers</span></router-link
          >
        </li>

        <li>
          <router-link class="side-menu__item" to="/company"
            ><i class="side-menu__icon" data-eva="monitor-outline"></i
            ><span class="side-menu__label">Company</span></router-link
          >
        </li>

        <li>
          <router-link class="side-menu__item" to="/departments"
            ><i class="side-menu__icon" data-eva="monitor-outline"></i
            ><span class="side-menu__label">Department</span></router-link
          >
        </li>

        <li>
          <router-link class="side-menu__item" to="/auditreport"
            ><i class="side-menu__icon" data-eva="monitor-outline"></i
            ><span class="side-menu__label">Audit Reports</span></router-link
          >
        </li>
      </div>

      <div v-else>
        <li><h3>Approval</h3></li>

        <li>
          <router-link class="side-menu__item" to="/approvals"
            ><i class="side-menu__icon" data-eva="monitor-outline"></i
            ><span class="side-menu__label">Asset Approvals</span></router-link
          >
        </li>

        <li>
          <router-link class="side-menu__item" to="retirementapprovals"
            ><i class="side-menu__icon" data-eva="monitor-outline"></i
            ><span class="side-menu__label"
              >Asset Retirement Approvals</span
            ></router-link
          >
        </li>
      </div>
    </ul>
  </aside>
  <!--/App Sidebar-->
</template>

<script>
export default {
  props: ["role", "user", "BaseURL"],

  data() {
    return {};
  },

  methods: {
    // logout(){
    // 	localStorage.clear()
    // 	window.location="/"
    // }
  },

  // created() {
  //   this.role = window.localStorage.getItem("role");
  // },
};
</script>

<style scoped>
.router-link-active {
  color: brown;
}
</style>