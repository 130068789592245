<template>
  <!--Main Content-->
  <div class="main-content px-0 app-content">
    <!--Main Content Container-->
    <div class="container-fluid">
      <!--Page Header-->
      <Breadcrumb />
      <!--Page Header-->
      <div class="forms card rounded">
        <form @submit.prevent="send()">
          <label>From :</label>
          <input
            type="text"
            :value="user.email"
            class="form-control"
            disabled
          />

          <label>To :</label>
          <input type="text" v-model="toAddress" class="form-control" />

          <label>Cc :</label>
          <input type="text" v-model="copyEmail" class="form-control" />

          <label>Mail Subject :</label>
          <input type="text" v-model="mailSubject" class="form-control" />

          <label>Email Body :</label>
          <textarea
            v-model="body"
            class="form-control mb-4"
            rows="10"
          ></textarea>
          <button
            v-if="loading == true"
            type="submit"
            class="btn btn-primary"
            disabled
          >
            Loading
            <div class="spinner-border spinner-border-sm" role="status">
              <span style="width: 2px" class="sr-only text-light"
                >Loading...</span
              >
            </div>
          </button>
          <button v-else type="submit" class="btn btn-primary">
            Send Mail
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";

export default {
  components: {
    Breadcrumb,
  },

  props: ["user", "BaseURL"],

  data() {
    return {
      loading: false,
      toAddress: "",
      copyEmail: "",
      fromAddress: this.user.email,
      body: "",
      isHtml: true,
      mailSubject: "",

      claim: {},
      document: "",
      name: "",

      isSuccessful: "",
      errorMessage: "",

      closed: false,
    };
  },

  created() {
    fetch(this.BaseURL + "/getClaimById" + this.$route.params.id, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        this.claim = data;
      });
  },

  methods: {
    send() {
      this.loading = true;

      fetch(this.BaseURL + "/send", {
        method: "POST",
        body: JSON.stringify({
          toAddress: this.toAddress,
          copyEmail: this.copyEmail,
          fromAddress: this.fromAddress,
          body:
            this.body +
            "<br/><br/> Claim Document URL : " +
            "<a href='https://chiadminapi.chiplc.com/IMAGE/" +
            this.claim.claimFormUrl +
            "'> View Document</a>",
          isHtml: true,
          mailSubject: this.mailSubject,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          this.isSuccessful = data.isSuccessful;
          this.errorMessage = data.message;

          if (this.isSuccessful == true) {
            this.loading = false;
            alert("Mail Sent");
            window.location = "/claim";
          }
        });
    },
  },
};
</script>