<template>
  <div
    v-bind:class="[closed ? 'close' : '']"
    class="mb-4 bg-warning p-3 text-dark"
    v-if="isSuccessful === false"
  >
    <b>Error : {{ errorMessage }}</b>
    <i @click="close()" class="fas fa-close"></i>
  </div>
  <div v-else></div>
  <form @submit.prevent="createAsset()">
    <label>Asset Name</label>
    <input type="text" v-model="name" class="form-control" required />

    <label>Asset Category</label>
    <select v-model="categoryId" class="form-control mb-3">
      <option selected>Select Category</option>
      <option
        v-for="category in categories"
        :key="category.id"
        :value="category.id"
      >
        {{ category.name }}
      </option>
    </select>

    <!--
    <label>Asset Code</label>
    <input type="text" v-model="code" class="form-control" required />

     
    <label>Asset Tag Number</label>
    <input type="text" v-model="tagNumber" class="form-control" required> -->

    <!--label>Days of Service</label>
    <input
      type="number"
      
      class="form-control"
      required
    /-->

    <label>Asset Purchase Date</label>
    <input
      type="date"
      v-model="purchaseDate"
      placeholder="dd/mm/yyyy"
      class="form-control"
      required
    />

    <label>Asset Vendor</label>
    <select v-model="vendor" class="form-control mb-3">
      <option selected>Select Vendor</option>
      <option v-for="vendor in vendors" :key="vendor.id" :value="vendor.name">
        {{ vendor.name }}
      </option>
    </select>

    <label>Asset Invoice Number</label>
    <input type="text" v-model="invoiceNo" class="form-control" required />

    <label>Asset Purchase Amount</label>
    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <span class="input-group-text">₦</span>
      </div>

      <input
        type="text"
        v-model="purchaseAmount"
        class="form-control"
        required
        aria-label="Amount (to the nearest naira)"
      />
      <div class="input-group-append">
        <span class="input-group-text">.00</span>
      </div>
    </div>

    <label>Mode Of Payment</label>
    <select v-model="modeOfPayment" class="form-control mb-3" required>
      <option selected>Select Payment Method</option>
      <option value="Transfer">Transfer</option>
      <option value="Cash">Cash</option>
    </select>

    <!--label>Total Sum</label>
    <input v-model="approvedAmount" type="number" class="form-control"-->

    <div class="form-check mt-4 mb-4">
      <input
        class="form-check-input"
        type="checkbox"
        v-model="maintenance"
        :value="true"
        id="maintenance"
      />
      <label class="form-check-label" for="flexCheckDefault">
        Maintenance
      </label>
    </div>

    <div class="form-check mb-4">
      <input
        class="form-check-input"
        type="checkbox"
        v-model="renewal"
        :value="true"
        id="renewal"
      />
      <label class="form-check-label" for="flexCheckDefault"> Renewal </label>
    </div>

    <button v-if="loading" type="submit" class="btn btn-primary" disabled>
      Loading
      <div class="spinner-border spinner-border-sm" role="status">
        <span style="width: 2px" class="sr-only text-light">Loading...</span>
      </div>
    </button>
    <button v-else type="submit" class="btn btn-primary">Save</button>

    <button @click.prevent="reset()" class="btn btn-warning mx-3">
      Cancel
    </button>
  </form>
</template>
<script>
//import $ from "jquery";
//import accounting from "accounting-js";
export default {
  components: {},

  props: ["user", "BaseURL"],

  data() {
    return {
      loading: false,
      name: "",
      daysOfService: "",
      code: 0,
      tagNumber: "None",
      purchaseDate: "",
      invoiceNo: "",
      purchaseAmount: "",
      vendor: "Select Vendor",
      categoryId: "Select Category",
      modeOfPayment: "Select Payment Method",
      approvedAmount: "",
      maintenance: false,
      renewal: false,

      categories: [],
      vendors: [],

      isSuccessful: "",
      errorMessage: "",

      closed: false,
    };
  },

  created() {
    // console.log(
    //   new Intl.NumberFormat("en-IN", {
    //     style: "currency",
    //     currency: "NGN",
    //   }).format(5000000)
    // );
    //console.log(new Intl.NumberFormat().format(50000000000));
    // console.log(accounting.formatMoney(12345678));
  },

  mounted() {
    fetch(this.BaseURL + "/getAllCategory", {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        //console.log(data)
        this.categories = data;
      });

    fetch(this.BaseURL + "/getVendorDropDown", {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        //console.log(data)
        this.vendors = data;
      });
  },

  methods: {
    reset() {
      window.location.reload(true);
    },
    format() {
      this.purchaseAmount = new Intl.NumberFormat().format(
        parseInt(this.purchaseAmount.replace(",", ""))
      );
    },
    createAsset() {
      this.loading = true;
      fetch(this.BaseURL + "/addAsset", {
        method: "POST",
        body: JSON.stringify({
          name: this.name,
          daysofService: 0,
          purchaseDate: this.purchaseDate,
          code: "0",
          invoiceNo: this.invoiceNo,
          tagNumber: this.tagNumber,
          modeOfPayment: this.modeOfPayment,
          vendor: this.vendor,
          purchaseAmount: parseInt(this.purchaseAmount.replace(",", "")),
          approvedAmount: 0,
          maintenance: this.maintenance,
          renewal: this.renewal,
          categoryId: this.categoryId,
          submittedBy: this.user.email,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log(data);
          this.isSuccessful = data.isSuccessful;
          this.errorMessage = data.message;

          if (this.isSuccessful == true) {
            alert("Asset Successfully Created");
            window.location = "/assets";
            this.loading = false;
          } else {
            this.loading = false;
            alert("Error : " + this.errorMessage);
          }
        });
    },
  },

  computed: {
    // ifMaintenance(){
    //  return (this.maintenance == 'Maintenance'? true : false)
    // },
    // ifRenewal(){
    //  return (this.maintenance == 'Renewal'? true : false)
    // }
  },
};
</script>

