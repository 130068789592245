<template>
  <!--Main Content-->
  <div class="main-content px-0 app-content">
    <!--Main Content Container-->
    <div class="container-fluid">
      <!--Page Header-->
      <Breadcrumb />
      <!--Page Header-->
      <div class="forms card rounded">
        <form @submit.prevent="updateMaintenance">
          <!-- <label>Maintenance Id</label>
          <input
            type="text"
            :value="maintenance.id"
            class="form-control"
            disabled
          /> -->

          <label>Asset Name</label>
          <input
            type="text"
            :value="maintenance.assetName"
            class="form-control"
            disabled
          />

          <label>Maintenance Type</label>
          <select
            v-model="maintenance.maintenanceTypeId"
            class="form-control mb-3"
          >
            <option selected>Select Category</option>
            <option
              v-for="maintenanceType in maintenanceTypes"
              :key="maintenanceType.id"
              :value="maintenanceType.id"
            >
              {{ maintenanceType.name }}
            </option>
          </select>

          <label>Maintenance Name</label>
          <input
            type="text"
            v-model="maintenance.name"
            class="form-control"
            required
          />

          <label>Registration Number</label>
          <input
            type="text"
            v-model="maintenance.registrationNumber"
            class="form-control"
          />

          <label>Make</label>
          <input type="text" v-model="maintenance.make" class="form-control" />

          <label>Maintenance Due Date </label>
          <input
            type="date"
            v-model="maintenance.maintenanceDueDate.split('T')[0]"
            class="form-control"
            disabled
          />
          <label>Set New Due Date </label>
          <input
            type="date"
            v-model="maintenance.maintenanceDueDate"
            class="form-control"
          />

          <label>Speedometer</label>
          <input
            type="number"
            v-model="maintenance.speedometre"
            class="form-control"
          />

          <label>Description Of Maintenance</label>
          <textarea
            v-model="maintenance.descriptionofMaintenance"
            class="form-control mb-3"
            rows="3"
          ></textarea>

          <!-- <label>Maintenance Frequency</label>
          <select v-model="maintenance.frequency" class="form-control mb-3">
             <option selected>{{claim.insurerName}}</option> 
            <option value="Recurring">Recurring</option>

            <option value="One Off">One Off</option>
          </select> -->

          <label>Mentainance Cost</label>
          <input
            type="number"
            v-model="maintenance.maintenanceCost"
            class="form-control"
          />

          <label>Workshop Manager</label>
          <input
            type="text"
            v-model="maintenance.workshopManager"
            class="form-control"
          />

          <label>Driver's Name</label>
          <input
            type="text"
            v-model="maintenance.driverName"
            class="form-control"
          />

          <label>Identity Card</label>
          <input
            type="text"
            v-model="maintenance.identityCard"
            class="form-control"
          />

          <label>Identity Card Number</label>
          <input
            type="text"
            v-model="maintenance.identityCardNo"
            class="form-control"
          />

          <label>Date</label>
          <input
            type="date"
            v-model="maintenance.date.split('T')[0]"
            class="form-control"
          />

          <label>User (Staff) Name</label>
          <input
            type="text"
            v-model="maintenance.staffAssignTo"
            class="form-control"
          />

          <!-- <label>Total Amount</label>
          <input
            type="number"
            v-model="maintenance.totalAmount"
            class="form-control"
          /> -->

          <!--label>Designation</label>
    <input type="text" v-model="designation" class="form-control">

    <label>Head Of Admin Signature</label>
    <input type="text" v-model="name" class="form-control" required>

    <label>Signature</label>
    <input type="text" v-model="signature" class="form-control"-->

          <label>Head Of Admin Comments</label>
          <textarea
            v-model="maintenance.commentsByHeadofAdmin"
            class="form-control mb-3"
            rows="3"
          ></textarea>

          <div class="row">
            <div class="col-4">
              <label>Uploaded Signed Document</label>
              <div class="mb-3">
                <a
                  v-if="maintenance.uploadcopyofsignedform == null"
                  class="btn btn-info text-light disabled"
                  >View Document</a
                >
                <a
                  v-else
                  class="text-light btn btn-info"
                  :href="
                    'https://chiadminapi.chiplc.com/IMAGE/' +
                    maintenance.uploadcopyofsignedform
                  "
                  target="_blank"
                  >View Document</a
                >
              </div>
            </div>

            <div class="col-4">
              <label>Upload New Signed Document</label>
              <div class="mb-3">
                <input
                  type="file"
                  v-on:change="handleFileUpload($event)"
                  class="dropify"
                  data-height="180"
                />
              </div>
            </div>
          </div>

          <br /><br />
          <button v-if="loading == true" class="btn btn-primary" disabled>
            Loading
            <div class="spinner-border spinner-border-sm" role="status">
              <span style="width: 2px" class="sr-only text-light"
                >Loading...</span
              >
            </div>
          </button>
          <button
            v-else-if="maintenance.status != 'cancel'"
            type="submit"
            class="btn btn-primary"
          >
            Update Maintenance</button
          ><br />
          <br />

          <!-- <button
            @click="printDiv('invoice')"
            type="button"
            class="ml-4 btn btn-primary"
          >
            Generate Invoice</button
          -->

          <span>
            1. The work or services should be carried out strictly according to
            instructions given and the Company reserves the right to reject the
            work/services if it does not conform with the report form.
            <br />2. Any variation of this report shall be binding only if made
            in writing and signed by Head of Administration.
          </span>
        </form>

        <div id="invoice">
          <h3>CHI Assets Maintenance Invoice</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";

export default {
  components: {
    Breadcrumb,
  },

  props: ["user", "BaseURL"],

  data() {
    return {
      loading: false,
      signedFormFile: null,
      maintenance: {},
      maintenanceTypes: [],

      isSuccessful: "",
      errorMessage: "",

      closed: false,
    };
  },

  methods: {
    // printDiv(divName) {
    //   var printContents = document.getElementById(divName).innerHTML;
    //   var originalContents = document.body.innerHTML;

    //   document.body.innerHTML = printContents;

    //   window.print();

    //   document.body.innerHTML = originalContents;
    // },

    handleFileUpload(e) {
      this.signedFormFile = e.target.files[0];
    },

    updateMaintenance() {
      this.loading = true;
      let formData = new FormData();
      formData.append("FormFile", this.signedFormFile);
      formData.append("Id", this.maintenance.id);
      formData.append("Name", this.maintenance.name);
      formData.append("SubmittedBy", this.user.email);
      formData.append("AssetId", this.maintenance.assetId);
      formData.append("MaintenanceTypeId", this.maintenance.maintenanceTypeId);
      formData.append(
        "RegistrationNumber",
        this.maintenance.registrationNumber
      );
      formData.append(
        "MaintenanceDueDate",
        this.maintenance.maintenanceDueDate
      );
      formData.append("Speedometre", this.maintenance.speedometre);
      formData.append("Make", this.maintenance.make);
      formData.append("TotalAmount", this.maintenance.totalAmount);
      formData.append(
        "DescriptionofMaintenance",
        this.maintenance.descriptionofMaintenance
      );
      formData.append(
        "MaintenanceCost",
        parseInt(this.maintenance.maintenanceCost)
      );
      formData.append("WorkshopManager", this.maintenance.workshopManager);
      formData.append("DriverName", this.maintenance.driverName);
      formData.append("IdentityCard", this.maintenance.identityCard);
      formData.append("IdentityCardNo", this.maintenance.identityCardNo);
      formData.append("Date", this.maintenance.date);
      formData.append("StaffAssignTo", this.maintenance.staffAssignTo);
      formData.append(
        "CommentsByHeadofAdmin",
        this.maintenance.commentsByHeadofAdmin
      );
      formData.append(
        "Uploadcopyofsignedform",
        this.maintenance.uploadcopyofsignedform
      );
      formData.append("TermsConditions", this.maintenance.termsConditions);
      formData.append("Frequency", this.maintenance.frequency);

      fetch(this.BaseURL + "/updateMaintenance", {
        method: "POST",
        body: formData,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          this.isSuccessful = data.isSuccessful;
          this.errorMessage = data.message;

          if (this.isSuccessful == true) {
            alert("Maintenance Edited successfully");
            this.loading = false;
            window.location = "/maintenance";
          } else {
            this.loading = false;
            alert("Error : " + this.errorMessage);
          }
        });
    },

    close() {
      this.closed = !this.closed;
    },
  },

  beforeCreate() {
    if (localStorage.getItem("role") !== "User") {
      window.location = "/error";
    }
  },

  created() {
    fetch(this.BaseURL + "/getMaintenanceById" + this.$route.params.id, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        //console.log(data)
        this.maintenance = data;
      });

    fetch(this.BaseURL + "/getAllMaintenanceType", {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        //console.log(data)
        this.maintenanceTypes = data;
      });
  },
};
</script>

<style scoped>
#invoice {
  display: none;
}
</style>