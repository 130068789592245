<template>
  <form @submit.prevent="createTask()">
    <label>Select Asset </label>
    <select v-model="assetID" class="form-control mb-3">
      <option selected>Select Asset</option>
      <option v-for="asset in assets" :key="asset.id" :value="asset.id">
        {{ asset.name }}
      </option>
    </select>

    <label>Task Name</label>
    <input type="text" v-model="name" class="form-control" required />

    <label>Description </label>
    <textarea
      v-model="description"
      class="form-control mb-3"
      rows="3"
    ></textarea>

    <label>Due Date</label>
    <input id="date" type="date" v-model="date" class="form-control" required />

    <button v-if="loading == true" class="btn btn-primary" disabled>
      Loading
      <div class="spinner-border spinner-border-sm" role="status">
        <span style="width: 2px" class="sr-only text-light">Loading...</span>
      </div>
    </button>

    <button v-else type="submit" class="btn btn-primary">Submit</button>
  </form>
</template>

<script>
import $ from "jquery";
export default {
  props: ["user", "BaseURL"],

  data() {
    return {
      loading: false,
      name: "",
      description: "",
      date: "",
      assetID: "Select Asset",
      assets: [],

      isSuccessful: "",
      errorMessage: "",

      closed: false,

      renewalTypes: [],
    };
  },

  created() {
    $(function () {
      var dtToday = new Date();

      var month = dtToday.getMonth() + 1;
      var day = dtToday.getDate();
      var year = dtToday.getFullYear();
      if (month < 10) month = "0" + month.toString();
      if (day < 10) day = "0" + day.toString();

      var maxDate = year + "-" + month + "-" + day;
      //alert(maxDate);
      $("#date").attr("min", maxDate);
    });

    fetch(this.BaseURL + "/getAllAsset", {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        //console.log(data)
        this.assets = data;
      });
  },

  methods: {
    createTask() {
      this.loading = true;
      // console.log({
      //   name: this.name,
      //   assetId: this.assetID,
      //   description: this.description,
      //   submittedBy: this.user.email,
      //   dueDate: this.date,
      // });
      fetch(this.BaseURL + "/addTask", {
        method: "POST",
        body: JSON.stringify({
          name: this.name,
          assetId: this.assetID,
          description: this.description,
          submittedBy: this.user.email,
          dueDate: this.date,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log(data);
          this.isSuccessful = data.isSuccessful;
          this.errorMessage = data.message;
          console.log(data);
          if (this.isSuccessful == true) {
            alert("Your New Task ( " + this.name + " ) Has Been Created");
            this.loading = false;
            window.location = "/tasks";
          } else {
            this.loading = false;
            alert("Error : " + this.errorMessage);
          }
        });
    },
  },
};
</script>

