<template>
  <!--Main Content-->
  <div class="main-content px-0 app-content">
    <!--Main Content Container-->
    <div class="container-fluid">
      <!--Page Header-->
      <Breadcrumb />
      <!--Page Header-->
      <div class="forms card rounded">
        <div
          v-bind:class="[closed ? 'close' : '']"
          class="mb-4 bg-warning p-3 text-dark"
          v-if="isSuccessful === false"
        >
          <b>Error : {{ errorMessage }}</b>
          <i @click="close()" class="fas fa-close"></i>
        </div>
        <div v-else></div>

        <form @submit.prevent="createDelivery()">
          <label>Mail Type</label>
          <select v-model="MailType" class="form-control mb-3" required>
            <option selected>Select Mail Type</option>
            <option value="Internal">Internal</option>
            <option value="External">External</option>
          </select>

          <!-- <label>Mail Type</label>
                <input  type="text" class="form-control" required> -->

          <label>Item</label>
          <input v-model="Item" type="text" class="form-control" required />

          <label>Reciever Name</label>
          <input
            v-model="ReceiverName"
            type="text"
            class="form-control"
            required
          />

          <div>
            <label>Reciever Address</label>
            <input
              v-model="ReceiverAddress"
              type="text"
              class="form-control"
              required
            />
          </div>

          <label>Recipient Department</label>
          <input v-model="Department" type="text" class="form-control" />

          <label>Courier</label>
          <input v-model="Courier" type="text" class="form-control" required />

          <label>Delivery Status</label>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              value="Not Delivered"
              v-model="DeliveryStatus"
              name="deliveryStatus"
              id=""
              required
            />
            <label class="form-check-label" for=""> Not Delivered </label>
          </div>
          <div class="form-check mb-4">
            <input
              class="form-check-input"
              type="radio"
              value="Delivered"
              v-model="DeliveryStatus"
              name="deliveryStatus"
              id=""
              required
            />
            <label class="form-check-label" for=""> Delivered </label>
          </div>

          <!-- <label>Delivery Status</label>
          <input
            v-model="DeliveryStatus"
            type="text"
            class="form-control"
            required
          /> -->

          <label>Delivery Date</label>
          <input v-model="date" type="date" class="form-control" required />

          <label>Acknowledgement FIle</label>
          <div class="">
            <input
              type="file"
              v-on:change="handleFileUpload($event)"
              class="dropify"
              data-height="180"
            />
          </div>

          <button v-if="loading == true" class="btn btn-primary" disabled>
            Loading
            <div class="spinner-border spinner-border-sm" role="status">
              <span style="width: 2px" class="sr-only text-light"
                >Loading...</span
              >
            </div>
          </button>

          <button v-else type="submit" class="btn btn-primary">Submit</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";

export default {
  components: {
    Breadcrumb,
  },

  props: ["user", "BaseURL"],
  data() {
    return {
      loading: false,
      MailType: "Select Mail Type",
      Item: "",
      ReceiverName: "",
      ReceiverAddress: "",
      Department: "",
      Courier: "",
      DeliveryStatus: "",
      date: "",
      document: "",

      isSuccessful: "",
      errorMessage: "",

      closed: "",
    };
  },

  computed: {
    address() {
      if (this.MailType == "Internal") {
        return "266 Ikorodu Road, Obanikoro, Lagos.";
      }
      return this.ReceiverAddress;
    },
  },
  beforeCreate() {
    if (localStorage.getItem("role") !== "User") {
      window.location = "/error";
    }
  },

  methods: {
    handleFileUpload(e) {
      this.document = e.target.files[0];
    },

    createDelivery() {
      this.loading = true;
      let formData = new FormData();
      formData.append("FormFile", this.document);
      formData.append("MailType", this.MailType);
      formData.append("Item", this.Item);
      formData.append("ReceiverName", this.ReceiverName);
      formData.append("ReceiverAddress", this.ReceiverAddress);
      formData.append("Department", this.Department);
      formData.append("Courier", this.Courier);
      formData.append("DeliveryStatus", this.DeliveryStatus);
      formData.append("AcknowledgementURL", "");
      formData.append("SubmittedBy", this.user.email);
      formData.append("Date", this.date);

      fetch(this.BaseURL + "/addDelivery", {
        method: "POST",
        body: formData,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((res) => res.json())
        .then((data) => {
          this.isSuccessful = data.isSuccessful;
          this.errorMessage = data.message;

          if (this.isSuccessful == true) {
            this.loading = false;
            window.location = "/delivery";
          } else {
            this.loading = false;
            alert(`Error : ${this.errorMessage}`);
          }
        });
    },

    close() {
      this.closed = true;
    },
  },
};
</script>