<template>
  <div class="container-fluid text-center">
    <div class="main-content px-0 app-content">
      <h1 class="pt-5">ERROR 404</h1>
      <p class="font-weight-bolder fs-1">
        You Are Not Allowed Access To This Page
        <br />
        <br />
        <button class="btn btn-primary" @click.prevent="$router.go(-1)">
          Go Back
        </button>
      </p>

      <img
        style="width: 40%"
        class="mt-5"
        src="https://assets.materialup.com/uploads/26541cce-49c6-4e35-a055-e11b90ffad68/preview.gif"
      />
    </div>
  </div>
</template>