<template>
  <form @submit.prevent="claim()">
    <!-- <h3 class="mb-4">Asset Claim</h3> -->

    <label>Select Asset To CLaim</label>
    <select @change="getAsset()" v-model="assetID" class="form-control mb-3">
      <option selected>Select Asset</option>
      <option v-for="asset in assets" :key="asset.id" :value="asset.id">
        {{ asset.name }} ({{ asset.tagNumber }})
      </option>
    </select>

    <label>Asset ID </label>
    <input type="text" :value="assetID" class="form-control" disabled />
    <label>Asset Tag No </label>
    <input type="text" :value="asset.tagNumber" class="form-control" disabled />

    <label>Asset Purchase Amount</label>
    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <span class="input-group-text">₦</span>
      </div>

      <input
        type="text"
        :value="asset.purchaseAmount"
        class="form-control"
        disabled
      />

      <div class="input-group-append">
        <span class="input-group-text">.00</span>
      </div>
    </div>

    <div class="mb-4">
      <label class="mg-b-10">Insurer</label>
      <select v-model="insurerID" class="form-control mb-3">
        <option selected>Select Insurer</option>
        <option
          v-for="insurer in insurers"
          :key="insurer.id"
          :value="insurer.id"
        >
          {{ insurer.name }}
        </option>
      </select>
    </div>

    <p class="mg-b-20">Claim Form</p>
    <div class="">
      <input
        type="file"
        @change="handleFileUpload($event)"
        class="dropify"
        data-height="180"
        required
      />
    </div>
    <button v-if="loading" class="btn btn-primary" disabled>
      Loading
      <div class="spinner-border spinner-border-sm" role="status">
        <span style="width: 2px" class="sr-only text-light">Loading...</span>
      </div>
    </button>
    <button v-else type="submit" class="btn btn-primary">Submit</button>
  </form>
</template>

<script>
export default {
  props: ["user", "BaseURL"],

  data() {
    return {
      loading: false,
      assets: [],
      asset: {},
      insurers: [],

      assetID: "Select Asset",
      insurerID: "",
      claimForm: "",

      isSuccessful: "",
      errorMessage: "",

      closed: false,

      signedFormFile: "",
    };
  },

  created() {
    fetch(this.BaseURL + "/getAllAsset", {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        //console.log(data);
        this.assets = data;
      });

    fetch(this.BaseURL + "/getAllInsurer", {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        //console.log(data);
        this.insurers = data;
      });
  },

  methods: {
    getAsset() {
      fetch(this.BaseURL + "/getAssetById" + this.assetID, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((res) => res.json())
        .then((data) => {
          //console.log(data)
          this.asset = data;
        });
    },
    handleFileUpload(e) {
      this.claimForm = e.target.files[0];
    },

    claim() {
      this.loading = true;
      let formData = new FormData();
      formData.append("FormFile", this.claimForm);
      formData.append("AssetId", this.assetID);
      formData.append("InsurerId", this.insurerID);
      formData.append("ClaimFormUrl", "");
      formData.append("SubmittedBy", this.user.email);

      fetch(this.BaseURL + "/addClaim", {
        method: "POST",
        body: formData,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((res) => res.json())
        .then((data) => {
          //console.log(data);
          this.isSuccessful = data.isSuccessful;
          this.errorMessage = data.message;

          if (this.isSuccessful == true) {
            this.loading = false;
            window.location.reload();
          }
        });
    },
  },
};
</script>
