<template>
  <!--Main Content-->
  <div class="main-content px-0 app-content">
    <!--Main Content Container-->
    <div class="container-fluid">
      <!--Page Header-->
      <Breadcrumb />
      <!--Page Header-->
      <div class="forms card rounded">
        <div
          v-bind:class="[closed ? 'close' : '']"
          class="mb-4 bg-warning p-3 text-dark"
          v-if="isSuccessful === false"
        >
          <b>Error : {{ errorMessage }}</b>
          <i @click="close()" class="fas fa-close"></i>
        </div>
        <div v-else></div>

        <form @submit.prevent="createRequisition()">
          <label>Requisition Type</label>
          <select v-model="type" class="form-control mb-3" required>
            <option value="" selected>Select Type</option>
            <option value="Stationary Requisition">
              Stationary Requisition
            </option>
            <option value="ICT Consumable Request">
              ICT Consumable Request
            </option>
            <option value="Device Request">Device Request</option>
          </select>

          <label>Select Requesting Company</label>
          <select
            @change="fetchDepartments()"
            v-model="companyId"
            class="form-control mb-3"
            required
          >
            <option value="" selected>Select Company</option>
            <option
              v-for="company in groupOfCompany"
              :key="company.id"
              :value="company.id"
            >
              {{ company.name }}
            </option>
          </select>

          <label>Select Requesting Department</label>
          <select v-model="departmentId" class="form-control mb-3" required>
            <option value="" selected>Select Department</option>
            <option
              v-for="department in departments"
              :key="department.id"
              :value="department.id"
            >
              {{ department.name }}
            </option>
          </select>

          <label>Request Description</label>
          <textarea
            v-model="description"
            class="form-control mb-3"
            rows="3"
            required
          ></textarea>

          <label>Date Of Requisition</label>
          <input v-model="date" type="date" class="form-control" required />

          <label>Serial Number</label>
          <input v-model="serialNumber" type="number" class="form-control" />

          <label>Code No</label>
          <input v-model="codeno" type="text" class="form-control" />

          <label>Quantity Requested</label>
          <input
            v-model="quantityRequired"
            type="number"
            class="form-control"
            required
          />

          <label>Quantity Issued</label>
          <input
            v-model="quantitySupplied"
            type="number"
            class="form-control"
          />

          <label>Requisition By</label>
          <input
            :value="user.email"
            type="text"
            class="form-control"
            disabled
          />

          <label>Recieved By</label>
          <input v-model="receivedBy" type="text" class="form-control" />

          <label>Remark</label>
          <input v-model="remark" type="text" class="form-control" />

          <button v-if="loading == true" class="btn btn-primary" disabled>
            Loading
            <div class="spinner-border spinner-border-sm" role="status">
              <span style="width: 2px" class="sr-only text-light"
                >Loading...</span
              >
            </div>
          </button>

          <button v-else type="submit" class="btn btn-primary">
            Create Requisition
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";

export default {
  components: {
    Breadcrumb,
  },

  props: ["user", "BaseURL"],
  data() {
    return {
      groupOfCompany: [],
      departments: [],

      companyId: "",
      departmentId: "",
      type: "",
      serialNumber: "",
      date: "",
      description: "",
      quantityRequired: "",
      codeno: "",
      quantitySupplied: 0,
      remark: null,
      receivedBy: null,

      loading: false,
      isSuccessful: "",
      errorMessage: "",

      closed: "",
    };
  },

  created() {
    fetch(this.BaseURL + "/getAllCompany", {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        //console.log(data)
        this.groupOfCompany = data;
      });
  },

  methods: {
    fetchDepartments() {
      fetch(this.BaseURL + "/getDepartmentDD?CompanyId=" + this.companyId, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((res) => res.json())
        .then((data) => {
          //console.log(data)
          this.departments = data;
        });
    },

    createRequisition() {
      this.loading = true;
      fetch(this.BaseURL + "/addStationeryRequisitionICTConsumableDevice", {
        method: "POST",
        body: JSON.stringify({
          companyId: this.companyId,
          departmentId: this.departmentId,
          type: this.type,
          serialNumber: this.serialNumber,
          date: this.date,
          description: this.description,
          quantityRequired: this.quantityRequired,
          codeno: this.codeno,
          quantitySupplied: this.quantitySupplied,
          remark: this.remark,
          requisitionBy: this.user.email,
          receivedBy: this.receivedBy,
          submittedBy: this.user.email,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((data) => {
          this.isSuccessful = data.isSuccessful;
          this.errorMessage = data.message;

          if (this.isSuccessful == true) {
            alert("Requisition Created Successfully");
            this.loading = false;
            window.location = "/requisition";
          } else {
            this.loading = false;
            alert(`Error : ${this.errorMessage}`);
          }
        });
    },

    close() {
      this.closed = true;
    },
  },
};
</script>