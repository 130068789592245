<template>
  <div
    v-bind:class="[closed ? 'close' : '']"
    class="mb-4 bg-warning p-3 text-dark"
    v-if="isSuccessful === false"
  >
    <b>Error : {{ errorMessage }}</b>
    <i @click="close()" class="fas fa-close"></i>
  </div>
  <div v-else></div>
  <form @submit.prevent="register()">
    <label>Name</label>
    <input v-model="name" type="text" class="form-control" required />

    <label>Email</label>
    <input v-model="email" type="text" class="form-control" required />

    <label>UserName</label>
    <input v-model="userName" type="text" class="form-control" required />

    <label>Phone Number</label>
    <input v-model="phoneNumber" type="tel" class="form-control" required />

    <div class="mb-4">
      <p class="mg-b-10">Role</p>
      <select v-model="role" class="form-control select2-no-search" required>
        <option label="Choose one"></option>
        <option value="Admin">Admin</option>
        <option value="User">User</option>
      </select>
    </div>

    <button
      v-if="loading == true"
      type="submit"
      class="btn btn-main-primary btn-block"
      disabled
    >
      Loading
      <div class="spinner-border spinner-border-sm" role="status">
        <span style="width: 2px" class="sr-only text-light">Loading...</span>
      </div>
    </button>

    <button v-else type="submit" class="btn btn-primary">
      Create {{ role }}
    </button>
  </form>
</template>

<style scoped>
i {
  float: right;
}

i:hover {
  cursor: pointer;
}

.close {
  display: none;
}
</style>

<script>
export default {
  props: ["BaseURL"],
  data() {
    return {
      loading: false,

      name: "",
      email: "",
      userName: "",
      phoneNumber: "",
      role: "",

      isSuccessful: "",
      errorMessage: "",

      closed: "",
    };
  },

  methods: {
    register() {
      this.loading = true;
      fetch(this.BaseURL + "/registerUser", {
        method: "POST",
        body: JSON.stringify({
          name: this.name,
          email: this.email,
          userName: this.userName,
          phoneNumber: this.phoneNumber,
          submittedBy: this.email,
          role: this.role,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((data) => {
          this.isSuccessful = data.isSuccessful;
          this.errorMessage = data.message;

          if (this.isSuccessful == true) {
            alert(this.role + " Created");
            this.loading = false;
            window.location = "/users";
          }
        });
    },

    close() {
      this.closed = true;
    },
  },
};
</script>

