<template>
  <!--Main Content-->
  <div class="main-content px-0 app-content">
    <!--Main Content Container-->
    <div class="container-fluid">
      <!--Page Header-->
      <Breadcrumb />
      <!--Page Header-->
      <div class="forms card rounded">
        <div
          v-bind:class="[closed ? 'close' : '']"
          class="mb-4 bg-warning p-3 text-dark"
          v-if="isSuccessful === false"
        >
          <b>Error : {{ errorMessage }}</b>
          <i @click="close()" class="fas fa-close"></i>
        </div>
        <div v-else></div>

        <div class="bg-primary text-light mb-4 pt-3 pl-2">
          <P><b>Basic Details Of Asset</b></P>
        </div>

        <form class="p-3">
          <label>Asset Name</label>
          <input
            type="text"
            v-model="asset.name"
            class="form-control"
            disabled
          />

          <!--<label>Asset Code</label>
          <input
            type="text"
            v-model="asset.code"
            class="form-control"
            disabled
          />-->

          <label>Asset Tag Number</label>
          <input
            type="text"
            v-model="asset.tagNumber"
            class="form-control"
            disabled
          />

          <label>Days of Service</label>
          <input
            type="number"
            v-model="asset.daysofService"
            class="form-control"
            disabled
          />

          <label
            >Asset Purchase Date ({{ asset.purchaseDate.split("T")[0] }})</label
          >

          <input
            type="date"
            v-model="asset.purchaseDate.split('T')[0]"
            class="form-control"
            disabled
          />

          <!-- v-model="asset.purchaseDate.split('T')[0]" -->

          <label>Asset Invoice Number</label>
          <input
            type="text"
            v-model="asset.invoiceNo"
            class="form-control"
            disabled
          />

          <label>Asset Purchase Amount</label>
          <input
            type="number"
            v-model="asset.purchaseAmount"
            class="form-control"
            disabled
          />

          <label>Asset Vendor</label>
          <input
            type="text"
            v-model="asset.vendor"
            class="form-control"
            disabled
          />

          <label>Asset Category</label>
          <select v-model="asset.categoryId" class="form-control mb-3" disabled>
            <option selected>Select Category</option>
            <option
              v-for="category in categories"
              :key="category.id"
              :value="category.id"
            >
              {{ category.name }}
            </option>
          </select>

          <label>Mode Of Payment</label>
          <select
            v-model="asset.modeOfPayment"
            class="form-control mb-3"
            disabled
          >
            <option selected>Select Payment Method</option>
            <option value="Transfer">Transfer</option>
            <option value="Cash">Cash</option>
          </select>

          <label>Total Sum</label>
          <input
            v-model="asset.approvedAmount"
            type="number"
            class="form-control"
            disabled
          />

          <div class="form-check mt-4 mb-4">
            <input
              class="form-check-input"
              type="checkbox"
              v-model="asset.maintenance"
              id="maintenance"
              disabled
            />
            <label class="form-check-label" for="flexCheckDefault">
              Maintenance
            </label>
          </div>

          <div class="form-check mb-4">
            <input
              class="form-check-input"
              type="checkbox"
              v-model="asset.renewal"
              id="renewal"
              disabled
            />
            <label class="form-check-label" for="flexCheckDefault">
              Renewal
            </label>
          </div>
        </form>
      </div>

      <div v-if="maintenance.length != 0" class="mt-5 forms card rounded">
        <div class="bg-primary text-light mb-4 pt-3 pl-2">
          <P><b>Maintenance Details Of Asset</b></P>
        </div>

        <form
          class="p-3"
          v-for="maintenance in maintenance"
          :key="maintenance.id"
        >
          <label>Asset Id</label>
          <input
            type="text"
            :value="$route.params.id"
            class="form-control"
            disabled
          />

          <label>Maintenance Id</label>
          <input
            type="text"
            :value="maintenance.id"
            class="form-control"
            disabled
          />

          <label>Maintenance Type</label>
          <select
            v-model="maintenance.maintenanceTypeId"
            class="form-control mb-3"
            disabled
          >
            <option selected>Select Category</option>
            <option
              v-for="maintenanceType in maintenanceTypes"
              :key="maintenanceType.id"
              :value="maintenanceType.id"
            >
              {{ maintenanceType.name }}
            </option>
          </select>

          <label>Maintenance Name</label>
          <input
            type="text"
            v-model="maintenance.name"
            class="form-control"
            disabled
          />

          <label>Registration Number</label>
          <input
            type="text"
            v-model="maintenance.registrationNumber"
            class="form-control"
            disabled
          />

          <label>Make</label>
          <input
            type="text"
            v-model="maintenance.make"
            class="form-control"
            disabled
          />

          <label>Maintenance Due Date </label>
          <input
            type="date"
            v-model="maintenance.maintenanceDueDate.split('T')[0]"
            class="form-control"
            disabled
          />

          <label>Speedometer</label>
          <input
            type="number"
            v-model="maintenance.speedometre"
            class="form-control"
            disabled
          />

          <label>Description Of Maintenance</label>
          <textarea
            v-model="maintenance.descriptionofMaintenance"
            class="form-control mb-3"
            rows="3"
            disabled
          ></textarea>

          <label>Maintenance Frequency</label>
          <select
            v-model="maintenance.frequency"
            class="form-control mb-3"
            disabled
          >
            <!-- <option selected>{{claim.insurerName}}</option> -->
            <option value="Recurring">Recurring</option>

            <option value="One Off">One Off</option>
          </select>

          <label>Mentainance Cost</label>
          <input
            type="number"
            v-model="maintenance.maintenanceCost"
            class="form-control"
            disabled
          />

          <label>Workshop Manager</label>
          <input
            type="text"
            v-model="maintenance.workshopManager"
            class="form-control"
            disabled
          />

          <label>Driver's Name</label>
          <input
            type="text"
            v-model="maintenance.driverName"
            class="form-control"
            disabled
          />

          <label>Identity Card</label>
          <input
            type="text"
            v-model="maintenance.identityCard"
            class="form-control"
            disabled
          />

          <label>Identity Card Number</label>
          <input
            type="text"
            v-model="maintenance.identityCardNo"
            class="form-control"
            disabled
          />

          <label>Date</label>
          <input
            type="date"
            v-model="maintenance.date.split('T')[0]"
            class="form-control"
            disabled
          />

          <label>User (Staff) Name</label>
          <input
            type="text"
            v-model="maintenance.staffAssignTo"
            class="form-control"
            disabled
          />

          <label>Total Amount</label>
          <input
            type="number"
            v-model="maintenance.totalAmount"
            class="form-control"
            disabled
          />

          <!--label>Designation</label>
    <input type="text" v-model="designation" class="form-control">

    <label>Head Of Admin Signature</label>
    <input type="text" v-model="name" class="form-control" disabled>

    <label>Signature</label>
    <input type="text" v-model="signature" class="form-control"-->

          <label>Head Of Admin Comments</label>
          <textarea
            v-model="maintenance.commentsByHeadofAdmin"
            class="form-control mb-3"
            rows="3"
            disabled
          ></textarea>

          <!-- <span>
    1. The work or services should be carried out strictly according to instructions given and the Company reserves the right to reject the work/services if it does not conform with the report form.
<br>2. Any variation of this report shall be binding only if made in writing and signed by Head of Administration.
    </span> -->

          <hr class="my-5" />
        </form>
      </div>

      <div v-if="renewal.length != 0" class="mt-5 forms card rounded">
        <div class="bg-primary text-light mb-4 pt-3 pl-2">
          <P><b>Renewal Details Of Asset</b></P>
        </div>
        <div v-for="renewal in renewal" :key="renewal.id">
          <form class="p-3">
            <!-- <label>Asset Name</label>
    <input type="text" :value="$route.params.id" class="form-control" disabled /> -->

            <label>Asset Id</label>
            <input
              type="text"
              :value="$route.params.id"
              class="form-control"
              disabled
            />

            <label>Renewal Name</label>
            <input
              type="text"
              v-model="renewal.name"
              class="form-control"
              disabled
            />

            <label>Registration Number</label>
            <input
              type="text"
              v-model="renewal.registrationNumber"
              class="form-control"
              disabled
            />

            <label>Expiry Date </label>
            <input
              type="date"
              v-model="renewal.expirydate.split('T')[0]"
              class="form-control"
              disabled
            />

            <label>Renewal Cost</label>
            <input
              type="number"
              v-model="renewal.renewalCost"
              class="form-control"
              disabled
            />

            <label>Renewal Type</label>
            <select
              v-model="renewal.renewalTypeId"
              class="form-control mb-3"
              disabled
            >
              <option selected>Select Type</option>
              <option
                v-for="renewalType in renewalTypes"
                :key="renewalType.id"
                :value="renewalType.id"
              >
                {{ renewalType.name }}
              </option>
            </select>

            <label>Renewal Frequency</label>
            <select
              v-model="renewal.frequency"
              class="form-control mb-3"
              disabled
            >
              <!-- <option selected>{{claim.insurerName}}</option> -->
              <option value="Recurring">Recurring</option>

              <option value="One Off">One Off</option>
            </select>

            <label>Driver's Name</label>
            <input
              type="text"
              v-model="renewal.driverName"
              class="form-control"
              disabled
            />

            <label>User (Staff) Name</label>
            <input
              type="text"
              v-model="renewal.staffAssignTo"
              class="form-control"
              disabled
            />

            <label>Total Sum</label>
            <input
              type="number"
              v-model="renewal.totalAmount"
              class="form-control"
              disabled
            />
          </form>

          <hr class="my-5" />
        </div>
      </div>

      <div v-if="task.length != 0" class="mt-5 forms card rounded">
        <div class="bg-primary text-light mb-4 pt-3 pl-2">
          <P
            ><b>Task Details on {{ asset.name }}</b></P
          >
        </div>
        <div v-for="task in task" :key="task.id">
          <form class="p-3">
            <label>Asset Id </label>
            <input
              type="text"
              v-model="task.assetId"
              class="form-control"
              disabled
            />

            <label>Task Name</label>
            <input
              type="text"
              v-model="task.name"
              class="form-control"
              disabled
            />

            <label>Description </label>
            <textarea
              v-model="task.description"
              class="form-control mb-3"
              rows="3"
              disabled
            ></textarea>

            <label>Due Date</label>
            <input
              type="date"
              v-model="task.dueDate.split('T')[0]"
              class="form-control"
              disabled
            />

            <label>Status</label>
            <select v-model="task.status" class="form-control mb-3" disabled>
              <option selected>Select</option>
              <option :value="false">Pending</option>

              <option :value="true">Done</option>
            </select>
          </form>

          <hr class="my-5" />
        </div>
      </div>

      <div v-if="claim.length != 0" class="mt-5 forms card rounded">
        <div class="bg-primary text-light mb-4 pt-3 pl-2">
          <P><b>Claim Details Of Asset</b></P>
        </div>
        <div v-for="claim in claim" :key="claim.id">
          <form class="p-3">
            <label>Claim ID</label>
            <input
              type="text"
              v-model="claim.id"
              class="form-control"
              disabled
            />

            <label>Asset </label>
            <select v-model="claim.assetId" class="form-control mb-3">
              <option selected>{{ claim.assetName }}</option>
              <option v-for="asset in assets" :key="asset.id" :value="asset.id">
                {{ asset.name }}
              </option>
            </select>

            <label>Asset ID </label>
            <input
              type="text"
              v-model="claim.assetId"
              class="form-control"
              disabled
            />

            <div class="mb-4">
              <label class="mg-b-10">Insurer</label>
              <select
                v-model="claim.insurerId"
                class="form-control mb-3"
                disabled
              >
                <option selected>{{ claim.insurerName }}</option>
                <option
                  v-for="insurer in insurers"
                  :key="insurer.id"
                  :value="insurer.id"
                >
                  {{ insurer.name }}
                </option>
              </select>
            </div>

            <label>Claim Status</label>
            <select v-model="claim.status" class="form-control mb-3" disabled>
              <!-- <option selected>{{claim.insurerName}}</option> -->
              <option value="pending">Pending</option>

              <option value="completed">Completed</option>
            </select>

            <Label>Uploaded Documents ({{ claim.documentsUrl.length }})</Label>
            <ul v-for="document in claim.documentsUrl" :key="document.id">
              <li>{{ document.documentName }}</li>
            </ul>
          </form>

          <hr class="my-5" />
        </div>
      </div>

      <!-- <div v-if="task.length != 0" class="mt-5 forms card rounded">
        <div class="bg-primary text-light mb-4 pt-3 pl-2">
          <P
            ><b>Task Details on {{ asset.name }}</b></P
          >
        </div>
        <div v-for="task in task" :key="task.id">
          <form class="p-3">
            <label>Asset Id </label>
            <input
              type="text"
              v-model="task.assetId"
              class="form-control"
              disabled
            />

            <label>Task Name</label>
            <input
              type="text"
              v-model="task.name"
              class="form-control"
              disabled
            />

            <label>Description </label>
            <textarea
              v-model="task.description"
              class="form-control mb-3"
              rows="3"
              disabled
            ></textarea>

            <label>Due Date</label>
            <input
              type="date"
              v-model="task.dueDate.split('T')[0]"
              class="form-control"
              disabled
            />

            <label>Status</label>
            <select v-model="task.status" class="form-control mb-3" disabled>
              <option selected>Select</option>
              <option :value="false">Pending</option>

              <option :value="true">Done</option>
            </select>
          </form>

          <hr class="my-5" />
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";

export default {
  components: {
    Breadcrumb,
  },

  props: ["user", "BaseURL"],

  data() {
    return {
      asset: {},

      maintenance: [],
      maintenanceTypes: [],

      renewal: [],
      renewalTypes: [],

      task: [],

      isSuccessful: "",
      errorMessage: "",

      closed: false,

      categories: [],
    };
  },

  methods: {
    close() {
      this.closed = !this.closed;
    },
  },
  beforeCreate() {
    if (localStorage.getItem("role") !== "User") {
      window.location = "/error";
    }
  },

  created() {
    fetch(this.BaseURL + "/getAssetView" + this.$route.params.id, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        //console.log(data)
        this.asset = data.assetDTO;
        this.maintenance = data.maintenanceDTO;
        this.renewal = data.renewalDTO;
        this.task = data.taskDTO;
        this.claim = data.claimDTO;
        this.reallocation = data.assetReallocationDisposalDTO;
      });

    fetch(this.BaseURL + "/getAllCategory", {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        //console.log(data)
        this.categories = data;
      });

    fetch(this.BaseURL + "/getAllRenewalType", {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        //console.log(data)
        this.renewalTypes = data;
      });

    fetch(this.BaseURL + "/getAllMaintenanceType", {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        //console.log(data)
        this.maintenanceTypes = data;
      });

    fetch(this.BaseURL + "/getAllAsset", {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        //console.log(data)
        this.assets = data;
      });

    fetch(this.BaseURL + "/getAllInsurer", {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        //console.log(data)
        this.insurers = data;
      });
  },

  computed: {},
};
</script>

<style scoped>
form {
  background-color: #fffafa;
}
</style>
