<template>
  <!--Main Content-->
  <div class="main-content px-0 app-content">
    <!--Main Content Container-->
    <div class="container-fluid">
      <!--Page Header-->
      <Breadcrumb />
      <!--Page Header-->

      <!--Row-->
      <div class="row row-sm">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-margin">
          <div class="card">
            <div class="card-body pb-0 border-bottom">
              <div class="d-flex mb-3">
                <h6 class="card-title mb-0">All Assets</h6>
                <div class="ml-auto d-md-flex"></div>
              </div>
            </div>
            <div class="">
              <form class="card m-3" @submit.prevent="getAsset()">
                <div class="m-3 p-3 row">
                  <div class="col-md-6">
                    <!-- <h5 class="mb-3 bg-light p-2">Generate By Date</h5> -->
                    <p>Start Date</p>
                    <input
                      class="form-control"
                      type="date"
                      v-model="startDate"
                      name="start"
                    />

                    <p>End Date</p>
                    <input
                      v-model="endDate"
                      class="form-control"
                      type="date"
                      name="end"
                    />
                  </div>

                  <div class="col-md-6">
                    <!-- <h5 class="mb-3 bg-light p-2">
                      Generate By Category Or Status
                    </h5> -->
                    <p>Category</p>
                    <select v-model="categoryId" class="form-control mb-3">
                      <option value="0" selected>Select Category</option>
                      <option
                        v-for="category in categories"
                        :key="category.id"
                        :value="category.id"
                      >
                        {{ category.name }}
                      </option>
                    </select>

                    <p>Status</p>
                    <select v-model="status" class="form-control mb-3">
                      <option value="" selected>Select Asset Status</option>
                      <option value="Active">Active</option>
                      <option :value="null">Decommissioned</option>
                    </select>
                  </div>

                  <button v-if="loading" class="btn btn-primary" disabled>
                    Loading
                    <div class="spinner-border spinner-border-sm" role="status">
                      <span style="width: 2px" class="sr-only text-light"
                        >Loading...</span
                      >
                    </div>
                  </button>

                  <input
                    v-else-if="assets.length == 0"
                    class="ml-3 btn btn-primary"
                    type="submit"
                    value="Generate"
                  />
                  <button class="ml-5 btn btn-warning" @click.prevent="reset">
                    Reset
                  </button>
                </div>
              </form>

              <div class="card m-3">
                <div class="table-responsive">
                  <table
                    id="example"
                    class="table table-striped"
                    style="width: 100%"
                  >
                    <thead>
                      <tr>
                        <th>Asset Name</th>
                        <th>Asset Category</th>
                        <th>Tag Number</th>
                        <th>Purchase Amount</th>
                        <th>Days Of Service</th>
                        <th>Purchase Date</th>

                        <th>Invoice Number</th>
                        <th>Mode Of Payment</th>
                        <th>Vendor</th>
                        <th>Maintenance</th>
                        <th>Renewal</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="asset in assets" :key="asset.id">
                        <td>{{ asset.name }}</td>
                        <td>{{ asset.categoryName }}</td>
                        <td>{{ asset.tagNumber }}</td>
                        <td>{{ asset.purchaseAmount }}</td>
                        <td>{{ asset.daysofService }}</td>
                        <td>{{ asset.purchaseDate.split("T")[0] }}</td>

                        <td>{{ asset.invoiceNo }}</td>
                        <td>{{ asset.modeOfPayment }}</td>
                        <td>{{ asset.vendor }}</td>
                        <td>{{ asset.maintenance }}</td>
                        <td>{{ asset.renewal }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- COL END -->
      </div>
    </div>
    <!--Main Content Container-->
  </div>
  <!--Main Content-->
</template>

<script>
import $ from "jquery";
import Breadcrumb from "@/components/Breadcrumb";

export default {
  components: {
    Breadcrumb,
  },
  props: ["user", "BaseURL"],
  data() {
    return {
      assets: [],

      startDate: "2021-01-01",
      endDate: "",
      categoryId: 0,
      status: "",
      categories: [],

      loading: false,
    };
  },

  methods: {
    getAsset() {
      this.loading = true;
      fetch(this.BaseURL + "/getAsset", {
        method: "POST",
        body: JSON.stringify({
          date1: this.startDate,
          date2: this.endDate,
          categoryId: this.categoryId,
          status: this.status,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((data) => {
          this.assets = data;
          if (this.assets.length == 0) {
            this.loading = false;
            alert(
              `No Data Available Within Specified Date Or Category To Show`
            );
          } else {
            this.loading = false;
            $(() => {
              $("#example").DataTable({
                dom: "Bfrtip",
                buttons: [
                  "colvis",
                  "copyHtml5",
                  "csvHtml5",
                  "excel",
                  "pdf",
                  "print",
                ],
              });
            });
          }
          //console.log(data);
          // this.isSuccessful = data.isSuccess;
          // this.errorMessage = data.message;
        });
    },

    reset() {
      window.location.reload(true);
    },
  },

  beforeCreate() {
    if (localStorage.getItem("role") !== "User") {
      window.location = "/error";
    }
  },

  created() {
    var dtToday = new Date();

    var month = dtToday.getMonth() + 1;
    var day = dtToday.getDate();
    var year = dtToday.getFullYear();
    if (month < 10) month = "0" + month.toString();
    if (day < 10) day = "0" + day.toString();

    var maxDate = year + "-" + month + "-" + day;
    // alert(maxDate);
    this.endDate = maxDate;

    fetch(this.BaseURL + "/getAllCategory", {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        //console.log(data)
        this.categories = data;
      });
  },
};
</script>

<style scoped>
</style>